/* eslint-disable require-jsdoc */
/* eslint-disable max-len */
import React from 'react';

export default function Rootcauseicon() {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path fillRule='evenodd' clipRule='evenodd' d='M1.34998 9.49998C1.34998 4.99885 4.99885 1.34998 9.49998 1.34998C14.0011 1.34998 17.65 4.99885 17.65 9.49998C17.65 11.5165 16.9176 13.362 15.7043 14.785L22.4596 21.5404C22.7135 21.7942 22.7135 22.2058 22.4596 22.4596C22.2058 22.7135 21.7942 22.7135 21.5404 22.4596L14.785 15.7043C13.362 16.9176 11.5165 17.65 9.49998 17.65C4.99885 17.65 1.34998 14.0011 1.34998 9.49998ZM14.3958 14.2909C14.3766 14.3062 14.3581 14.3227 14.3404 14.3404C14.3227 14.3581 14.3062 14.3766 14.2909 14.3958C13.0557 15.6047 11.3649 16.35 9.49998 16.35C6.65634 16.35 4.21741 14.6172 3.18141 12.15H4.3823C4.69098 12.15 4.97541 11.9826 5.12533 11.7128L6.94188 8.44297L8.75405 12.4297C9.03459 13.0469 9.88934 13.1034 10.2487 12.5285L11.9369 9.82729L12.8633 11.6801C13.0073 11.9681 13.3016 12.15 13.6236 12.15H15.8185C15.4814 12.9529 14.9957 13.678 14.3958 14.2909ZM16.217 10.85C16.3042 10.4135 16.35 9.96209 16.35 9.49998C16.35 5.71682 13.2831 2.64998 9.49998 2.64998C5.71682 2.64998 2.64998 5.71682 2.64998 9.49998C2.64998 9.96209 2.69574 10.4135 2.78297 10.85H4.11751L6.23907 7.03118C6.57801 6.42108 7.46711 6.45687 7.75591 7.09224L9.5906 11.1286L11.2598 8.45788C11.6116 7.89498 12.444 7.93454 12.7408 8.52825L13.9017 10.85H16.217ZM12.3619 9.14734C12.362 9.14719 12.3621 9.14703 12.3622 9.14688L11.9806 8.90838L12.3622 9.14688L12.3619 9.14734Z' fill='#1B2324' />
    </svg>

  );
}

