/* eslint-disable require-jsdoc */
/* eslint-disable max-len */
import React from 'react';

export default function StatusIcon() {
  return (
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M0 8C0 12.4103 3.58857 16 8 16C12.4103 16 16 12.4103 16 8C16 3.58971 12.4103 0 8 0C3.58857 0 0 3.58971 0 8ZM13.7143 8C13.7143 11.1509 11.1509 13.7143 8 13.7143C4.84914 13.7143 2.28571 11.1509 2.28571 8C2.28571 4.84914 4.84914 2.28571 8 2.28571C11.1509 2.28571 13.7143 4.84914 13.7143 8Z' fill='#BBC7CA' />
    </svg>
  );
}

