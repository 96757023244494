import React, {Suspense} from 'react';
import {Redirect, Route} from 'react-router-dom';
import Spinner from '../components/spinner';
import {getLocalStorage} from '../core/local-storage-service';
/**
 * @return {void}
 */
export function PrivateRoute({children, ...rest}: any) {
  const token = getLocalStorage('token') ? getLocalStorage('token') : '';

  return (
    <Suspense fallback={ <Spinner />}>
      <Route
        {...rest}
        render={({location}: any) =>
        token ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/sign-in',
              state: {from: location},
            }}
          />
        )
        }
      />
    </Suspense>
  );
}
