/* eslint-disable require-jsdoc */
/* eslint-disable max-len */
import React from 'react';

export default function PdfIcon() {
  return (
    <svg width='18' height='22' viewBox='0 0 18 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M15.879 6.60498L12 2.72598V6.60498H15.879ZM16.5 8.10498H10.5V2.10498H1.5V20.105H16.5V8.10498ZM0.75 0.60498H12L18 6.60498V20.855C18 21.0539 17.921 21.2447 17.7803 21.3853C17.6397 21.526 17.4489 21.605 17.25 21.605H0.75C0.551088 21.605 0.360322 21.526 0.21967 21.3853C0.0790178 21.2447 0 21.0539 0 20.855V1.35498C0 1.15607 0.0790178 0.965303 0.21967 0.82465C0.360322 0.683998 0.551088 0.60498 0.75 0.60498ZM8.2125 14.249L12.4545 10.007L13.515 11.0675L8.2125 16.37L4.5 12.659L5.5605 11.597L8.2125 14.249Z' fill='#355F92'/>
    </svg>

  );
}
