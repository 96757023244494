/* eslint-disable require-jsdoc */
import React from 'react';

export default function AddIcon() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="31" height="31" rx="15.5" fill="#355F92" />
      <path
        // eslint-disable-next-line max-len
        d="M22.1366 16.152H17.0246V21.336H14.9846V16.152H9.89659V14.304H14.9846V9.096H17.0246V14.304H22.1366V16.152Z"
        fill="white"
      />
      <rect x="0.5" y="0.5" width="31" height="31" rx="15.5" stroke="#355F92" />
    </svg>
  );
}
