/* eslint-disable require-jsdoc */
/* eslint-disable max-len */
import React from 'react';

export default function ImgUploadIcon() {
  return (
    <svg width='48' height='42' viewBox='0 0 48 42' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M48 10.5V37.5C48 39.9844 45.9844 42 43.5 42H4.5C2.01562 42 0 39.9844 0 37.5V10.5C0 8.01562 2.01562 6 4.5 6H12.75L13.9031 2.91562C14.5594 1.1625 16.2375 0 18.1125 0H29.8781C31.7531 0 33.4313 1.1625 34.0875 2.91562L35.25 6H43.5C45.9844 6 48 8.01562 48 10.5ZM35.25 24C35.25 17.7938 30.2062 12.75 24 12.75C17.7938 12.75 12.75 17.7938 12.75 24C12.75 30.2062 17.7938 35.25 24 35.25C30.2062 35.25 35.25 30.2062 35.25 24ZM32.25 24C32.25 28.5469 28.5469 32.25 24 32.25C19.4531 32.25 15.75 28.5469 15.75 24C15.75 19.4531 19.4531 15.75 24 15.75C28.5469 15.75 32.25 19.4531 32.25 24Z' fill='#97CCC0' />
    </svg>
  );
}
