/* eslint-disable require-jsdoc */
/* eslint-disable max-len */
import React from 'react';

export default function GoalsIcon() {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path fillRule='evenodd' clipRule='evenodd' d='M3 3C3 2.44772 3.44772 2 4 2H17.865C18.245 2.00014 18.6175 2.10856 18.9382 2.31254C19.2588 2.51653 19.5147 2.80765 19.6759 3.15181C19.837 3.49597 19.8968 3.87892 19.8482 4.25583C19.7996 4.63275 19.6446 4.988 19.4014 5.28L16.3017 9L19.4012 12.7198C19.6445 13.0119 19.7996 13.3673 19.8482 13.7442C19.8968 14.1211 19.837 14.504 19.6759 14.8482C19.5147 15.1923 19.2588 15.4835 18.9382 15.6875C18.6175 15.8914 18.2454 15.9999 17.8654 16H5V21C5 21.5523 4.55228 22 4 22C3.44772 22 3 21.5523 3 21V3ZM5 14H17.8646L14.2317 9.64015C13.9228 9.26932 13.9228 8.73068 14.2317 8.35985L17.8646 4L5 4V14Z' fill='#1B2324'/>
    </svg>

  );
}
