/* eslint-disable require-jsdoc */
/* eslint-disable max-len */
import React from 'react';

export default function AppointmentAttendenceIcon() {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M14.8991 6.5C11.9828 6.5 9.61213 8.87723 9.61213 11.8177C9.61213 14.7583 11.9828 17.1355 14.8991 17.1355C17.8154 17.1355 20.186 14.7583 20.186 11.8177C20.186 8.87723 17.8154 6.5 14.8991 6.5ZM8.11213 11.8177C8.11213 8.05602 11.1471 5 14.8991 5C18.651 5 21.686 8.05602 21.686 11.8177C21.686 14.184 20.4851 16.2709 18.6604 17.4937C18.8385 17.5722 19.0236 17.6646 19.219 17.7727C20.0124 17.2426 20.9651 16.9336 21.9906 16.9336C24.7619 16.9336 27.002 19.1906 27.002 21.9667C27.002 24.7428 24.7619 26.9998 21.9906 26.9998C19.2193 26.9998 16.9792 24.7428 16.9792 21.9667C16.9792 20.7961 17.3775 19.7178 18.046 18.8621C17.6362 18.6827 17.3177 18.6205 16.9947 18.5992C16.7433 18.5827 16.4761 18.5902 16.1287 18.6047L16.0035 18.61L16.0034 18.61C15.6902 18.6235 15.3248 18.6392 14.8998 18.6392C13.1776 18.6392 11.4855 19.0949 9.99358 19.9607C8.50163 20.8266 7.26229 22.0722 6.4005 23.5728C6.19422 23.932 5.73581 24.056 5.37662 23.8497C5.01742 23.6434 4.89347 23.185 5.09975 22.8258C6.09232 21.0975 7.52035 19.6618 9.24065 18.6634C9.93952 18.2578 10.6766 17.9302 11.4387 17.6842C9.44617 16.4955 8.11213 14.3117 8.11213 11.8177ZM21.9906 18.4336C20.0549 18.4336 18.4792 20.0118 18.4792 21.9667C18.4792 23.9216 20.0549 25.4998 21.9906 25.4998C23.9263 25.4998 25.502 23.9216 25.502 21.9667C25.502 20.0118 23.9263 18.4336 21.9906 18.4336ZM24.4852 20.3794C24.7719 20.6783 24.7619 21.1531 24.463 21.4398L21.8573 23.9383C21.567 24.2166 21.1088 24.2164 20.8187 23.9378L19.5181 22.6886C19.2194 22.4016 19.2098 21.9269 19.4967 21.6281C19.7837 21.3294 20.2584 21.3198 20.5572 21.6068L21.3387 22.3574L23.4248 20.3571C23.7238 20.0704 24.1985 20.0804 24.4852 20.3794Z" fill="#355F92" />
    </svg>


  );
}

