/* eslint-disable require-jsdoc */
/* eslint-disable max-len */
import React from 'react';

export default function NotesWidgetIcon() {
  return (
    <svg width='80' height='80' viewBox='0 0 80 80' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g filter='url(#filter0_d_8452_61950)'>
        <circle cx='44' cy='40' r='30' fill='#355F92' />
        <path d='M55.5 50.125H32.5C31.9469 50.125 31.5 50.5719 31.5 51.125V52.25C31.5 52.3875 31.6125 52.5 31.75 52.5H56.25C56.3875 52.5 56.5 52.3875 56.5 52.25V51.125C56.5 50.5719 56.0531 50.125 55.5 50.125ZM36.0531 47.5C36.1156 47.5 36.1781 47.4937 36.2406 47.4844L41.4969 46.5625C41.5594 46.55 41.6188 46.5219 41.6625 46.475L54.9094 33.2281C54.9383 33.1992 54.9613 33.1649 54.977 33.1271C54.9927 33.0893 55.0008 33.0487 55.0008 33.0078C55.0008 32.9669 54.9927 32.9264 54.977 32.8886C54.9613 32.8508 54.9383 32.8164 54.9094 32.7875L49.7156 27.5906C49.6562 27.5313 49.5781 27.5 49.4937 27.5C49.4094 27.5 49.3313 27.5313 49.2719 27.5906L36.025 40.8375C35.9781 40.8844 35.95 40.9406 35.9375 41.0031L35.0156 46.2594C34.9852 46.4268 34.9961 46.5991 35.0473 46.7613C35.0985 46.9236 35.1884 47.071 35.3094 47.1906C35.5156 47.3906 35.775 47.5 36.0531 47.5Z' fill='white' />
      </g>
      <defs>
        <filter id='filter0_d_8452_61950' x='0' y='0' width='80' height='80' filterUnits='userSpaceOnUse' colorInterpolationFilters='sRGB'>
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha' />
          <feOffset dx='-4' />
          <feGaussianBlur stdDeviation='5' />
          <feColorMatrix type='matrix' values='0 0 0 0 0.4625 0 0 0 0 0.4625 0 0 0 0 0.4625 0 0 0 0.3 0' />
          <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_8452_61950' />
          <feBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_8452_61950' result='shape' />
        </filter>
      </defs>
    </svg>
  );
}
