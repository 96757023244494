/* eslint-disable require-jsdoc */
/* eslint-disable max-len */
import React from 'react';

export default function HypothesisIcon() {
  return (
    <svg width='15' height='18' viewBox='0 0 15 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path fillRule='evenodd' clipRule='evenodd' d='M3.27797 1.8002C3.01865 1.8002 2.76995 1.90321 2.58658 2.08658C2.40321 2.26995 2.3002 2.51865 2.3002 2.77797V15.4456L7.25743 11.9048C7.53559 11.7061 7.90925 11.7061 8.18741 11.9048L13.1446 15.4456V2.77797C13.1446 2.51865 13.0416 2.26995 12.8583 2.08658C12.6749 1.90321 12.4262 1.8002 12.1669 1.8002H3.27797ZM1.45521 0.955209C1.93864 0.471782 2.5943 0.200195 3.27797 0.200195H12.1669C12.8505 0.200195 13.5062 0.471782 13.9896 0.955209C14.4731 1.43864 14.7446 2.0943 14.7446 2.77797V17.0002C14.7446 17.2999 14.5772 17.5744 14.3107 17.7115C14.0442 17.8487 13.7235 17.8254 13.4796 17.6512L7.72242 13.5389L1.96519 17.6512C1.72133 17.8254 1.40059 17.8487 1.13413 17.7115C0.867673 17.5744 0.700195 17.2999 0.700195 17.0002V2.77797C0.700195 2.0943 0.971782 1.43864 1.45521 0.955209Z' fill='#1B2324' />
    </svg>
  );
}
