/* eslint-disable react/no-deprecated */
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
// Import './core/language/i18';
import disableLogs from './consolelogs/disablelogs';
// Import serviceWorker from "./serviceWorker";

import {Provider} from 'react-redux';
import {store} from './store/store';

disableLogs();

ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <App />
      </Provider>
    </React.StrictMode>,
    document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
// ServiceWorker();
