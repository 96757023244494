/* eslint-disable max-len */
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-tabs */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, {useContext, lazy, Suspense} from 'react';
import {
  BrowserRouter as Router,
  Switch, Route,
} from 'react-router-dom';
import CommonLayout from '../components/commonlayout/common-layout';
import {PrivateRoute} from './private-route';
import {AuthContext} from '../providers/auth';

import {clearLocalStorage} from '../core/local-storage-service';
import {commonRoutes} from './common-routes';
import {salesRoutes} from './sales-routes';
import {careRoutes} from './care-routes';
import {healthRoutes} from './health-routes';
import {mdRoutes} from './md-routes';
import Spinner from '../components/spinner';


const Testing = lazy(() =>
  import('../modules/recommendations/testing/component'));
const Clients = lazy(() =>
  import('../modules/clients/component/clients'));
const Profile = lazy(() =>
  import('../modules/admin-profile/component/admin-profile'));
const CustomerProfile = lazy(() =>
  import('../modules/customer-profile/component/customer-profile'));
const Roles = lazy(() =>
  import('../modules/roles/component/roles'));
const Nutrition = lazy(() =>
  import('../modules/recommendations/nutrition/component/nutrition'));
const Supplements = lazy(() =>
  import('../modules/recommendations/supplements/component'));
const Lifestyle = lazy(() =>
  import('../modules/recommendations/lifestyle/component'));
const Rootcause = lazy(() =>
  import('../modules/hypothesis/rootcause/component'));
const Diagnosis = lazy(() =>
  import('../modules/hypothesis/diagnosis/component'));
const Coredysfunction = lazy(() =>
  import('../modules/hypothesis/coredysfunction/component'));
const Goals = lazy(() =>
  import('../modules/hypothesis/goals/component/goals'));
// const Plans = lazy(() =>
//   import('../modules/plans/component/plans'));
const AcuityAppointment = lazy(() =>
  import('../modules/care-manager/care-client/acuity-appointment'));
const UserSettingsGroup = lazy(() =>
  import('../modules/user-settings/component/user_settings'));
const AssignHcMatchCare = lazy(() =>
  import('../modules/match-care-team/component/assign-hc-matchcare'));
const Redirection = lazy(() =>
  import('../components/redirection/redirection-component'));
const PermissionDenied = lazy(() =>
  import('../components/permission-denied/permission-denied'));

const officeRoutes = [
  {
    path: '/office/users/:tab',
    component: UserSettingsGroup,
    private: true,
  },
  {
    path: '/office/profile',
    component: Profile,
    private: true,
  },
  {
    path: '/office/clients',
    component: Clients,
    private: true,
  },
  {
    path: '/office/root_cause',
    component: Clients,
    private: true,
  },
  {
    path: '/office/leads',
    component: Clients,
    private: true,
  },
  {
    path: '/office/roles',
    component: Roles,
    private: true,
  },
  // {
  //   path: '/office/plans',
  //   component: Plans,
  //   private: true,
  // },
  {
    path: '/office/nutrition',
    component: Nutrition,
    private: true,
  },
  {
    path: '/office/testing',
    component: Testing,
    private: true,
  },
  {
    path: '/office/supplements',
    component: Supplements,
    private: true,
  },
  {
    path: '/office/lifestyle',
    component: Lifestyle,
    private: true,
  },
  {
    path: '/office/rootcause',
    component: Rootcause,
    private: true,
  },
  {
    path: '/office/diagnosis',
    component: Diagnosis,
    private: true,
  },
  {
    path: '/office/imbalance',
    component: Coredysfunction,
    private: true,
  },
  {
    path: '/office/goals',
    component: Goals,
    private: true,
  },
  {
    path: '/office/client/appointment/:id/:tab',
    component: AcuityAppointment,
    private: true,
  },
  {
    path: '/office/myschedule/:id/appointment',
    component: AcuityAppointment,
    private: true,
  },
  {
    path: '/office/dashboard/:id/appointment',
    component: AcuityAppointment,
    private: true,
  },
  {
    path: '/office/matchcare/:type/:id',
    component: AssignHcMatchCare,
    private: true,
  },
  {
    path: '/permission-denied',
    component: PermissionDenied,
    private: true,
  },
];

const customerRoutes = [

  {
    path: '/customer/profile',
    component: CustomerProfile,
    private: true,
  },
];

/**
 * @return {void}
 */
export default function RoutesGrit() {
  const {userInfo}: any = useContext(AuthContext);

  let allRoutes = [...commonRoutes];

  if (userInfo && userInfo.user_type === 1) {
    if (userInfo.code === 'admin') {
      allRoutes = allRoutes.concat(officeRoutes);
    } else if (userInfo.code === 'sales_manager') {
      allRoutes = allRoutes.concat(salesRoutes);
    } else if (userInfo.code === 'care_manager') {
      allRoutes = allRoutes.concat(careRoutes);
    } else if (userInfo.code === 'md') {
      allRoutes = allRoutes.concat(mdRoutes);
    } else if (userInfo.code === 'health_coach') {
      allRoutes = allRoutes.concat(healthRoutes);
    }
  } else if (userInfo && userInfo.user_type === 2) {
    allRoutes = allRoutes.concat(customerRoutes);
  } else {
    clearLocalStorage();
  }

  return (
    // basename={'grit-well/'}
    <Router basename={''} >
      <CommonLayout />
      <Suspense fallback={ <Spinner />}>
        <Switch>
          {allRoutes.map((route: any, i: number) =>
					route.private ? (
						<PrivateRoute exact path={route.path} key={i}>
						  <route.component />
						</PrivateRoute>
					) :

						(
							<Route exact path={route.path} key={i}>
							  <route.component />
							</Route>
						),
          )}
          <Redirection />
        </Switch>
      </Suspense>
    </Router>
  );
}

