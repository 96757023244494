/* eslint-disable require-jsdoc */
/* eslint-disable max-len */
import React from 'react';

export default function ProfileLogout() {
  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path fillRule='evenodd' clipRule='evenodd' d='M9.16667 16.6667C9.16667 16.2064 8.79357 15.8333 8.33333 15.8333H4.16667V4.16667H8.33333C8.79357 4.16667 9.16667 3.79357 9.16667 3.33333C9.16667 2.87309 8.79357 2.5 8.33333 2.5H4.16667C3.24619 2.5 2.5 3.24619 2.5 4.16667V15.8333C2.5 16.7538 3.24619 17.5 4.16667 17.5H8.33333C8.79357 17.5 9.16667 17.1269 9.16667 16.6667Z' fill='#0D0D0D'/>
      <path d='M18.0947 10.5838C18.1719 10.5052 18.2304 10.415 18.2701 10.319C18.3105 10.2215 18.333 10.1146 18.3333 10.0025L18.3333 10L18.3333 9.9975C18.3327 9.78507 18.2513 9.57283 18.0893 9.41074L14.7559 6.07741C14.4305 5.75197 13.9028 5.75197 13.5774 6.07741C13.252 6.40285 13.252 6.93049 13.5774 7.25592L15.4882 9.16667H7.5C7.03976 9.16667 6.66667 9.53976 6.66667 10C6.66667 10.4602 7.03976 10.8333 7.5 10.8333H15.4882L13.5774 12.7441C13.252 13.0695 13.252 13.5972 13.5774 13.9226C13.9028 14.248 14.4305 14.248 14.7559 13.9226L18.0887 10.5898L18.0947 10.5838Z' fill='#0D0D0D'/>
    </svg>

  );
}
