import apiEndpoint from '../core/apiend_point';
import {ApiResponseProps, PayloadProps} from '../core/schema';
import {triggerApi} from '../services';
import {getLocalStorage} from '../core/local-storage-service';

/**
 * @param {PayloadProps} params required params
 * @return {object} params
 */
export async function getGlobalData() {
  const apiObject: PayloadProps = {
    payload: {},
    method: 'GET',
    apiUrl: apiEndpoint.getGlobalData,
    headers: {Authorization: getLocalStorage('token')},
  };
  return triggerApi(apiObject)
      .then((response: ApiResponseProps) => {
        if (response.status_code === 200) {
          return response.data;
        }
      })
      .catch((err: Record<string, unknown>) => {
        console.log();
      });
}
