/* eslint-disable require-jsdoc */
/* eslint-disable max-len */
import React from 'react';

export default function ProfileIcon() {
  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M4.16667 17.4993H2.5V16.666C2.50367 13.4459 5.1132 10.8364 8.33333 10.8327H11.6667C14.8868 10.8364 17.4963 13.4459 17.5 16.666V17.4993H15.8333V16.666C15.8306 14.366 13.9667 12.5021 11.6667 12.4993H8.33333C6.03329 12.5021 4.16942 14.366 4.16667 16.666V17.4993ZM10 9.99935C7.69881 9.99935 5.83333 8.13387 5.83333 5.83268C5.83333 3.5315 7.69881 1.66602 10 1.66602C12.3012 1.66602 14.1667 3.5315 14.1667 5.83268C14.1639 8.13273 12.3 9.99659 10 9.99935ZM10 3.33268C8.61929 3.33268 7.5 4.45197 7.5 5.83268C7.5 7.21339 8.61929 8.33268 10 8.33268C11.3807 8.33268 12.5 7.21339 12.5 5.83268C12.5 4.45197 11.3807 3.33268 10 3.33268Z' fill='#1A1B1D'/>
    </svg>

  );
}
