/* eslint-disable require-jsdoc */
/* eslint-disable max-len */
import React from 'react';

export default function ReviewPrePostIcon() {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M22.6039 16.7813C24.8253 16.7755 26.6671 18.6477 26.6671 20.9113C26.6671 23.1749 24.8331 25.0453 22.6078 25.0413C21.5398 25.0399 20.5157 24.6071 19.7586 23.837C19.0015 23.0669 18.5728 22.0221 18.5659 20.9303C18.5575 18.6551 20.3757 16.7876 22.6039 16.7813ZM22.1044 22.1836C22.0166 22.0684 21.9541 21.9867 21.8938 21.9038C21.5633 21.4513 21.2384 20.9942 20.8983 20.5492C20.8154 20.436 20.7033 20.3489 20.5745 20.2976C20.476 20.2611 20.3682 20.26 20.269 20.2945C20.1698 20.3291 20.0851 20.3972 20.0289 20.4876C19.8796 20.7 19.8813 20.9165 20.0328 21.1254C20.5903 21.8946 21.1449 22.6666 21.7125 23.4282C21.9732 23.7776 22.3527 23.7535 22.5977 23.3856C22.7553 23.1484 22.8933 22.8968 23.0481 22.6591C23.638 21.7591 24.3272 20.9316 25.1023 20.1928C25.2909 20.0132 25.3906 19.8106 25.3118 19.5538C25.1941 19.171 24.7391 19.0869 24.4193 19.3886C23.5417 20.223 22.7645 21.1615 22.1044 22.1841V22.1836Z" fill="#F48621" />
      <path d="M9.60513 7.19088V8.20237H9.31457C8.7329 8.20237 8.15066 8.19834 7.56899 8.20237C6.81276 8.2087 6.34202 8.69113 6.34145 9.46889C6.34145 14.8896 6.34145 20.3101 6.34145 25.7305C6.34145 26.4881 6.81389 26.9688 7.55773 26.9688C12.24 26.9711 16.9225 26.9711 21.6052 26.9688C22.1254 26.9688 22.4988 26.7454 22.7381 26.2687C22.7966 26.1833 22.8855 26.1248 22.9858 26.1058C23.2421 26.054 23.5045 26.0304 23.8401 25.9855C23.7725 26.2365 23.7421 26.48 23.6435 26.6907C23.2528 27.5272 22.5906 27.9906 21.684 27.9929C16.954 28.0037 12.2241 28.0052 7.4941 27.9975C7.20975 27.9982 6.92808 27.9414 6.66531 27.8303C6.40255 27.7192 6.16387 27.5561 5.96305 27.3502C5.76222 27.1444 5.6032 26.9 5.49516 26.6311C5.38712 26.3622 5.33218 26.0741 5.33352 25.7834C5.33352 20.3531 5.33352 14.9224 5.33352 9.49134C5.33352 8.21849 6.11058 7.29796 7.34544 7.20009C8.09266 7.14252 8.84157 7.19088 9.60513 7.19088Z" fill="#F48621" />
      <path d="M14.568 8.7057C13.4887 8.7057 12.4097 8.7057 11.3308 8.7057C10.8561 8.7057 10.6804 8.52897 10.6787 8.05172C10.6787 7.37125 10.6787 6.6904 10.6787 6.00916C10.6787 5.54458 10.8527 5.3759 11.3139 5.3713C11.7362 5.3713 12.1585 5.36554 12.5803 5.37418C12.7272 5.37705 12.8094 5.34309 12.8725 5.18823C13.189 4.40298 13.7875 4 14.6006 4C15.4137 4 15.9757 4.43119 16.3096 5.18535C16.3369 5.23295 16.3743 5.2736 16.4192 5.30422C16.4641 5.33484 16.5152 5.35463 16.5686 5.36209C17.009 5.38051 17.4504 5.36784 17.8913 5.3713C18.3035 5.37418 18.4961 5.56818 18.4984 5.98671C18.5017 6.68752 18.5017 7.38775 18.4984 8.08741C18.4984 8.4973 18.2934 8.70283 17.888 8.70455C16.7809 8.70743 15.6739 8.7057 14.568 8.7057ZM14.5933 6.74432C14.7033 6.74342 14.8121 6.72036 14.9134 6.67647C15.0147 6.63257 15.1065 6.56871 15.1836 6.48851C15.2608 6.40831 15.3217 6.31337 15.3629 6.20909C15.4041 6.10481 15.4248 5.99325 15.4239 5.88078C15.425 5.76832 15.4042 5.65675 15.3627 5.55259C15.3212 5.44843 15.2598 5.35376 15.1821 5.27413C15.1044 5.19449 15.012 5.13147 14.9102 5.08875C14.8085 5.04603 14.6994 5.02446 14.5894 5.02531C14.477 5.02516 14.3657 5.04767 14.2619 5.09156C14.158 5.13546 14.0637 5.19986 13.9842 5.2811C13.9048 5.36233 13.8418 5.45879 13.7988 5.56496C13.7559 5.67112 13.7339 5.7849 13.734 5.89978C13.7432 6.12645 13.8375 6.34077 13.9974 6.4981C14.1572 6.65543 14.3703 6.74363 14.5922 6.74432H14.5933Z" fill="#F48621" />
      <path d="M19.5479 8.19956V7.17541C19.7168 7.17541 19.8733 7.17541 20.0287 7.17541C20.6481 7.18174 21.2675 7.1495 21.883 7.20592C23.0013 7.30782 23.8144 8.16848 23.8313 9.32043C23.8623 11.4574 23.8403 13.5949 23.8392 15.7325C23.8342 15.7702 23.8259 15.8074 23.8144 15.8436L22.8414 15.7077V9.57547C22.8414 8.66472 22.3836 8.20014 21.4866 8.19956H19.5479Z" fill="#F48621" />
      <path d="M14.5864 15.7315H9.66327C9.5698 15.7315 9.45662 15.7568 9.38736 15.7136C9.2967 15.656 9.18465 15.5409 9.18408 15.4522C9.18352 15.3636 9.2967 15.2508 9.38454 15.1869C9.44085 15.1454 9.54728 15.169 9.63174 15.169H19.5343C19.6091 15.169 19.7032 15.1431 19.7544 15.1799C19.8518 15.2508 19.9864 15.3567 19.9875 15.4494C19.9887 15.5421 19.8558 15.6526 19.7578 15.7188C19.6981 15.7591 19.5934 15.7315 19.5095 15.7315H14.5864Z" fill="#F48621" />
      <path d="M14.5684 12.167H9.6695C9.57546 12.167 9.46397 12.1923 9.39302 12.1497C9.30124 12.0921 9.18637 11.9845 9.18411 11.8947C9.18186 11.8049 9.29673 11.6983 9.38458 11.6362C9.44426 11.5947 9.54787 11.6137 9.63177 11.6137H19.5422C19.6261 11.6137 19.7302 11.5953 19.7894 11.6373C19.8783 11.7012 19.9943 11.8135 19.9904 11.8998C19.9864 11.9862 19.8665 12.0967 19.7725 12.1508C19.6998 12.1929 19.5889 12.167 19.4954 12.167H14.5684Z" fill="#F48621" />
      <path d="M17.9374 18.7467C17.8484 19.2649 17.8219 19.2873 17.3658 19.2873C14.8131 19.2873 12.262 19.2873 9.71229 19.2873C9.59967 19.2873 9.47185 19.3075 9.38063 19.2597C9.28941 19.2119 9.18073 19.087 9.18467 19.0018C9.18862 18.9166 9.30799 18.8152 9.39583 18.7456C9.43807 18.7122 9.52253 18.7329 9.58841 18.7329C12.2991 18.7329 15.0098 18.7329 17.7206 18.7329C17.7926 18.7323 17.8642 18.7416 17.9374 18.7467Z" fill="#F48621" />
      <path d="M17.8496 22.8583H9.61949C9.55361 22.8583 9.46689 22.8825 9.42691 22.8491C9.32894 22.7674 9.20168 22.6661 9.18647 22.5613C9.1769 22.487 9.32612 22.3707 9.42635 22.3091C9.48885 22.2711 9.59077 22.2988 9.67524 22.2988H17.2409C17.7325 22.2965 17.7325 22.2965 17.8496 22.8583Z" fill="#F48621" />
      <path d="M22.1044 22.1845C22.7655 21.1619 23.5435 20.2236 24.4221 19.3896C24.7419 19.0879 25.1969 19.1719 25.3146 19.5548C25.3934 19.8115 25.2938 20.0153 25.1051 20.1938C24.3301 20.9325 23.6408 21.7601 23.051 22.6601C22.8944 22.8978 22.7565 23.1494 22.6005 23.3866C22.3556 23.7544 21.976 23.7786 21.7153 23.4292C21.1477 22.6675 20.5931 21.8955 20.0356 21.1264C19.8842 20.9174 19.8825 20.701 20.0317 20.4885C20.0879 20.3981 20.1727 20.3301 20.2719 20.2955C20.3711 20.261 20.4788 20.2621 20.5773 20.2986C20.7061 20.3499 20.8183 20.437 20.9011 20.5501C21.239 20.9952 21.5661 21.4523 21.8967 21.9047C21.9541 21.9876 22.0166 22.0682 22.1044 22.1845Z" fill="white" />
      <path d="M14.5925 6.74523C14.3706 6.74453 14.1576 6.65633 13.9977 6.49901C13.8378 6.34168 13.7435 6.12736 13.7344 5.90069C13.7344 5.66907 13.8244 5.44694 13.9846 5.28316C14.1448 5.11938 14.362 5.02737 14.5886 5.02737C14.6986 5.02652 14.8077 5.04809 14.9094 5.09081C15.0112 5.13353 15.1036 5.19655 15.1813 5.27619C15.259 5.35582 15.3204 5.45049 15.3619 5.55465C15.4034 5.65881 15.4242 5.77038 15.4231 5.88285C15.4247 6.10979 15.3382 6.32811 15.1824 6.48983C15.0267 6.65154 14.8145 6.74341 14.5925 6.74523Z" fill="white" />
    </svg>
  );
}
