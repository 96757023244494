import {Permissions} from './schema';

export const checkUserPermissions =
(permissions: Permissions[], code: string) => {
  const pemission: Permissions[] = permissions
      .filter((data: Permissions) => data.key === code);
  if (pemission.length > 0) {
    return pemission[0].value;
  }

  return false;
};
