/* eslint-disable require-jsdoc */
/* eslint-disable max-len */
import React from 'react';

export default function Diagnosisicon() {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path fillRule='evenodd' clipRule='evenodd' d='M1.25 4C1.25 2.48579 2.48579 1.25 4 1.25H4.5C4.91421 1.25 5.25 1.58579 5.25 2C5.25 2.41421 4.91421 2.75 4.5 2.75H4C3.31421 2.75 2.75 3.31421 2.75 4V8C2.75 10.8858 5.11421 13.25 8 13.25C10.8858 13.25 13.25 10.8858 13.25 8V4C13.25 3.31421 12.6858 2.75 12 2.75H11.5C11.0858 2.75 10.75 2.41421 10.75 2C10.75 1.58579 11.0858 1.25 11.5 1.25H12C13.5142 1.25 14.75 2.48579 14.75 4V8C14.75 11.4608 12.1143 14.3326 8.75 14.7084V16C8.75 18.8858 11.1142 21.25 14 21.25C16.8858 21.25 19.25 18.8858 19.25 16V14.6465C18.0957 14.32 17.25 13.2588 17.25 12C17.25 10.4812 18.4812 9.25 20 9.25C21.5188 9.25 22.75 10.4812 22.75 12C22.75 13.2588 21.9043 14.32 20.75 14.6465V16C20.75 19.7142 17.7142 22.75 14 22.75C10.2858 22.75 7.25 19.7142 7.25 16V14.7084C3.88568 14.3326 1.25 11.4608 1.25 8V4ZM20 13.25C20.6904 13.25 21.25 12.6904 21.25 12C21.25 11.3096 20.6904 10.75 20 10.75C19.3096 10.75 18.75 11.3096 18.75 12C18.75 12.6904 19.3096 13.25 20 13.25Z' fill='#1B2324' />
    </svg>

  );
}
