/* eslint-disable require-jsdoc */
/* eslint-disable max-len */
import React from 'react';

export default function UsersIcon() {
  return (
    <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M7.33366 2.33317C5.49271 2.33317 4.00033 3.82555 4.00033 5.6665C4.00033 7.50745 5.49271 8.99984 7.33366 8.99984C9.17461 8.99984 10.667 7.50745 10.667 5.6665C10.667 3.82555 9.17461 2.33317 7.33366 2.33317ZM2.33366 5.6665C2.33366 2.90508 4.57224 0.666504 7.33366 0.666504C10.0951 0.666504 12.3337 2.90508 12.3337 5.6665C12.3337 8.42793 10.0951 10.6665 7.33366 10.6665C4.57224 10.6665 2.33366 8.42793 2.33366 5.6665ZM13.024 2.13097C13.3495 1.80553 13.8771 1.80553 14.2025 2.13097C16.1552 4.08359 16.1552 7.24942 14.2025 9.20204C13.8771 9.52748 13.3495 9.52748 13.024 9.20204C12.6986 8.8766 12.6986 8.34896 13.024 8.02353C14.3258 6.72178 14.3258 4.61123 13.024 3.30948C12.6986 2.98404 12.6986 2.45641 13.024 2.13097ZM13.6085 12.9644C13.7202 12.5179 14.1726 12.2464 14.6191 12.3581C15.7257 12.6347 16.4262 13.3423 16.829 14.148C17.2188 14.9276 17.3337 15.7974 17.3337 16.4998C17.3337 16.9601 16.9606 17.3332 16.5003 17.3332C16.0401 17.3332 15.667 16.9601 15.667 16.4998C15.667 15.9522 15.5735 15.3637 15.3383 14.8933C15.1162 14.4491 14.7749 14.115 14.2149 13.975C13.7684 13.8633 13.4969 13.4109 13.6085 12.9644ZM4.41699 13.9998C3.36744 13.9998 2.33366 15.0111 2.33366 16.4998C2.33366 16.9601 1.96056 17.3332 1.50033 17.3332C1.04009 17.3332 0.666992 16.9601 0.666992 16.4998C0.666992 14.3067 2.24488 12.3332 4.41699 12.3332H10.2503C12.4224 12.3332 14.0003 14.3067 14.0003 16.4998C14.0003 16.9601 13.6272 17.3332 13.167 17.3332C12.7068 17.3332 12.3337 16.9601 12.3337 16.4998C12.3337 15.0111 11.2999 13.9998 10.2503 13.9998H4.41699Z' fill='#1B2324' />
    </svg>
  );
}
