/* eslint-disable require-jsdoc */
/* eslint-disable max-len */
import React from 'react';

export default function ScheduleFollowUpIcon() {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.5625 15.5971C12.5915 17.7221 14.3098 19.436 16.4375 20.4596C16.5944 20.5339 16.768 20.5661 16.9412 20.5529C17.1143 20.5398 17.2811 20.4818 17.425 20.3846L20.55 18.2971C20.6881 18.2035 20.8476 18.1463 21.0137 18.131C21.1799 18.1157 21.3472 18.1428 21.5 18.2096L27.35 20.7221C27.5499 20.8053 27.717 20.952 27.8254 21.1394C27.9338 21.3269 27.9776 21.5448 27.95 21.7596C27.7646 23.2068 27.0582 24.5368 25.9631 25.5008C24.8679 26.4648 23.459 26.9968 22 26.9971C17.4913 26.9971 13.1673 25.206 9.97919 22.0179C6.79107 18.8298 5 14.5058 5 9.99711C5.00033 8.53811 5.53227 7.12921 6.49628 6.03406C7.4603 4.9389 8.79033 4.23253 10.2375 4.04711C10.4523 4.01952 10.6702 4.0633 10.8577 4.17172C11.0452 4.28014 11.1918 4.44717 11.275 4.64711L13.7875 10.5096C13.8528 10.66 13.8802 10.8242 13.8671 10.9877C13.854 11.1512 13.8009 11.309 13.7125 11.4471L11.625 14.6221C11.5321 14.7657 11.4775 14.9308 11.4666 15.1015C11.4556 15.2722 11.4887 15.4428 11.5625 15.5971V15.5971Z" stroke="#355F92" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
