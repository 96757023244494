/* eslint-disable require-jsdoc */
/* eslint-disable max-len */
import React from 'react';

export default function FlagIcon() {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M4 15H17.865C18.055 14.9999 18.2411 14.9457 18.4014 14.8437C18.5617 14.7417 18.6897 14.5962 18.7702 14.4241C18.8508 14.252 18.8807 14.0605 18.8564 13.8721C18.8321 13.6836 18.7546 13.506 18.633 13.36L15 9L18.633 4.64C18.7546 4.494 18.8321 4.31637 18.8564 4.12792C18.8807 3.93946 18.8508 3.74799 18.7702 3.57591C18.6897 3.40383 18.5617 3.25827 18.4014 3.15627C18.2411 3.05428 18.055 3.00007 17.865 3H4V21' stroke='#355F92' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'/>
    </svg>

  );
}
