/* eslint-disable require-jsdoc */
/* eslint-disable max-len */
import React from 'react';

export default function ProfilePayment() {
  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path fillRule='evenodd' clipRule='evenodd' d='M9.96236 2C10.4042 2 10.7624 2.35817 10.7624 2.8V4.55594H13.4312C13.873 4.55594 14.2312 4.91411 14.2312 5.35594C14.2312 5.79777 13.873 6.15594 13.4312 6.15594H10.7624V9.02878H11.6969C12.5327 9.02878 13.3463 9.33401 13.9558 9.89538C14.5675 10.4588 14.925 11.2377 14.925 12.0652C14.925 12.8927 14.5675 13.6716 13.9558 14.235C13.3463 14.7964 12.5327 15.1016 11.6969 15.1016H10.7624V16.8575C10.7624 17.2993 10.4042 17.6575 9.96236 17.6575C9.52053 17.6575 9.16236 17.2993 9.16236 16.8575V15.1016H5.8C5.35817 15.1016 5 14.7435 5 14.3016C5 13.8598 5.35817 13.5016 5.8 13.5016H9.16236V10.6288H8.22812C7.39223 10.6288 6.57868 10.3235 5.9692 9.76219C5.35751 9.19879 5 8.41989 5 7.59236C5 6.76483 5.35751 5.98593 5.9692 5.42253C6.57868 4.86117 7.39223 4.55594 8.22812 4.55594H9.16236V2.8C9.16236 2.35817 9.52053 2 9.96236 2ZM9.16236 6.15594H8.22812C7.77605 6.15594 7.3544 6.32195 7.05316 6.59941C6.75413 6.87483 6.6 7.23362 6.6 7.59236C6.6 7.9511 6.75413 8.30989 7.05316 8.58531C7.3544 8.86277 7.77605 9.02878 8.22812 9.02878H9.16236V6.15594ZM10.7624 10.6288V13.5016H11.6969C12.1489 13.5016 12.5706 13.3356 12.8718 13.0582C13.1708 12.7827 13.325 12.4239 13.325 12.0652C13.325 11.7065 13.1708 11.3477 12.8718 11.0723C12.5706 10.7948 12.1489 10.6288 11.6969 10.6288H10.7624Z' fill='#1A1B1D'/>
    </svg>

  );
}
