/* eslint-disable require-jsdoc */
/* eslint-disable max-len */
import React from 'react';

export default function HideIcon() {
  return (
    <svg width='21' height='18' viewBox='0 0 21 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M3.5782 0.292893C3.18768 -0.0976311 2.55451 -0.0976311 2.16399 0.292893C1.77346 0.683417 1.77346 1.31658 2.16399 1.70711L4.58815 4.13127C3.15749 5.20737 1.91035 6.68543 0.976667 8.55279C0.835903 8.83432 0.835903 9.16569 0.976667 9.44722C3.13489 13.7637 6.96797 16 10.8711 16C12.4262 16 13.9703 15.645 15.4017 14.9448L18.164 17.7071C18.5545 18.0976 19.1877 18.0976 19.5782 17.7071C19.9687 17.3166 19.9687 16.6834 19.5782 16.2929L3.5782 0.292893ZM13.8849 13.428C12.9054 13.8112 11.8845 14 10.8711 14C7.90231 14 4.86915 12.3792 3.00075 9C3.8183 7.52136 4.85887 6.3794 6.01947 5.56259L8.16347 7.70659C7.97605 8.09822 7.87109 8.53686 7.87109 9.00001C7.87109 10.6569 9.21424 12 10.8711 12C11.3342 12 11.7729 11.8951 12.1645 11.7076L13.8849 13.428Z' fill='#6F7381' />
      <path d='M17.4234 10.8955C17.9064 10.3402 18.3495 9.70876 18.7414 9C16.873 5.62078 13.8398 4 10.8711 4C10.7591 4 10.647 4.00231 10.5348 4.00693L8.75049 2.22258C9.4485 2.07451 10.1586 2 10.8711 2C14.7742 2 18.6073 4.23635 20.7655 8.55279C20.9062 8.83431 20.9062 9.16569 20.7655 9.44721C20.2215 10.5352 19.5711 11.491 18.84 12.3121L17.4234 10.8955Z' fill='#6F7381' />
    </svg>
  );
}
