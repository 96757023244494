import {createSlice, PayloadAction, createAsyncThunk} from '@reduxjs/toolkit';
import {getGlobalData} from '../../services/global-data-store';

export type GlobalData = {
  permissions: [];
  date_format: string;
  globalData: object;
};

const initialState: GlobalData = {
  permissions: [],
  date_format: '',
  globalData: {},
};

export const getGlobalDataAsync = createAsyncThunk(
    'counter/fetchCount',
    async () => {
      const response = await getGlobalData();
      // The value we return becomes the `fulfilled` action payload
      return response;
    },
);

export const globalDataSlice = createSlice({
  name: 'globalData',
  initialState,
  reducers: {
    globalData(state: any, action: PayloadAction<GlobalData>) {
      const data = action.payload;
      state.permissions = data.permissions;
      state.date_format = data.date_format;
      state.globalData = data.globalData;
    },
  },
  extraReducers(builder) {
    builder
        .addCase(getGlobalDataAsync.pending, () => {
        })
        .addCase(getGlobalDataAsync.fulfilled, (state, action) => {
          const data = action.payload;
          state.permissions = data?.permissions;
          state.date_format = data?.date_format;
          state.globalData = data?.globalData;
        })
        .addCase(getGlobalDataAsync.rejected, (state) => {
          console.log('Error in getting global data');
        });
  },
});

export const {globalData} = globalDataSlice.actions;

export default globalDataSlice.reducer;
