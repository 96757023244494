import './App.css';
import React from 'react';
import RoutesGrit from './routes/routes';
import {AuthProvider} from '../src/providers/auth';
import {CookiesProvider} from 'react-cookie';
/**
 * This is a description of the App function.
 * @return {App}
 */
function App() {
  return (
    <div className='App'>
      <CookiesProvider>
        <AuthProvider>
          <RoutesGrit />
        </AuthProvider>
      </CookiesProvider>
    </div>
  );
}

export default App;
