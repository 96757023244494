/* eslint-disable require-jsdoc */
/* eslint-disable max-len */
import React from 'react';

export default function ReadinessIcon() {
  return (
    <svg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M18.879 7.6051L15 3.7261V7.6051H18.879ZM19.5 9.1051H13.5V3.1051H4.5V21.1051H19.5V9.1051ZM3.75 1.6051H15L21 7.6051V21.8551C21 22.054 20.921 22.2448 20.7803 22.3854C20.6397 22.5261 20.4489 22.6051 20.25 22.6051H3.75C3.55109 22.6051 3.36032 22.5261 3.21967 22.3854C3.07902 22.2448 3 22.054 3 21.8551V2.3551C3 2.15619 3.07902 1.96542 3.21967 1.82477C3.36032 1.68412 3.55109 1.6051 3.75 1.6051ZM11.2125 15.2491L15.4545 11.0071L16.515 12.0676L11.2125 17.3701L7.5 13.6591L8.5605 12.5971L11.2125 15.2491Z' fill='#355F92'/>
    </svg>
  );
}
