import React from 'react';
import CanceledIcon from './icons/cancelled-icon';
import CreateUpdateHCIcon from './icons/create-update-HC-icon';
import {
  GritwellLogo,
  ShowIcon,
  HideIcon,
  CheckIcon,
  SuccessCheck,
  BackIcon,
  StatusIcon,
  DashboardIcon,
  ClientsIcon,
  ChatsIcon,
  SettingsIcon,
  LeftMenuIcon,
  NoUserImageIcon,
  ImgUploadIcon,
  InActiveIcon,
  ActiveIcon,
  OpenEyeIcon,
  PlusIcon,
  ProfileUploadIcon,
  DotsIcon,
  SymptomAnalysisIcon,
  IntakeFormIcon,
  PdfIcon,
  MessageIcon,
  DeleteIcon,
  NoUserIcon,
  DownloadsIcon,
  UsersIcon,
  PlansIcon,
  HypothesisIcon,
  NutritionsIcon,
  SupplementsIcon,
  LifestyleIcon,
  TestIcon,
  PendingIcon,
  CloseIcon,
  RootcauseIcon,
  Diagnosisicon,
  CoredysfunctionIcon,
  GoalsIcon,
  RightIcon,
  ReadinessIcon,
  SymptomIcon,
  HealthHistoryIcon,
  FoodDiaryIcon,
  FlagIcon,
  MenuDotIcon,
  NutritionTabIcon,
  TestingTabIcon,
  SupplimentsTabIcon,
  LifestyleTabIcon,
  MessageTabIcon,
  EditIcon,
  DataDeleteIcon,
  ModalCloseIcon,
  ModalWarningIcon,
  ScheduleIcon,
  NoShowIcon,
  LateCancelIcon,
  LateRescheduleIcon,
  LateShowIcon,
  RefundIcon,
  RequestRefundIcon,
  HealthPlanIcon,
  CrossDeleteIcon,
  InfoIcon,
  CompleteHCIcon,
  FillClientHappinessIcon,
  ScheduleFollowUpIcon,
  ReviewPrePostIcon,
  ReviewDocumentsIcon,
  ReviewtestResultsIcon,
  CanceledAppointmentIcon,
  MissedAppointmentIcon,
  BookIntroIcon,
  NewOrderRequestIcon,
  MatchCareTeamIcon,
  ReviewClientHappinessIcon,
  AppointmentAttendenceIcon,
  NewUploadIcon,
  BackArrow,
  AddIcon,
  AttachmentIcon,
  SendSummaryIcon,

} from './icons/index';
import InternalNotesIcon from './icons/internalnotes-icon';
import ReScheduleIcon from './icons/reschedule-icon';
import SymptomHPIcon from './icons/symptom-healthprofile';
import UploadsIcon from './icons/upload-icon';
import NoteIcon from './icons/note-icon';

export const iconNames = {
  gritwell: GritwellLogo,
  showicon: ShowIcon,
  hideicon: HideIcon,
  checkicon: CheckIcon,
  success: SuccessCheck,
  back: BackIcon,
  statusicon: StatusIcon,
  dashboard: DashboardIcon,
  clients: ClientsIcon,
  chats: ChatsIcon,
  settings: SettingsIcon,
  leftMenuIcon: LeftMenuIcon,
  nousericon: NoUserImageIcon,
  uploadIcon: ImgUploadIcon,
  inactiveIcon: InActiveIcon,
  activeIcon: ActiveIcon,
  openeye: OpenEyeIcon,
  plusicon: PlusIcon,
  ProfileUpload: ProfileUploadIcon,
  DotsMenu: DotsIcon,
  SymptomAnalysis: SymptomAnalysisIcon,
  IntakeForm: IntakeFormIcon,
  Pdf: PdfIcon,
  Message: MessageIcon,
  deleteIcon: DeleteIcon,
  noclienticon: NoUserIcon,
  downloadicon: DownloadsIcon,
  uploadicon: UploadsIcon,
  usericon: UsersIcon,
  plansicon: PlansIcon,
  hypothesisicon: HypothesisIcon,
  nutritionicon: NutritionsIcon,
  supplemetsicon: SupplementsIcon,
  lifestyleicon: LifestyleIcon,
  testicon: TestIcon,
  pendingicon: PendingIcon,
  closeicon: CloseIcon,
  rootcauseicon: RootcauseIcon,
  diagnosisicon: Diagnosisicon,
  coredysfunctionIcon: CoredysfunctionIcon,
  goalsicon: GoalsIcon,
  righticon: RightIcon,
  readinessicon: ReadinessIcon,
  symptomicon: SymptomIcon,
  healthhistoryicon: HealthHistoryIcon,
  fooddiaryicon: FoodDiaryIcon,
  flagicon: FlagIcon,
  menudoticon: MenuDotIcon,
  nutritiontabicon: NutritionTabIcon,
  testingtabicon: TestingTabIcon,
  supplimentstabicon: SupplimentsTabIcon,
  lifestyletabicon: LifestyleTabIcon,
  messagetabicon: MessageTabIcon,
  editicon: EditIcon,
  datadeleteicon: DataDeleteIcon,
  modalcloseicon: ModalCloseIcon,
  modalwarningicon: ModalWarningIcon,
  cancelicon: CanceledIcon,
  scheduleicon: ScheduleIcon,
  noshowicon: NoShowIcon,
  latecancelicon: LateCancelIcon,
  rescheduleicon: ReScheduleIcon,
  laterescheduleicon: LateRescheduleIcon,
  lateshowicon: LateShowIcon,
  refundicon: RefundIcon,
  requestrefundicon: RequestRefundIcon,
  healthplanicon: HealthPlanIcon,
  crossdeleteicon: CrossDeleteIcon,
  infoicon: InfoIcon,
  COMPLETE_HEALTH_PROFILE: CompleteHCIcon,
  FILL_CLIENT_HAPPINESS_FORM: FillClientHappinessIcon,
  CREATE_AND_SUBMIT_INITIAL_RECOMMENDATIONS: CreateUpdateHCIcon,
  UPDATE_HEALTH_PLAN: CreateUpdateHCIcon,
  SCHEDULE_FOLLOWUP_APPOINTMENTS: ScheduleFollowUpIcon,
  REVIEW_PRE_APPOINTMENT_FORM: ReviewPrePostIcon,
  REVIEW_POST_APPOINTMENT_FORM: ReviewPrePostIcon,
  REVIEW_ONBOARDING_DOCUMENTS: ReviewDocumentsIcon,
  TEST_RESULT_PUBLISH_TO_HC: ReviewtestResultsIcon,
  CANCEL_APPOINTMENT: CanceledAppointmentIcon,
  MISSED_APPOINTMENT: MissedAppointmentIcon,
  INTRO_CALL_WITH_CLIENT: BookIntroIcon,
  NEW_ORDER_REQUEST: NewOrderRequestIcon,
  MATCH_CARE_TEAM: MatchCareTeamIcon,
  REVIEW_CLIENT_HAPPINESS_FORM: ReviewClientHappinessIcon,
  APPOINTMENT_ATTENDANCE: AppointmentAttendenceIcon,
  PREPARE_ROOT_CAUSE_REPORT: ReviewtestResultsIcon,
  BOOK_HC_INITIAL_APPOINTMENT: BookIntroIcon,
  symptomIcon: SymptomHPIcon,
  internalIcon: InternalNotesIcon,
  SEND_CLIENT_MESSAGE: NoteIcon,
  SEND_SUMMARY_TO_MD: SendSummaryIcon,
  UPLOAD_REQUISITION_FORM: NewUploadIcon,
  MESSAGE_NOT_ANSWERED: MessageIcon,
  backArrow: BackArrow,
  addIcon: AddIcon,
  attachmentIcon: AttachmentIcon,
};

export type IconProps = {
  name: keyof typeof iconNames;
};

/**
 * @param {IconProps} param0
 * @return {Icon}
 */
export default function Icon({name}: IconProps) {
  const IconComponent = iconNames[name];
  return <IconComponent />;
}
