/* eslint-disable require-jsdoc */
/* eslint-disable max-len */
import React from 'react';

export default function ModalWarningIcon() {
  return (
    <svg width='62' height='62' viewBox='0 0 62 62' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <circle cx='31' cy='31' r='31' fill='#F5F7F7'/>
      <path d='M47.3672 37.6642L35.4787 17.9482C34.998 17.1961 34.3357 16.5772 33.5528 16.1485C32.77 15.7198 31.8918 15.4951 30.9992 15.4951C30.1067 15.4951 29.2285 15.7198 28.4456 16.1485C27.6628 16.5772 27.0005 17.1961 26.5197 17.9482L14.6312 37.6642C14.2113 38.3643 13.983 39.1625 13.9694 39.9788C13.9558 40.7951 14.1573 41.6005 14.5537 42.3142C15.012 43.1175 15.6754 43.7847 16.476 44.2477C17.2767 44.7106 18.1859 44.9527 19.1107 44.9492H42.8877C43.8065 44.959 44.7116 44.7263 45.5117 44.2745C46.3118 43.8228 46.9786 43.168 47.4447 42.3762C47.8528 41.6551 48.0607 40.8381 48.0471 40.0096C48.0335 39.1812 47.7988 38.3715 47.3672 37.6642V37.6642ZM44.7322 40.8417C44.5459 41.1619 44.2756 41.4251 43.9505 41.6026C43.6254 41.7802 43.2578 41.8655 42.8877 41.8492H19.1107C18.7406 41.8655 18.3731 41.7802 18.048 41.6026C17.7229 41.4251 17.4525 41.1619 17.2662 40.8417C17.1302 40.6061 17.0586 40.3388 17.0586 40.0667C17.0586 39.7946 17.1302 39.5273 17.2662 39.2917L29.1702 19.5602C29.3841 19.2776 29.6606 19.0485 29.9779 18.8907C30.2953 18.7329 30.6448 18.6508 30.9992 18.6508C31.3536 18.6508 31.7032 18.7329 32.0205 18.8907C32.3379 19.0485 32.6143 19.2776 32.8282 19.5602L44.7167 39.2762C44.858 39.5123 44.9339 39.7817 44.9367 40.0568C44.9394 40.332 44.8688 40.6028 44.7322 40.8417V40.8417Z' fill='#EFAD12'/>
      <path d='M30.9992 38.7494C31.8553 38.7494 32.5492 38.0555 32.5492 37.1994C32.5492 36.3434 31.8553 35.6494 30.9992 35.6494C30.1432 35.6494 29.4492 36.3434 29.4492 37.1994C29.4492 38.0555 30.1432 38.7494 30.9992 38.7494Z' fill='#EFAD12'/>
      <path d='M30.9992 24.7998C30.5881 24.7998 30.1939 24.9631 29.9032 25.2538C29.6125 25.5445 29.4492 25.9387 29.4492 26.3498V32.5498C29.4492 32.9609 29.6125 33.3551 29.9032 33.6458C30.1939 33.9365 30.5881 34.0998 30.9992 34.0998C31.4103 34.0998 31.8046 33.9365 32.0952 33.6458C32.3859 33.3551 32.5492 32.9609 32.5492 32.5498V26.3498C32.5492 25.9387 32.3859 25.5445 32.0952 25.2538C31.8046 24.9631 31.4103 24.7998 30.9992 24.7998Z' fill='#EFAD12'/>
    </svg>

  );
}
