import style from 'styled-components';

export const HeaderMain = style.div`
width: 100%;
padding: 0 16px;
height: 56px;
min-height: 56px;
display: flex;
align-items: center;
width: 100%;
background: white;
padding: 0 16px;
height: 56px;
min-height: 56px;
display: flex;
align-items: center;
justify-content: center;
z-index: 11;
box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.08);
border-bottom: 1px solid rgb(0 0 0 / 8%);
.defaultName{
    margin:0px;
}
.chatIcon{
    top: 4px !important;
}
`;
export const Ulstyles = style.ul`
width:100%;
display:flex;
justify-content:space-around;
align-items:center;
`;
