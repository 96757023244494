/* eslint-disable require-jsdoc */
/* eslint-disable max-len */
import React from 'react';

export default function CanceledAppointmentIcon() {
  return (
    <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M26.5 5H6.5C5.94772 5 5.5 5.44772 5.5 6V26C5.5 26.5523 5.94772 27 6.5 27H26.5C27.0523 27 27.5 26.5523 27.5 26V6C27.5 5.44772 27.0523 5 26.5 5Z" stroke="#1596B7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M22.5 3V7" stroke="#1596B7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M10.5 3V7" stroke="#1596B7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M5.5 11H27.5" stroke="#1596B7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M19.5 16L13.5 22" stroke="#1596B7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M19.5 22L13.5 16" stroke="#1596B7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
