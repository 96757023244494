/* eslint-disable require-jsdoc */
/* eslint-disable max-len */
import React from 'react';

export default function MenuDotIcon() {
  return (
    <svg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path fillRule='evenodd' clipRule='evenodd' d='M0.65328 4.74094C1.30802 3.22573 2.43674 1.95708 3.56253 1.05302C4.71759 0.174883 5.86981 -0.338757 7.2464 0.256316C8.60938 0.868306 10.1823 2.22989 11.0972 3.65283C12.0341 5.10673 12.313 6.62198 11.5936 8.2557C10.9197 9.88063 9.3611 10.8229 7.78037 11.4153C6.17432 11.9822 4.54613 12.1992 3.14737 11.7848C1.73084 11.335 0.669343 10.3459 0.241856 9.08175C-0.203564 7.81799 -0.0149766 6.27911 0.65328 4.74094Z' fill='#F0F0F0'/>
    </svg>

  );
}
