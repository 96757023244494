/* eslint-disable max-len */
import React, {useContext, useEffect, useState} from 'react';
import Loginheader from '../login-header';
import {AuthContext} from '../../providers/auth';
import CustomerLoginheader from '../customer-login-header/customer-login-header';

const LoginCommonLayout = () => {
  const {userInfo}: any = useContext(AuthContext);
  const [role, setRole] = useState('');

  useEffect(() => {
    userInfo && setRole(userInfo.code);
  }, [userInfo]);

  return (
    <>{((role === 'admin' || role === 'md' || role === 'health_coach' ||
    role === 'sales_manager' ||
      role === 'care_manager') && <Loginheader />) ||
      (role === 'customer' && <CustomerLoginheader />)
    }
    </>
  );
};

export default LoginCommonLayout;
