import {PiHeaderMenu} from 'pixel-kit';
import React, {useEffect, useState} from 'react';
import {HeaderMain} from '../header/header-components';
import Logo from '../../assets/Vector.svg';
import MobileLogo from '../../assets/mobileLogo.svg';
// const Logo = require('../../assets/Vector.svg') as string;
// const MobileLogo = require('../../assets/mobileLogo.svg') as string;
import {useHistory} from 'react-router-dom';
import Icon from '../icon';
import {triggerApi} from '../../services';
import {clearLocalStorage, getLocalStorage} from '../../core/local-storage-service';
import apiEndpoint from '../../core/apiend_point';
import {
  ApiResponseProps,
  PayloadProps,
  Permissions,
  ProfileProps,
  SidenavProps,
} from '../../core/schema';
import {useAppSelector} from '../../store/hooks';
import {checkUserPermissions} from '../../core/checkUserPermission';
// import {useCookies} from 'react-cookie';

/**
 *
 * @return {CustomerLoginheader}
 */
export default function CustomerLoginheader() {
  const [headerLinkactive, setHeaderLinkactive] = useState('dashboard');
  const [hideHeader, setHideheader] = useState(false);
  const history = useHistory();
  const userPermissions: Permissions[] = useAppSelector((state) =>
    state.globalData.permissions);
  // const [cookies, setCookie, removeCookie] = useCookies(['token']);

  useEffect(() => {
    if (userPermissions.length > 0) {
      getMenuItems();
    }
  }, [userPermissions]);

  /**
   *
   */
  function getMenuItems() {
    const memu = [];
    if (checkUserPermissions(userPermissions, 'dashboard') === true) {
      memu.push({
        key: 'dashboard',
        label: 'Dashboard',
        children: [],
        href: '',
      });
    }
  }

  const MenuClick = (menu: SidenavProps) => {
    setHeaderLinkactive(menu.key);
  };

  const profileClick = (menu: ProfileProps) => {
    if (menu.id === 'logout') {
      logout();
    } else if (menu.id === 'profile') {
      history.push('/customer/profile');
    }
  };
  const token = getLocalStorage('token') ? getLocalStorage('token') : '';

  const logout = () => {
    const apiObject: PayloadProps = {
      payload: {},
      method: 'POST',
      apiUrl: apiEndpoint.logoutApi,
      headers: {
        Authorization: token,
      },
    };
    triggerApi(apiObject)
        .then((res: ApiResponseProps) => {
          setHideheader(true);
          clearLocalStorage();
          // removeCookie('token', {path: '/'});
          history.push('/sign-in');
          window.location.reload();
        })
        .catch((err: Record<string, unknown>) => {
        });
  };

  return (
    <>

      {!hideHeader && <HeaderMain >
        <PiHeaderMenu
          activeKey={headerLinkactive}
          image={Logo}
          xsImage= {MobileLogo}
          onMenuClick={MenuClick}
          onProfileClick={(e: any) => {
            profileClick(e);
          }}
          options={[
            {
              icon: <Icon name='dashboard' />,
              key: 'dashboard',
              label: 'Dashboard',
              children: [],
              href: '',
            },

          ]}
          profileOptions={[
            {
              menuItems: [
                {
                  elemBefore: <svg fill='none' height='32' viewBox='0 0 32 32' width='32' xmlns='http://www.w3.org/2000/svg'><rect fill='white' height='32' rx='4' width='32' /><rect height='15' rx='2' stroke='black' strokeWidth='1.6' width='16.6667' x='7.66699' y='9.33398' /><path d='M19.333 7.66602L19.333 10.666C19.333 11.6088 19.333 12.0802 19.0401 12.3731C18.7472 12.666 18.2758 12.666 17.333 12.666L14.6663 12.666C13.7235 12.666 13.2521 12.666 12.9592 12.3731C12.6663 12.0802 12.6663 11.6088 12.6663 10.666L12.6663 7.66602' stroke='black' strokeLinecap='round' strokeWidth='1.6' /><path d='M12.667 17.666L19.3337 17.666' stroke='black' strokeLinecap='round' strokeWidth='1.6' /></svg>,
                  id: 1,
                  name: 'Health Plan',
                },
                {
                  elemBefore: <svg fill='none' height='32' viewBox='0 0 32 32' width='32' xmlns='http://www.w3.org/2000/svg'><rect fill='white' height='32' rx='4' width='32' /><rect height='15' rx='2' stroke='black' strokeWidth='1.6' width='16.6667' x='7.66699' y='9.33398' /><path d='M19.333 7.66602L19.333 10.666C19.333 11.6088 19.333 12.0802 19.0401 12.3731C18.7472 12.666 18.2758 12.666 17.333 12.666L14.6663 12.666C13.7235 12.666 13.2521 12.666 12.9592 12.3731C12.6663 12.0802 12.6663 11.6088 12.6663 10.666L12.6663 7.66602' stroke='black' strokeLinecap='round' strokeWidth='1.6' /><path d='M12.667 17.666L19.3337 17.666' stroke='black' strokeLinecap='round' strokeWidth='1.6' /></svg>,
                  id: 2,
                  name: 'Health Profile',
                },
                {
                  elemBefore: <svg fill='none' height='32' viewBox='0 0 32 32' width='32' xmlns='http://www.w3.org/2000/svg'><rect fill='white' height='32' rx='4' width='32' /><rect height='15' rx='2' stroke='black' strokeWidth='1.6' width='16.6667' x='7.66699' y='9.33398' /><path d='M19.333 7.66602L19.333 10.666C19.333 11.6088 19.333 12.0802 19.0401 12.3731C18.7472 12.666 18.2758 12.666 17.333 12.666L14.6663 12.666C13.7235 12.666 13.2521 12.666 12.9592 12.3731C12.6663 12.0802 12.6663 11.6088 12.6663 10.666L12.6663 7.66602' stroke='black' strokeLinecap='round' strokeWidth='1.6' /><path d='M12.667 17.666L19.3337 17.666' stroke='black' strokeLinecap='round' strokeWidth='1.6' /></svg>,
                  id: 3,
                  name: 'User Settings',
                },
              ],
              title: 'Admin',
            },
            {
              menuItems: [
                {
                  id: 1,
                  name: 'My Profile',
                },
                {
                  id: 2,
                  name: 'Logout',
                },
              ],
              title: 'My account',
            },
          ]}
        />
      </HeaderMain>} </>

  );
}
