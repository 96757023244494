/* eslint-disable require-jsdoc */
/* eslint-disable max-len */
import React from 'react';

export default function ClientsIcon() {
  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M8 2C5.79086 2 4 3.79086 4 6C4 8.20914 5.79086 10 8 10C10.2091 10 12 8.20914 12 6C12 3.79086 10.2091 2 8 2ZM2 6C2 2.68629 4.68629 0 8 0C11.3137 0 14 2.68629 14 6C14 9.31371 11.3137 12 8 12C4.68629 12 2 9.31371 2 6ZM14.8284 1.75736C15.219 1.36683 15.8521 1.36683 16.2426 1.75736C18.5858 4.1005 18.5858 7.8995 16.2426 10.2426C15.8521 10.6332 15.219 10.6332 14.8284 10.2426C14.4379 9.85212 14.4379 9.21895 14.8284 8.82843C16.3905 7.26633 16.3905 4.73367 14.8284 3.17157C14.4379 2.78105 14.4379 2.14788 14.8284 1.75736ZM15.5299 14.7575C15.6638 14.2217 16.2067 13.8959 16.7425 14.0299C18.0705 14.3618 18.911 15.2109 19.3944 16.1778C19.8622 17.1133 20 18.1571 20 19C20 19.5523 19.5523 20 19 20C18.4477 20 18 19.5523 18 19C18 18.3429 17.8878 17.6367 17.6056 17.0722C17.339 16.5391 16.9295 16.1382 16.2575 15.9701C15.7217 15.8362 15.3959 15.2933 15.5299 14.7575ZM4.5 16C3.24054 16 2 17.2135 2 19C2 19.5523 1.55228 20 1 20C0.447715 20 0 19.5523 0 19C0 16.3682 1.89347 14 4.5 14H11.5C14.1065 14 16 16.3682 16 19C16 19.5523 15.5523 20 15 20C14.4477 20 14 19.5523 14 19C14 17.2135 12.7595 16 11.5 16H4.5Z' fill='#6F7381' />
    </svg>
  );
}

