import React, {useEffect, useState} from 'react';
import {getLocalStorage} from '../../core/local-storage-service';
import {useAppDispatch} from '../../store/hooks';
import {getGlobalDataAsync} from '../../store/modules/globalData';
import LoginCommonLayout from '../login-commonlayout/login-commonlayout';

/**
 *
 * @return {CommonLayout}
 */
export default function CommonLayout() {
  const token = getLocalStorage('token') ? getLocalStorage('token') : '';
  const [hideHeader, setHideheader] = useState(false);
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (token) {
      getGloblaData();
    }

    if (window.location.href.includes('recoverypassword')) {
      setHideheader(true);
    }
  }, [token, hideHeader]);

  /**
   *
   */
  async function getGloblaData() {
    await dispatch(getGlobalDataAsync());
  }


  return (
    (token && !hideHeader) ? <LoginCommonLayout /> : <></>
  );
}
