/* eslint-disable require-jsdoc */
/* eslint-disable max-len */
import React from 'react';

export default function NewOrderRequestIcon() {
  return (
    <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M28.5 7H4.5C3.94772 7 3.5 7.44772 3.5 8V11C3.5 11.5523 3.94772 12 4.5 12H28.5C29.0523 12 29.5 11.5523 29.5 11V8C29.5 7.44772 29.0523 7 28.5 7Z" stroke="#75C3AA" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M27.5 12V24C27.5 24.2652 27.3946 24.5196 27.2071 24.7071C27.0196 24.8946 26.7652 25 26.5 25H6.5C6.23478 25 5.98043 24.8946 5.79289 24.7071C5.60536 24.5196 5.5 24.2652 5.5 24V12" stroke="#75C3AA" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M13.5 17H19.5" stroke="#75C3AA" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
