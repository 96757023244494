
import {
  lazy} from 'react';
const Profile = lazy(() =>
  import('../modules/admin-profile/component/admin-profile'));
const AcuityAppointment = lazy(() =>
  import('../modules/care-manager/care-client/acuity-appointment'));
const MDClients = lazy(() =>
  import('../modules/md/component'));
const MDDashboard = lazy(() =>
  import('../modules/md/component/md-dashboard'));
const MDCompletedTodos = lazy(() =>
  import('../modules/md/component/md-completed_todos'));
export const mdRoutes = [

  {
    path: '/office/clients',
    component: MDClients,
    private: true,
  },
  {
    path: '/office/root_cause',
    component: MDClients,
    private: true,
  },
  {
    path: '/office/leads',
    component: MDClients,
    private: true,
  },
  {
    path: '/office/dashboard',
    component: MDDashboard,
    private: true,
  },
  {
    path: '/office/dashboard/completedtodos',
    component: MDCompletedTodos,
    private: true,
  },
  {
    path: '/office/profile',
    component: Profile,
    private: true,
  },
  {
    path: '/office/myschedule/:id/appointment',
    component: AcuityAppointment,
    private: true,
  },
  {
    path: '/office/dashboard/:id/appointment',
    component: AcuityAppointment,
    private: true,
  },
];
