/* eslint-disable require-jsdoc */
/* eslint-disable max-len */
import React from 'react';

export default function CreateUpdateHCIcon() {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M26.4205 5.59089C26.3004 5.4715 26.1487 5.38883 25.9831 5.35262C25.8176 5.3164 25.6452 5.32814 25.4861 5.38646L5.90781 12.4972C5.73896 12.5612 5.5936 12.6749 5.49102 12.8234C5.38843 12.9718 5.3335 13.1479 5.3335 13.3283C5.3335 13.5086 5.38843 13.6847 5.49102 13.8332C5.5936 13.9816 5.73896 14.0954 5.90781 14.1593L14.4511 17.5725L17.8684 26.1054C17.9326 26.2666 18.0426 26.4056 18.1849 26.5051C18.3273 26.6046 18.4956 26.6603 18.6693 26.6654C18.8491 26.6617 19.0236 26.6037 19.1698 26.4989C19.3159 26.3942 19.4269 26.2477 19.488 26.0787L26.6074 6.52418C26.668 6.36692 26.6826 6.19569 26.6495 6.03045C26.6165 5.8652 26.537 5.71276 26.4205 5.59089ZM18.6693 23.2878L16.1864 17.0659L20.4491 12.8083L19.1943 11.555L14.896 15.8481L8.70216 13.3327L24.3025 7.70634L18.6693 23.2878Z" fill="#355F92" />
    </svg>
  );
}
