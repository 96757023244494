/* eslint-disable require-jsdoc */
/* eslint-disable max-len */
import React from 'react';

export default function SuccessCheck() {
  return (
    <svg width='21' height='20' viewBox='0 0 21 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M10.8708 2C6.45257 2 2.87085 5.58172 2.87085 10C2.87085 14.4183 6.45257 18 10.8708 18C15.2891 18 18.8708 14.4183 18.8708 10C18.8708 5.58172 15.2891 2 10.8708 2ZM0.87085 10C0.87085 4.47715 5.348 0 10.8708 0C16.3937 0 20.8708 4.47715 20.8708 10C20.8708 15.5228 16.3937 20 10.8708 20C5.348 20 0.87085 15.5228 0.87085 10ZM15.5352 6.75259C15.948 7.11951 15.9852 7.75158 15.6183 8.16436L10.2849 14.1644C10.0952 14.3779 9.82315 14.5 9.53752 14.5C9.25188 14.5 8.97987 14.3779 8.79011 14.1644L6.12344 11.1644C5.75652 10.7516 5.7937 10.1195 6.20649 9.75259C6.61927 9.38567 7.25134 9.42285 7.61826 9.83564L9.53752 11.9948L14.1234 6.83564C14.4904 6.42285 15.1224 6.38567 15.5352 6.75259Z' fill='#355F92' />
    </svg>
  );
}
