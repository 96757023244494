/* eslint-disable require-jsdoc */
/* eslint-disable max-len */
import React from 'react';

export default function BookIntroIcon() {
  return (
    <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M27.1663 15.2484H23.6383C23.1705 15.2484 22.7218 15.4343 22.391 15.7651C22.0602 16.0959 21.8744 16.5446 21.8744 17.0124V21.4224C21.8744 21.8902 22.0602 22.3389 22.391 22.6697C22.7218 23.0005 23.1705 23.1864 23.6383 23.1864H25.4023C25.8702 23.1864 26.3189 23.0005 26.6497 22.6697C26.9805 22.3389 27.1663 21.8902 27.1663 21.4224M27.1663 15.2484V21.4224M27.1663 15.2484C27.1664 13.851 26.8897 12.4675 26.3523 11.1776C25.8149 9.88773 25.0274 8.71702 24.0352 7.73305C23.0431 6.74907 21.8659 5.97131 20.5716 5.44463C19.2773 4.91795 17.8915 4.65279 16.4942 4.66443C15.0978 4.65425 13.7132 4.92049 12.4201 5.44783C11.1271 5.97516 9.95122 6.75316 8.96022 7.73699C7.96922 8.72082 7.1827 9.89103 6.64598 11.1802C6.10927 12.4694 5.83297 13.852 5.83301 15.2484M27.1663 21.4224V24.0685C27.1663 25.0041 26.7946 25.9015 26.133 26.5631C25.4714 27.2247 24.574 27.5964 23.6383 27.5964H17.299M5.83301 15.2484V21.4224C5.83301 21.8902 6.01886 22.3389 6.34967 22.6697C6.68048 23.0005 7.12916 23.1864 7.597 23.1864H9.361C9.82884 23.1864 10.2775 23.0005 10.6083 22.6697C10.9391 22.3389 11.125 21.8902 11.125 21.4224V17.0124C11.125 16.5446 10.9391 16.0959 10.6083 15.7651C10.2775 15.4343 9.82884 15.2484 9.361 15.2484H5.83301Z" stroke="#1596B7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
