/* eslint-disable require-jsdoc */
/* eslint-disable max-len */
import React from 'react';

export default function EyeOepnIcon() {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M22.0827 11.3953C19.8608 6.71484 16.5023 4.35938 11.9999 4.35938C7.49521 4.35938 4.13896 6.71484 1.91708 11.3977C1.82796 11.5864 1.78174 11.7925 1.78174 12.0012C1.78174 12.2099 1.82796 12.416 1.91708 12.6047C4.13896 17.2852 7.49755 19.6406 11.9999 19.6406C16.5046 19.6406 19.8608 17.2852 22.0827 12.6023C22.2632 12.2227 22.2632 11.782 22.0827 11.3953V11.3953ZM11.9999 17.9531C8.21943 17.9531 5.45146 16.0359 3.49911 12C5.45146 7.96406 8.21943 6.04688 11.9999 6.04688C15.7804 6.04688 18.5483 7.96406 20.5007 12C18.5507 16.0359 15.7827 17.9531 11.9999 17.9531ZM11.9062 7.875C9.62802 7.875 7.78115 9.72188 7.78115 12C7.78115 14.2781 9.62802 16.125 11.9062 16.125C14.1843 16.125 16.0312 14.2781 16.0312 12C16.0312 9.72188 14.1843 7.875 11.9062 7.875ZM11.9062 14.625C10.4554 14.625 9.28115 13.4508 9.28115 12C9.28115 10.5492 10.4554 9.375 11.9062 9.375C13.3569 9.375 14.5312 10.5492 14.5312 12C14.5312 13.4508 13.3569 14.625 11.9062 14.625Z' fill='#68787A' />
    </svg>
  );
}
