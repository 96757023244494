/* eslint-disable require-jsdoc */
/* eslint-disable max-len */
import React from 'react';

export default function InternalNotesIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 15H9V12L18 3L21 6L12 15Z" stroke="#355f92" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M15.75 5.25L18.75 8.25" stroke="#355f92" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M20.25 11.25V19.5C20.25 19.6989 20.171 19.8897 20.0303 20.0303C19.8897 20.171 19.6989 20.25 19.5 20.25H4.5C4.30109 20.25 4.11032 20.171 3.96967 20.0303C3.82902 19.8897 3.75 19.6989 3.75 19.5V4.5C3.75 4.30109 3.82902 4.11032 3.96967 3.96967C4.11032 3.82902 4.30109 3.75 4.5 3.75H12.75" stroke="#355f92" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>

  );
}


