/* eslint-disable require-jsdoc */
/* eslint-disable max-len */
import React from 'react';

export default function UploadsIcon() {
  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M9.16699 12.5002H10.8337V7.50016H13.3337L10.0003 3.3335L6.66699 7.50016H9.16699V12.5002Z' fill='#F5F7F7' />
      <path d='M16.667 14.9998H3.33366V9.1665H1.66699V14.9998C1.66699 15.919 2.41449 16.6665 3.33366 16.6665H16.667C17.5862 16.6665 18.3337 15.919 18.3337 14.9998V9.1665H16.667V14.9998Z' fill='#F5F7F7' />
    </svg>

  );
}
