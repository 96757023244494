

import {
  lazy} from 'react';
const Chat = lazy(() =>
  import('../components/chat'));
const FeedbackTypeForm = lazy(() =>
  import('../modules/care-manager/care-client/feedback-typeform'));
const Redirection = lazy(() =>
  import('../components/redirection/redirection-component'));
const ClientDetailPayment = lazy(() =>
  import('../modules/care-manager/care-client/client-detail-payment'));
const PasswordResetComponent = lazy(() =>
  import('../components/password-reset-component/password-reset'));
const ViewHealthPlan = lazy(() =>
  import('../modules/health-plan/component/view-healthplan'));
const ClientHappynessTypeForm = lazy(() =>
  import('../modules/care-manager/care-client/client-happiness-typeform'));
const CareManagerClient = lazy(() =>
  import('../modules/care-manager/care-client/care-clients'));
const Payment = lazy(() =>
  import('../modules/payment/component/payment'));
const ViewDocument = lazy(() =>
  import('../modules/care-manager/care-client/view-document'));
const SignIn = lazy(() =>
  import('../modules/sign-in/component/sign-in'));
const RecoveryPassword = lazy(() =>
  import('../modules/recovery-password/component/recovery-password'));
const ForgotPassword = lazy(() =>
  import('../modules/forgot-password/component/forgot-password'));
const ChangePassword = lazy(() =>
  import('../modules/change-password/component/change-password'));
const PermissionDenied = lazy(() =>
  import('../components/permission-denied/permission-denied'));
const Onboard = lazy(() =>
  import('../modules/health-plan/component'));
const HealthPlan = lazy(() =>
  import('../modules/health-plan/component/health-plan'));
const Myschedule = lazy(() =>
  import('../modules/my-schedule/component/myschedule'));
const HealthProfileTabGroup = lazy(() =>
  import('../modules/hypothesis/component/tabgroup-healthProfile'));
const HealthPlanTabGroup = lazy(() =>
  import('../modules/recommendations/component/tabgroup_healthplan'));
const AcuityAppointment = lazy(() =>
  import('../modules/care-manager/care-client/acuity-appointment'));
const RootCauseDetail = lazy(() =>
  import('../modules/root_cause/component/root-cause-detail'));
const PostPaymentPayment = lazy(() =>
  import('../modules/health-coach/component/post-membership'));
const PreviousMessage = lazy(() =>
  import('../modules/health-plan/component/previous-messages'));
const HCPlanSelection = lazy(() =>
  import('../modules/health-coach/component/plans-page'));
const HCPayment = lazy(() =>
  import('../modules/health-coach/component/add-payment'));
const PaymentSuccess = lazy(() =>
  import('../modules/health-coach/component/payment-success'));
const CreateMessage = lazy(() =>
  import('../modules/health-coach/component/create-message'));
const SendIntialMsg = lazy(() =>
  import('../modules/health-coach/component/send-initialmsg'));

export const commonRoutes = [
  {
    path: '/',
    component: Redirection,
    private: false,
  },
  {
    path: '/sign-in',
    component: SignIn,
    private: false,
  },
  {
    path: '/forgotpassword',
    component: ForgotPassword,
    private: false,
  },
  {
    path: '/recoverypassword/:id/:type',
    component: RecoveryPassword,
    private: false,
  },
  {
    path: '/changepassword',
    component: ChangePassword,
    private: true,
  },
  {
    path: '/permission-denied',
    component: PermissionDenied,
    private: false,
  },
  {
    path: '/office/client/:id/:msg_id/create-message',
    component: CreateMessage,
    private: true,
  },
  {
    path: '/office/client/:id/intial-message',
    component: SendIntialMsg,
    private: true,
  },
  {
    path: '/office/client/appointment/:id/:tab',
    component: AcuityAppointment,
    private: true,
  },
  {
    path: '/office/client/clientpayment/:id/',
    component: ClientDetailPayment,
    private: true,
  },
  {
    path: '/office/client/plandetails/:id',
    component: PostPaymentPayment,
    private: true,
  },
  {
    path: '/office/client/:type_form_id/:client_id/:id',
    component: ViewDocument,
    private: true,
  },
  {
    path: '/office/client/:id/client-happiness-form',
    component: ClientHappynessTypeForm,
    private: true,
  },

  {
    path: '/office/client/:id/create-message',
    component: CreateMessage,
    private: true,
  },
  {
    path: '/office/client/:id/:tab',
    component: CareManagerClient,
    private: true,
  },
  {
    path: '/office/root_cause/:id/:tab',
    component: RootCauseDetail,
    private: true,
  },
  {
    path: '/office/myschedule/:tab',
    component: Myschedule,
    private: true,
  },
  {
    path: '/office/chats',
    component: Chat,
    private: true,
  },
  {
    path: '/office/myschedule/:type_form_id/:client_id/:id',
    component: ViewDocument,
    private: true,
  },
  {
    path: '/office/health-plan/:id',
    component: Onboard,
    private: true,
  },
  {
    path: '/office/health-plan/:id/:health_plan_id',
    component: Onboard,
    private: true,
  },
  {
    path: '/office/health-plan/:health_plan_id/:id/:tab',
    component: HealthPlan,
    private: true,
  },
  {
    path: '/office/view-health-plan/:health_plan_id/:id',
    component: ViewHealthPlan,
    private: true,
  },
  {
    path: '/office/payment/:tab',
    component: Payment,
    private: true,
  },
  {
    path: '/office/healthplans/:tab',
    component: HealthPlanTabGroup,
    private: true,
  },
  {
    path: '/office/healthprofiles/:tab',
    component: HealthProfileTabGroup,
    private: true,
  },
  {
    path: '/passwordreset',
    component: PasswordResetComponent,
    private: false,
  },
  {
    path: '/office/clients/feedbackform',
    component: FeedbackTypeForm,
    private: true,
  },
  {
    path: '/office/client/previousmsgs',
    component: PreviousMessage,
    private: true,
  },
  {
    path: '/office/plans/:id',
    component: HCPlanSelection,
    private: true,
  },
  {
    path: '/office/add-payment/:id',
    component: HCPayment,
    private: true,
  },
  {
    path: '/office/payment-success/:id',
    component: PaymentSuccess,
    private: true,
  },
];

