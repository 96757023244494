/* eslint-disable require-jsdoc */
/* eslint-disable max-len */
import React from 'react';

export default function ReviewtestResultsIcon() {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.48413 16.7886C9.48413 16.7886 11.5588 15.1289 14.8784 16.7886C18.1979 18.4484 20.2726 16.7886 20.2726 16.7886M24.0072 13.054L12.1814 24.8798C11.8277 25.2349 11.4074 25.5166 10.9445 25.7088C10.4817 25.901 9.98546 26 9.48429 26C8.98312 26 8.48688 25.901 8.02405 25.7088C7.56121 25.5166 7.14088 25.2349 6.78717 24.8798C6.43212 24.5261 6.1504 24.1058 5.95817 23.6429C5.76594 23.1801 5.66699 22.6839 5.66699 22.1827C5.66699 21.6815 5.76594 21.1853 5.95817 20.7225C6.1504 20.2596 6.43212 19.8393 6.78717 19.4856L20.2728 6L26.4969 12.2241L24.0072 13.054Z" stroke="#F48621" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
