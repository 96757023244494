/* eslint-disable require-jsdoc */
/* eslint-disable max-len */
import React from 'react';

export default function ProfileRecommendation() {
  return (

    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path fillRule='evenodd' clipRule='evenodd' d='M5.61133 2.5332H14.3878C15.2838 2.53317 16.0359 2.53314 16.634 2.61356C17.2674 2.69871 17.8465 2.887 18.3128 3.35331C18.7791 3.81962 18.9674 4.39873 19.0525 5.03206C19.1329 5.63021 19.1329 6.38233 19.1329 7.27831V14.3881C19.1329 15.2841 19.1329 16.0362 19.0525 16.6343C18.9674 17.2677 18.7791 17.8468 18.3128 18.3131C17.8465 18.7794 17.2674 18.9677 16.634 19.0528C16.0359 19.1333 15.2837 19.1332 14.3878 19.1332H5.61134C4.71535 19.1332 3.96323 19.1333 3.36507 19.0528C2.73173 18.9677 2.15262 18.7794 1.68631 18.3131C1.22001 17.8468 1.03172 17.2677 0.946571 16.6343C0.866151 16.0362 0.866179 15.2841 0.866212 14.3881V7.27832C0.866179 6.38233 0.866151 5.63022 0.946571 5.03206C1.03172 4.39873 1.22001 3.81962 1.68631 3.35331C2.15262 2.887 2.73173 2.69871 3.36507 2.61356C3.96322 2.53314 4.71534 2.53317 5.61133 2.5332ZM3.57827 4.1993C3.12235 4.26059 2.93716 4.3652 2.81769 4.48468C2.69821 4.60416 2.5936 4.78934 2.5323 5.24526C2.46791 5.72419 2.46621 6.36778 2.46621 7.33321V14.3332C2.46621 15.2986 2.46791 15.9422 2.5323 16.4212C2.5936 16.8771 2.69821 17.0623 2.81769 17.1817C2.93716 17.3012 3.12235 17.4058 3.57827 17.4671C4.0572 17.5315 4.70079 17.5332 5.66621 17.5332H14.3329C15.2983 17.5332 15.9419 17.5315 16.4208 17.4671C16.8767 17.4058 17.0619 17.3012 17.1814 17.1817C17.3009 17.0623 17.4055 16.8771 17.4668 16.4212C17.5312 15.9422 17.5329 15.2986 17.5329 14.3332V7.33321C17.5329 6.36778 17.5312 5.72419 17.4668 5.24526C17.4055 4.78934 17.3009 4.60416 17.1814 4.48468C17.0619 4.3652 16.8767 4.26059 16.4208 4.1993C15.9419 4.1349 15.2983 4.13321 14.3329 4.13321H5.66621C4.70079 4.13321 4.0572 4.1349 3.57827 4.1993Z' fill='black'/>
      <path fillRule='evenodd' clipRule='evenodd' d='M12.5043 5.65786C12.5321 5.45124 12.5338 5.1612 12.5338 4.66718L12.5338 1.66719C12.5338 1.22536 12.892 0.867187 13.3338 0.867187C13.7756 0.867187 14.1338 1.22536 14.1338 1.66719L14.1338 4.66718C14.1338 4.68284 14.1338 4.69845 14.1338 4.71402C14.1338 5.14497 14.1339 5.54483 14.09 5.87105C14.0415 6.23208 13.9264 6.62011 13.6066 6.93998C13.2867 7.25984 12.8987 7.3749 12.5377 7.42344C12.2114 7.4673 11.8116 7.46725 11.3806 7.46719C11.365 7.46719 11.3494 7.46718 11.3338 7.46718L8.66711 7.46719C8.65146 7.46719 8.63585 7.46719 8.62028 7.46719C8.18933 7.46725 7.78947 7.4673 7.46324 7.42344C7.10222 7.3749 6.71418 7.25984 6.39432 6.93998C6.07446 6.62012 5.9594 6.23208 5.91086 5.87106C5.867 5.54483 5.86705 5.14497 5.86711 4.71402C5.86711 4.69845 5.86711 4.68284 5.86711 4.66719L5.86711 1.66719C5.86711 1.22536 6.22529 0.867191 6.66711 0.867191C7.10894 0.867192 7.46711 1.22536 7.46711 1.66719L7.46711 4.66719C7.46711 5.16121 7.46881 5.45124 7.49659 5.65786C7.50774 5.74075 7.52025 5.78502 7.52814 5.80616C7.54928 5.81405 7.59355 5.82656 7.67644 5.83771C7.88306 5.86549 8.17309 5.86719 8.66711 5.86719L11.3338 5.86718C11.8278 5.86718 12.1178 5.86549 12.3245 5.83771C12.4073 5.82656 12.4516 5.81405 12.4728 5.80616C12.4806 5.78502 12.4932 5.74075 12.5043 5.65786ZM12.4648 5.82261C12.4647 5.82229 12.4659 5.81976 12.4687 5.816C12.4664 5.82106 12.465 5.82293 12.4648 5.82261ZM12.4826 5.80212C12.4864 5.79929 12.4889 5.79813 12.4892 5.79825C12.4895 5.79838 12.4877 5.79979 12.4826 5.80212ZM7.51169 5.79825C7.51201 5.79813 7.51454 5.79929 7.5183 5.80212C7.51324 5.79979 7.51136 5.79838 7.51169 5.79825ZM7.53218 5.816C7.53501 5.81976 7.53617 5.82229 7.53605 5.82261C7.53592 5.82294 7.53451 5.82106 7.53218 5.816Z' fill='black'/>
      <path fillRule='evenodd' clipRule='evenodd' d='M5.86621 11.6672C5.86621 11.2254 6.22438 10.8672 6.66621 10.8672L13.3329 10.8672C13.7747 10.8672 14.1329 11.2254 14.1329 11.6672C14.1329 12.109 13.7747 12.4672 13.3329 12.4672L6.66621 12.4672C6.22438 12.4672 5.86621 12.109 5.86621 11.6672Z' fill='black'/>
    </svg>

  );
}

