/* eslint-disable require-jsdoc */
/* eslint-disable max-len */
import React from 'react';

export default function RightIcon() {
  return (
    <svg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M8.74616 16.1937L12.9755 12.3686C12.9858 12.3594 12.9956 12.3495 13.0048 12.3392C13.1525 12.1749 13.1389 11.922 12.9745 11.7744L8.79479 8.0207C8.42454 7.68819 8.39324 7.11771 8.72487 6.74649C9.05651 6.37526 9.6255 6.34388 9.99574 6.67639L15.2515 11.3964C15.6499 11.7542 15.651 12.3794 15.2538 12.7386L9.9518 17.5338C9.58272 17.8676 9.01362 17.8383 8.6807 17.4682C8.34777 17.0981 8.37708 16.5276 8.74616 16.1937Z' fill='#767B7C'/>
    </svg>

  );
}
