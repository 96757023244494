/* eslint-disable require-jsdoc */
/* eslint-disable max-len */
import React from 'react';

export default function LateShowIcon() {
  return (
    <svg width='8' height='8' viewBox='0 0 8 8' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <circle cx='4' cy='4' r='4' fill='#E08E00' />
    </svg>

  );
}
