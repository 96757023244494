import {configureStore, ThunkAction, Action} from '@reduxjs/toolkit';
import globalDataReducer from '../store/modules/globalData';
import clientNotesReducer from '../store/modules/clientNotes';

export const store = configureStore({
  reducer: {
    globalData: globalDataReducer,
    clientNotes: clientNotesReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
ReturnType,
RootState,
unknown,
Action<string>
>;
