/* eslint-disable max-len */
import axios from 'axios';
import {clearLocalStorage, getLocalStorage} from '../core/local-storage-service';
import {formatPayload} from '../services/general';

const token = getLocalStorage('token');
const Http = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
  },
  withCredentials: true,
  responseType: 'json',
});
Http.interceptors.request.use(
    async (config :any) => {
      console.log(config);
      if (config?.data && process.env.REACT_APP_ENCRYPT_DATA === 'TRUE'&& !config?.url.includes('clients/appointmentNotes') &&
         config?.headers&& config?.headers['content-type'] != 'multipart/form-data') {
        const encryptedData = await formatPayload(
            JSON.stringify(config.data));
        config.data = encryptedData;
      }
      if (token) {
      } else {
        delete Http.defaults.headers.common.Authorization;
      }
      return config;
    },
    async (error) => Promise.reject(error),
);

Http.interceptors.response.use(
    (response) => {
      console.log(response, '456');
      const statusCode = response?.data?.status_code ?
        response?.data?.status_code : '';
      if (statusCode === 403 || (statusCode === 401 && (response?.data?.name &&
(response?.data?.name === 'invalid_token' ||
     response?.data?.name === 'unauthorized_request')))) {
        clearLocalStorage();
        sessionStorage.clear();

        window.location.reload();
      }

      return response;
    },
    (error) => {
      console.log(error.response, 456);

      if (error?.response?.data?.status_code === 401 && (error?.response?.data?.name === 'Unauthorized' || error?.response?.data?.name === 'invalid_token')) {
        clearLocalStorage();
        sessionStorage.clear();
        window.location.reload();
      }

      if ( error?.response?.status === 401 ) {
        clearLocalStorage();
        sessionStorage.clear();
        window.location.reload();
      } else if (error?.response?.status === 403) {
        if (typeof window !== 'undefined' && !(error?.request?.responseURL?.includes('office/clients/goals') ||
        error?.request?.responseURL?.includes('office/clients/recentActivities')) ) {
          window.location.href ='/permission-denied';
        }
      }
      return error;
    },
);
export default Http;
