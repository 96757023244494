/* eslint-disable max-len */
import forge from 'node-forge';
const publickey = process.env.REACT_APP_ENCRYPT_KEY || '';
const MAX_CHUNK_SIZE = 100;
const encryptPayload = (param: any) => {
  if (publickey && process.env.REACT_APP_ENCRYPT_DATA == 'TRUE') {
    const rsa = forge.pki.publicKeyFromPem(publickey);
    const payload = {param};
    const plaintext = JSON.stringify(payload);
    const utf8Bytes = utf8ToBytes(plaintext);
    const encryptedArray = [];
    // Encrypt each byte with RSA

    for (let i = 0; i < utf8Bytes.length; i += MAX_CHUNK_SIZE) {
    // const encryptedByte = rsa.encrypt(String.fromCharCode(utf8Bytes[i]), 'RSA-OAEP', {
    //   md: forge.md.sha256.create(),
    // });
      const chunk = utf8Bytes.subarray(i, i + MAX_CHUNK_SIZE);
      const encryptedChunk = rsa.encrypt(forge.util.binary.raw.encode(chunk), 'RSA-OAEP', {
        md: forge.md.sha256.create(),
      });

      // return forge.util.encode64(encrypted);
      // const encryptedByte = rsa.encrypt(String.fromCharCode(utf8Bytes[i]));
      const encodedByte = forge.util.encode64(encryptedChunk);
      encryptedArray.push(encodedByte);
    }

    return encryptedArray;
  } else {
    return param;
  } // Use a delimiter to concatenate chunks
};

const utf8ToBytes = (str: string | undefined) => {
  return new TextEncoder().encode(str);
};

const formatPayload = (param: string) => {
  const params = encryptPayload(param);
  return {payLoad: params};
};

const findChangedItems = (array1: any[], array2: any[]) => {
  const changedItems = array1.filter((item1, index) => {
    const item2 = array2[index];
    return item2 &&
           (item1.title !== item2.title || item1.description !== item2.description);
  });
  return changedItems;
};

export {encryptPayload, formatPayload, findChangedItems};
