/* eslint-disable max-len */
import styled from 'styled-components';
import style from 'styled-components';
import {
  primaryColor,
  commonTextColor,
  whiteColor,
  blue2,
  blue1,
} from './colors';
import {poppinsfont} from './font-styles';

export const MainContainer = style.div`
 

`;

export const LeftMenuContainer = style.div`
    display: flex;
    flex-direction: row;
    width: 30%;
    width: 300px;
    max-width: 300px;
    height: 100%;
    background-color: #F5F7F7;
`;
export const PermissionsBox = style.div`
  flex: 1;
  display: flex;
  flex-direction: column;

.permission-container{
    overflow-y: scroll;   
    max-width: 100vw;
    height: 100%;
    max-height: 100%;    
}
.disablePermission{
    padding: 16px 16px 0 16px;
    opacity: 0.7;
    pointer-events: none;

}

.permissions-box{
    margin-bottom: 24px !important;
    border: 1px solid #E6EDEF;
    border-radius: 8px;
}
.permission{
    background-color: transparent !important;
    box-sizing: border-box;
    border-radius: 8px;
    border-bottom: 1px solid transparent !important;
  
}



`;
export const LeftMenuHeader = style.div`
   

`;
export const FormContainer = style.div`
    margin: 0 auto;

&.form-box-container{
    background: #FFFFFF;
    border: 1px solid #E6EDEF;
    box-sizing: border-box;
    border-radius: 10px;
    width: 100%;
    max-width: 690px;
    margin: 0 auto;
    box-shadow: 0px 2px 30px rgba(123, 156, 207, 0.2);
}
&.withBg-container{
    position: relative;
    .backdrop {
        position: absolute;
        &.bg1{
            top: 0;
            left: 0;
            @media screen and (max-width: 991px){
                svg{
                    width: 500px;
                }
            }
        }
        &.bg2{
            top: 50%;
            transform: translateY(-50%);
            left: 0;
            @media screen and (max-width: 991px){
                width: 450px;
            }
        }
        &.bg3{
            bottom: 0;
            right: 0;
            @media screen and (max-width: 991px){
                svg{
                    width: 500px;
                }
            }
        }
        &.bg4{
            bottom: 0%;
            right: 0;
            @media screen and (max-width: 991px){
                width: 450px;
            }
        }
        img{
            width: 100%;
            height: 100%;
        }
    }
}
`;
export const ProfileHeader = style.div`
    border-bottom: 1px solid #EEF0F4;
    padding-bottom: 12px;
    margin-bottom: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;    
    h2{
        margin: 0;
        font-weight: 600;
        font-size: 22px;
        line-height: 30px;
        display: flex;
        align-items: center;
        color: ${commonTextColor};
        font-family: ${poppinsfont};
    }
    svg{
        margin-right: 16px;
        cursor: pointer;
    }
    .dropdownContainer{
        display: flex;
        flex-direction: column;
        justify-content: end;
        align-items: flex-end;
        .dropdown{
            position: absolute;
            top: 100%;
            left: 0;
            width: 300px;
            z-index: 2;
            border: 1px solid rgba(0, 0, 0, 0.04);
            box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14);
            ul {
                list-style: none;
                padding: 0;
                margin: 0;
              }
              li {
                padding: 8px 12px;
              }
              
              li:hover {
                background-color: rgba(0, 0, 0, 0.14);
                cursor: pointer;
              }
        }
    }
`;

export const Comingsoon = style.div`
width: 100%;
height: 100%;
display: flex;
align-items: center;
justify-content: center;
h2{
    color: ${primaryColor};
    font-weight: 600;
}


`;

export const SecondaryHeaderCon = style.div`
padding: 16px 116px;
height: 100%;
overflow: auto;
position: relative;
scrollbar-color: #999999 var(--white);
scrollbar-width: thin;
-ms-overflow-style: none;
@media screen and (max-width: 1200px){
    padding: 16px 116px;
}
@media screen and (max-width: 900px){
    padding: 16px 40px;
}
h5{
    margin: 0;
}
p{
    margin: 0;
}
&.healthPlanSection{
    background-color: #F5F7F7;
}
.back-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    cursor:pointer;
  }
`;
export const ClientName = style.div`
  cursor:pointer;
  display: flex;
  align-items: center;  
  .clientName{
    font-family:  ${poppinsfont};
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;   
    display: flex;
    align-items: center;
    text-transform: capitalize;
    color: ${commonTextColor};
  }

`;
export const ClientNamePic = style.div`
cursor: pointer;
.pictureProfile{
    margin-right: 8px;
    &.custom-width58{
        button{
            height: 36px;
            width: 36px;
            svg{
                height: 36px;
                width: 36px;
            }
        }
    }
    button{
        height: 36px;
        width: 36px;
        border: 1px solid #f3f1f1;
        svg{
            height: 36px;
        width: 36px;
        }
    }
}
.clientName{
    font-family:  ${poppinsfont};
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;   
    display: flex;
    align-items: center;
    color: ${commonTextColor};
    color: #1B2324 ;
  }
  .clientNameGroup{
    overflow: hidden;
    p{
        margin: 0;
        &:first-child{
          font-family: ${poppinsfont};
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 1.3;
          color: #1B2324;
        }
        font-family: ${poppinsfont};
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: #1B2324;    
    }
  }
  
`;
export const ClientID = style.span`
color: #1B2324;
cursor: pointer;
`;

export const ClientSummaryBlock = style.div`
    background: #FFFFFF;
    border-radius: 8px;
    min-height: 250px;
    padding: 16px 0;
.clientSummary{
    .summaryHeader{
        border-bottom: 1px solid #F5F7F7;
        padding: 12px 0;
        margin-bottom: 24px;
        h5{
            margin: 0;
            font-family: ${poppinsfont};
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 25px;
            align-items: center;
            color: #494F50;
        }
        .statusBadge{
            background: #FFFFFF;
            border: 1px solid #1596B7;
            box-sizing: border-box;
            border-radius: 4px;
            font-family: ${poppinsfont};
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 16px;isplay: flex;
            align-items: center;
            text-align: center;
            letter-spacing: 0.04em;
            text-transform: uppercase;
            color: #1596B7;
            padding: 4px;
        }
       
    }
    .summaryDetails{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }

    &:not(:last-child){
        padding: 0 16px 32px;
        border-bottom: 2px solid #E6EDEF;
        margin-bottom: 16px;
    }
        .linkBtn{
            display: inline-block;
            cursor: pointer;
            margin-bottom: 16px;
            .linkText{
                font-family: ${poppinsfont};
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                display: flex;
                align-items: center;
                color: ${blue1};
                text-decoration: none;
                span{
                    margin-left: 6px;
                }
            }
        }
}
`;

export const UserListContainer = style.div`
    display: flex;
    overflow: hidden;
    flex: 1;
    height: 100%; //added style
    `;

export const RightContainer = style.div`
  flex: 1;
  margin: 0 auto;
  position: relative;
  overflow-y: auto;
   .hVYqzy {
    /* position: absolute; */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
&.homePageContainer{
  max-width: 1140px;
  margin: 0 auto;
}
.heroBannerSubHeading h2 {
    margin-bottom: 32px;
    color: #111a1c;
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    display: flex;
    justify-content: space-between;
  }
  .reactTableSkel{
    
  }
  .sender_name{
    font-weight: 500;
    font-size: 17px;
    color: #262933;
  }
  .senders_msg{
    font-weight: 400;
    font-size: 15px;
    color: #8A8E9C;
  }
  .senders_msg p{
    white-space: nowrap;
    width: 400px;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 4px;
    margin-bottom: 0px;
  }
`;

export const SideDrawerHolder = style.div`
.css-1yf273i .side-drawer{
    height: 95%;
    top: 52px;
}
.agzCT .css-1yf273i .side-drawer{
    padding: 32px;
}
.errors-list{
  height: 100%;
  overflow: auto;
  padding-right: 16px;
}
`;
export const ButtonGroup = style.div`
//width: 100%;
 margin: 0 10px;
padding: 1rem;
    background: #ffffff;
    position: sticky;
    bottom: -16px;
&.hideFooter{
  transition-property: all;
  transition-duration: .5s;
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  max-height: 0;
  height: 0;  
  opacity: 0;
  justify-content: flex-end;
    display: none;
}
&.showFooter{
  overflow-y: hidden;
  transition-property: all;
  transition-duration: .5s;
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  align-items: center;
  vertical-align: top;
  transition: opacity 0.3s;
  -webkit-transition: opacity 0.3s;
  opacity: 1;
  justify-content: flex-end;
    display: flex;
}
`;
export const SecondaryHeaderContainer = style.div`
    max-width: 100%;
    .css-15pswuj{
        width: 30%;
        padding: 0px 14px;
    }
    .css-283000 {
        width: 80%;
    }
    display:flex;
        .pageHeader{
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            color: #0D0D0D;
        }
`;
export const GoBackSection = style.div`
        span{
            gap: 8px;
            cursor: pointer;
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            -webkit-align-items: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: #0D0D0D;
        }
`;
export const PaymentDetails = style.div`
    width: 100%;
    display: flex;
    gap: 24px;
    .noPayments{
        font-weight: 400;
        font-size: 16px;
        color: #484848;
    }
    .paymentHeader{
        color: #717171;
        font-weight: 600;
        font-family: ${poppinsfont};
        font-size: 14px;
    }
    .paymentCon{
        color: #1B2324;
        font-weight: 600;
        font-family: ${poppinsfont};
        font-size: 32px;
    }
    .subscriptionHover{
        display: flex;
        gap: 40px;
        align-items: flex-start;
        &:hover{
            .subscriptionShow{
                display: flex;
                    
            }
        }
    }
    .subscriptionShow{
        display: none;
        border-radius: 14px;
        background: #FFFFFF;
        box-shadow: 0px 2px 20px rgb(0 0 0 / 11%);
    }
    .hoverDiv{
        border-right: 1px solid #E6EDEF;
        padding: 14px;
    }
    &.hideDetails{
        transition-property: all;
        transition-duration: .5s;
        transition-timing-function: cubic-bezier(0,1,0.5,1);
        height: 0;
        opacity: 0;
        width: 100%;
        display: flex;        
      }
      &.showDetails{
        transition-property: all;
        transition-duration: .5s;
        transition-timing-function: cubic-bezier(0,1,0.5,1);
        overflow-y: hidden;
        height: 110px;
        opacity: 1;
      }
`;
export const GridStatus = style.div`
font-size: 16px;
 color: #1B2324;
 font-size: 16px;
line-height: 24px;
color: #1B2324;
font-family: ${poppinsfont};
display: flex;
align-items: center;
font-weight: 600;
.active{
  color: ${primaryColor}
}
.inactive{
  color: #95A1A2
}
svg{
  margin-right: 10px;
}
`;
export const RightContent = style.div`
display: flex;
align-items: center;
justify-content: space-between;
flex:1;
padding-bottom: 12px;
.svgPadding{
    svg{
        margin-bottom: 6px;
    }
    
}
p{
font-family: ${poppinsfont};
font-weight: 500;
font-size: 24px;
line-height: 36px;
color: #1B2324;
margin: 0;
}

&.myScheduleCustomCSS{
    @media (max-width: 1150px){
        flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    gap: 16px;
    }
}
&.paymentCustomCSS{
    flex: unset !important;
}
.css-1mxwzzc input{
    margin: 0;
}

.searchBlock{
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media only screen and (max-width: 900px){
        width: 100%;
    }
}

.searchTabsBlock{
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    @media only screen and (max-width: 900px){
    }
}

.clearall{
    font-weight: 400;
    font-size: 12px;
    color: ${blue1};
}
.completed{
    color: #1B2324;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
}
.sub_filters{
    width: 424px;
    height: 40px;
    background: #EDEDED;
}
.searchSkel{
    width: 272px;
    height: 40px;
    background: #EDEDED;
}
.licenseState div button{
    padding-left: 4px;
    font-weight: 600;
    font-size: 16px;
    color: #355F92 !important;
}
`;
export const SecondaryBtn = style.div`
`;

export const ProfileInner = style.div`
    width: 100%;
`;
export const DeleteWrap = style.div`
 position: absolute;
 bottom: 0;
 right: 0;
 background: linear-gradient(360deg, 
    rgba(0, 0, 0, 0.5) 13.1%, rgba(0, 0, 0, 0) 100%);
 border-radius: 4px;
 z-index: 999999;
 width: 28px;
 height: 28px;
 display: flex;
 align-items: center;
 justify-content: center;
 cursor: pointer;
 width: 100%;
 height: 40px;
 display: none;
 transition: opacity 0.3s;
 -webkit-transition: opacity 0.3s;
 opacity: 0;
span{
margin: 0 8px;
}
`;
export const SpinnerOverlay = style.div`
pointer-events: none;
position: absolute;
inset: 0px;
display: flex;
-webkit-box-align: center;
align-items: center;
-webkit-box-pack: center;
justify-content: center;
z-index: 100;
background: #fff;
`;

export const SupplementLink = style.div`
  text-decoration: none;
  color: #355F92;
`;

export const OverviewContainer = style.div`
   display: flex;
   flex-direction: row;
   width: 100%;
   height: 100%;
   @media screen and (max-width: 900px){
    flex-direction: column;
   }

`;
export const OverviewRightContainer = style.div`
   min-width: 280px;
   max-width: 280px;
   height: 100%;
   padding-left: 32px;
   @media screen and (max-width: 900px){
    min-width: 100%;
    max-width: 100%;
    padding-left: 0;
   }
   .msgs_header{
    font-weight: 700;
    font-size: 24px;
    line-height: 33px;
    color: #1B2324;
   }
    .RightColumnHeader{
        display: flex;
        align-items: center;
            h3{
                margin: 0;
                font-size: 20px;
            }
            .ToDoCount{
                background-color: #EEF0F4;
                border: none;
                padding: 6px 16px;
                text-align: center;
                text-decoration: none;
                display: inline-block;
                margin: 4px 2px;
                cursor: pointer;
                border-radius: 20px;
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                color: #1B2324;
                margin-left: 22px;
            }
    }
    .RightColumnCardsHolder{
        @media screen and (max-width: 900px){
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            gap: 0 8px;
           }
    }
    .ToDoSubTitle {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 1.5;
        letter-spacing: 0.01em;
        color: #666666;
        flex: none;
        order: 1;
        flex-grow: 0;
        margin: 16px 0;
    }
`;

export const OverviewleftContainer = style.div`
   flex: 1;
   padding-right: 32px;
   @media screen and (max-width: 900px){
    padding-right: 0px;
   }
`;

export const GwOverviewCard = style.div`

margin-bottom: 48px;
&:last-child{
    margin-bottom: 20px;
}
.GwCardHeader{
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    h3{
        margin: 0px;
        font-size: 20px;
        margin-right: 24px;
    }
}
.clientName{
    font-weight: 400;
    font-size: 14px;
  }
.GwCardBody{
    background: #FFFFFF;
    border: 1px dashed #E0E0E0;
    border-radius: 10px;
    min-height: 160px;
    margin: 24px 0;
    display: flex;
    align-items: center;
    justify-content: center;
        .GwCardBodyContentHolder {
            padding: 24px;
            text-align: center;
            .GwCardContent{
                span{
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 160%;
                    text-align: center;
                    color: #333333;
                    display: block;
                    &:last-child{
                      font-weight: 400 !important;
                      color: #BDBDBD;
                    }
                }
            }
        }
}
`;
export const MessageDiv = style.div`
box-shadow: rgba(37, 42, 49, 0.16) 0px 0px 2px, rgba(37, 42, 49, 0.12) 0px 1px 4px;
padding: 24px;
height: 220px;
overflow: auto;
`;
export const ToDoCard = style.div`
    background: #FFFFFF;
    box-shadow: 0px 0px 2px rgb(37 42 49 / 16%), 0px 1px 4px rgb(37 42 49 /12%);
    border-radius: 3px;
    min-width: 288px;
    min-height: 164px;
    margin: 20px 0;
    padding: 24px;
    display: flex;
    border: 1px solid rgb(37 42 49 / 12%);
        &:last-child {
            margin-bottom:  0;
            @media screen and (max-width: 900px){
                margin-bottom:  20px;
            }
        }
        @media screen and (max-width: 900px){
            width: 24%;
                }
    .ToDoCardContent{
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        .ToDoCardInnerContent{
            display: flex;
            flex-direction: column;
            .ToDoCardTitle{
                font-weight: 400;
                font-size: 16px;
                line-height: 1.5;
                color: #1B2324;
                display: block;
            }
            .ToDoCardTimeStamp{
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 1.5;
                color: ${blue1};
            }
            .todoMessageCount{
                font-weight: 400;
                font-size: 16px;
                line-height: 22px;
                color: #252A31;
            }
        }
    }
    .draftContent{
        border: 1px solid #F2F2F2;
    padding: 10px 10px 10px 12px;
    border-radius: 4px;
    margin: 8px 0px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    }
    .draftMain{
        font-size: 15px;
    font-weight: 500;
    line-height: 24px;
    color: #000000;
    }
    .draftSecondary{
        font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: #717171;
    }
    .noMsgDiv{
        font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: #717171;
    margin-top: 8px;
    }
`;
export const GwDocumentContainer = style.div`
    width: 100%;
    height: 100%;
`;
export const GWBreadCrum = style.div`
display: flex;
align-items: center;
margin-bottom: 32px;
`;
export const GWBreadCrumItem = style.div`
display: flex;
align-items: center;
span{
    font-weight: 500;
          line-height: 1.5;
          color: ${blue2};
          text-decoration: underline;
          cursor: pointer;
}
&:not(:last-child)::after {
    /* width: 8px; */
    padding: 0 10px;
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    content: ">";
    color: #697D95;
    text-align: center;
    font-size: 22px;
    line-height: 1.5;
  }
  &:last-child{
      span{
        font-weight: 500;
        line-height: 1.5;
        color: #68797a;
        text-decoration: none;
        cursor: unset !important;
    }
    &:first-child{
        span{
          font-weight: 500;
          line-height: 1.5;
          color: ${blue2};
          text-decoration: underline;
      }
  }
`;
export const GwDocumentInnerContainer = style.div`
    display: flex;
    margin: 24px 0;
    .leftDocumentContainer{
        width: 50%;
        padding-right: 16px;
    }
    .rightDocumentContainer{
        width: 50%;
        padding-left:16px;
    }
`;

export const GwDocumentsCardHolder = style.div`

    h5{
        margin: 0;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 160%;
        letter-spacing: 0.01em;
        color: #1B2324;
    }
`;
export const GwDocumentCard = style.div`
    background: #FFFFFF;
    box-shadow: 0px 0px 2px rgb(37 42 49 / 16%), 0px 1px 4px rgb(37 42 49 /12%);
    border-radius: 3px;
    width: 100%;    
    min-height: 72px;
    padding: 16px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    margin:20px 0;
    border: 1px solid transparent;
    &::first-child{
        margin-top: 20px;
    }
    &:active {
            -webkit-transform: scale(0.99);
    -moz-transform: scale(0.99);
    -ms-transform: scale(0.99);
    transform: scale(0.99);
        -webkit-transition-duration: 0s,0s;
        transition-duration: 0s,0s;
    }
    &:hover {
        border: 1px solid ${blue2};
    }
    .DocumentTitle {
        display: flex;
        align-items: center;
        gap: 10px;
        flex: 1;
        flex-direction:row;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #1B2324;
        span{
            font-weight: 400;
            font-size: 12px;
            color: #68787A;
        }
    }
    .FindAMatchTitleBlock{
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex: 1;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #1B2324; 
        .FindAMatchTypeResult{
            color: ${blue1};
            display: flex;
            align-items: center;
            gap: 8px;
        }
    }

    &.disableDoc{
        filter: grayscale(1);
       cursor: not-allowed;
       background: #a0a0a01c;
    }
    &.displayNone{
        display: none;
    }
    &.disablePhy{
        opacity: 0.5;
    }
`;

export const ExitButton = style.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    display: inline-flex;
    align-items: center;
    padding: 8px 16px;    
    background: #F5F7F7;
    border-radius: 17px;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.003em;
    color: #1B2324;
    font-weight: 500;
    cursor: pointer;-webkit-transition: background 0.15s ease-out!important;
    transition: background 0.15s ease-out!important;
    &:hover{
        background: ${blue1};
        color: #F5F7F7;
    }
`;
export const OnboardContainer = style.div`
    width: 100%;
    height: 100%;
    display: flex;
    overflow: hidden;
`;
export const OnboardLeftColumn = style.div`
    width: 100%;
    max-width: 40%;
    height: 100%;
    img{
        width: 100%;
        object-fit: cover;
        height: 100%;
    }
`;
export const OnboardrRightColumn = style.div`
    flex: 1;
    padding: 60px;
    overflow: auto;
    
    .RightContentHolder{
        .logoHolder{
            position: relative;
            margin-bottom: 80px;
            display: flex;
            align-items: center;
            justify-content: center;
            .top50{
                position: absolute;
                right: 0px;
                top: 50%;
                transform: translateY(-50%);
            }
        }
        .OnboardWrapper{
            display: flex;
            flex-direction: column;
            align-items: center;
            max-width: 700px;
            margin: 0 auto;
                h2{
                    margin: 0;
                    margin-bottom: 46px;
                    text-align: center;
                }
        }
    }
`;

export const OnboardCard = style.div`
    min-width: 327px;
    min-height: 303px;
    background: #FFFFFF;
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.11);
    border-radius: 16px;
    padding: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .CardBadge{
        padding: 8px 16px;
        background: #F0F0F0;
        border-radius: 32px;
        font-weight: 500;
        font-size: 16px;
        line-height: 16px;        
        color: ${blue2};
    }
    .AvatarHolder{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 68px;
        height: 68px;
        margin: 20px 0;
        span{
            width: 100%;
            height: 100%;
            max-height: 68px;
            &[class$="-AvatarImage"]{
                svg{
                    width: 68px;
                    height: 68px;
                }
            }
        }
    }
    .CardContent {
        text-align: center;
        p{
            font-style: normal;
            font-weight: 500;
            font-size: 18px;    
            line-height: 26px; 
            color: #717171;
            margin: 0;
            margin-bottom: 8px;
            &:first-child{
                color: #1B2324;
                font-size: 18px;    
                line-height: 26px;  
                margin-bottom: 18px;              
            }
        }
    }
`;

export const PhasesOfMainContainer = style.div`
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
`;
export const PhasesOfCareContainer = style.div`
    
    overflow-y: auto;
    height: 100%;
    margin-bottom: 84px;
    .PhasesOfCareContentWrapp{
        max-width: 1040px;
        width: 100%;
        margin: 0 auto;
        padding: 0;
        padding-top: 8px;
        .error{
            color:#e24444!important
        }
        }
    .buttonWrap{
        display: flex;
        justify-content: space-between;
        margin: 16px 0px 0px 42px;
    }
`;
export const GWCStepper = style.div`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 16px 0;
    gap: 10px;
`;
export const GWCStepperItem = style.div`
    list-style-type: none;
    display: inline-block;
    min-width: 128px;    
    cursor: pointer;
    overflow: hidden;
`;
export const GWCStepperTitle = style.div`
    text-align: center;
    color : #B0B0B0;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    padding-bottom: 12px;
    border-bottom: 3px solid #B0B0B0;
    position: relative;
    box-sizing: content-box;
    &::after {
        content: "";
        height: 3px;
        width: 100%;
        transition: all 0.3s;
        background: ${blue2};
        position: absolute;
        bottom: -3px;
        left: 0;
        transform: translateX(-100%);
    }
    &:hover{
        color: ${blue2};
    }
    &.active {
        color:  ${blue2};
        font-weight: 500;
        &::after {
            transform: translateX(0);
        }
    }
    &.completed {
        color:  ${blue2};
        font-weight: 500;
        &::after {
            transform: translateX(0);
        }
    }
`;

export const GWCStepperContent = style.div`
    background: #FFFFFF;
    box-shadow: 0px 0px 2px rgba(37, 42, 49, 0.16),0px 1px 4px rgba(37, 42, 49, 0.12);
    border-radius: 8px;
    max-width: 100%;
    min-height: 250px;
    margin: 32px 0;
    padding: 24px;
    animation: tab-content-anim 0.75s linear;
    // position: relative;
    @keyframes tab-content-anim{
        0% {
            
            transform: translateX(-100%);
          }
           100% { 
          
            transform: translateX(0%);
           }
    }
    .StepperContentHeader{
        margin: 32px 0;
        display: flex;
        align-items: center;
        margin-top: 0;
        h4{
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 140%;
            color: #1B2324;
            margin: 0;
            margin-left: 8px;
        }
    }
    .delete-icon{
        cursor:pointer;
    }

    .popup-body{
      height: 100%;
    }
    .popup-body tbody{
        height: 100%;
      }
    .no-data{
        min-height:200px;
        display:flex;
        align-items: center;
        justify-content: center;
        color:#717171;
        font-size: 16px;
    }
    .react-select__control{        
    }
    .health-plan-grid-input{
        input{
            margin: 0;
        }
    }
    .modal-main{
        transform: unset !important;
        position: unset !important;
        max-width: 95%;
    }
    
    .display-block{
        display: flex;
        position: absolute;
        top: 25%;
        left: 0px;
        justify-content: center;
        align-items: start;
        width: 100%
    }
`;
export const StepperContentBody = style.div`
`;
export const PreviewTabContainer = style.div`
    max-width: 100%;
    min-height: 250px;
    margin: 0;
    padding: 24px;
    animation: tab-content-anim 0.75s linear;
    position: relative;
    @keyframes tab-content-anim{
        0% {
            
            transform: translateX(-100%);
          }
           100% { 
          
            transform: translateX(0%);
           }
    }
    .preview-header{
        display:flex;
        align-items:center;
        flex-direction: row;
        padding: 10px;
        h2{
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 20px;
            color: #1B2324;
            margin-left: 13px;
        }

    }
    .accordion__panel{
        color: #717171;
        padding: 16px 27px 16px 37px !important;
        textarea{
            color: #717171 !important;
        }
    }
    .accordion{
        box-shadow: 0px 0px 2px rgba(37, 42, 49, 0.16), 0px 1px 4px rgba(37, 42, 49, 0.12);
        border-radius: 8px !important;
        .accordion__button, .accordion__panel, .accordion__button[aria-expanded="true"]:hover{
            background-color:transparent !important;
        }
    }
`;
export const FooterAction = style.div`
    background: #FFFFFF;    
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.14), 0px -2px 12px 2px rgba(0, 0, 0, 0.08);
    min-height: 84px;
    width: 100%;    
    position: fixed;
    bottom: 0;
    left:0;
    .FooterActionWidth{
        max-width: 1040px;
        width: 100%;
        margin: 0 auto;
        padding: 0 0;
    }
    .BackBlock{
        cursor: pointer;
    }
`;

export const PhaseCard = style.div`
    border-bottom: 1px solid #E0E0E0;
    margin-bottom: 32px;
    &:last-child{
        margin-bottom: 24px;
    }
    .SelectBlock{        
        margin: 0 16px;
        width:150px;
        .react-select__control{
            min-height:35px !important;
        }
    }
    .phasesOfCareTitleBox{
        min-width:380px;
        .react-select__control{
            background:#fff;
            min-height:35px !important;
            border:none !important;
            margin:0px !important;
            .react-select__indicators{
                display:none !important;
            }
            .react-select__placeholder{
                color:${blue1};
                font-weight: 500;
                font-size: 18px;
                line-height: 160%;
            }
            .react-select__input{
                color:${blue2};
            }
            .react-select__single-value{
                color:${blue2};
                font-style: normal;
                font-weight: 500;
                font-size: 18px;
                line-height: 160%;
            }
        }
    }
    .PhaseCardHeader{
        h5{
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 160%;
            color: #1B2324;
            margin: 0;
        }
    }
    .PhaseCardBody{        
        p{
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 160%;
            color: #717171;
            margin: 0;
        }
        .description{
            color: #717171;
            margin-bottom:24px;
        }
        
    }
    .editabel-text{
        padding: 6px 5px 0px 5px;
        color: #717171;
        width:100%;
        border: 1px solid transparent !important;
        font-family:  ${poppinsfont};
        font-size:16px;
        &:focus {
            border: 1px solid #0A0D0D !important;
        }
        &:focus-visible {
            border: 1px solid transparent !important;
            outline: none;
        }

    }
    .editabel-text:focus{
        border: 1px solid #0A0D0D !important;
    }
    .before-edit{
        padding: 4px;
        min-height:25px;
    }
    .after-edit{
        .editable-text{
            padding: 4px;
            min-height:60px;
            transition: visibility 0s, opacity 0.5s linear;
        }
    }
`;
export const HealthProfileContainer = style.div`
   display: flex;
   flex-direction: row;
   width: 100%;
`;
export const HealthProfileLeftContainer = style.div`
    min-width: 280px;
   max-width: 280px;
   width: 30%;
   height: 100%;
   padding-right: 32px;

   .HealthProfileMenu{
        display: flex;
        flex-direction: column;
        align-items: flex-start;

       .HealthProfileMenuItem{

        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 1.5;
        color: #1B2324;
        margin-bottom: 16px;
        display: inline-block;
        cursor: pointer;
        display: flex;
        align-items: center;
        svg{
            margin-right: 12px;
            width: 16px;
            height: 16px;
        } 
        &.active{
            color:  ${blue1};
            svg{
                path{
                    fill: ${blue1};
                }
            } 
        }       
       }
   }
`;
export const HealthProfileRightContainerBorder = style.div`
    min-height: 250px;
    background: #FFFFFF;
    box-shadow: 0px 0px 2px rgba(37, 42, 49, 0.16), 0px 1px 4px rgba(37, 42, 49, 0.12);
        border-radius: 8px;
    padding: 40px;
    position: relative;
    border: 1px solid rgba(37, 42, 49, 0.12);
`;
export const HealthProfileRightContainer = style.div`
    flex: 1;
    padding-left: 0;
    width: 100%;
    min-height: 250px;
        .HealthProfileContentTitle{
            display: flex;
            align-items: center;
            margin-bottom: 24px;
            h3{
                font-style: normal;
                font-weight: 500;
                font-size: 24px;
                line-height: 20px;
                color: #1B2324;
                margin: 0;
                margin-left: 8px;
            }
        }
        .HealthProfileContentEditor{
            .HealthProfileTitle{
                font-style: normal;
                font-weight: 500;
                font-size: 20px;
                line-height: 160%;
                color: #1B2324;
                margin-bottom: 20px;
            }
            .ql-editor{
                min-height:200px;
                max-height:250px;
            }
        }
`;
export const HealthProfileEditorContainer = style.div`
        margin: 32px 0;
        .note-text{
            font-weight: 400;
            font-size: 14px;
            color: #B0B0B0;
        }
`;
export const NoSuggestionsCardTitle = style.div`
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 24px;
color: #1B2324;
label{
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #1B2324;
}
margin: 0;
margin-bottom: 20px;
display:flex;
justify-content: space-between;
align-items:center;
.actions{
    display:flex;
    align-items:center;
    gap:20px;
    .actions--icons{
        display: flex;
        align-items: center;
        cursor:pointer;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: ${blue2};
        display:flex;
        algine-items: center;
        gap: 4px;
        &:hover{
            text-decoration: underline;
        }
    }
}
&.ColorCara{
    color: #E09B07;
}
`;
export const GwcNoSuggestionsCard = style.div`
margin-top: 32px;
 &:first-child{
     margin-top: 0;
 }
 &.no_focus textarea{
    :focus{
        border: 1px solid #E0E0E0 !important;
    }
    :focus-visible{
        border: 1px solid #E0E0E0 !important;
    }
 }
.NoSuggestionsCardTitle{
    h5{
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #1B2324;
        margin: 0;
        margin-bottom: 20px;
    }
}
.NoSuggestionsCardBody{
    min-height: 100px;
    background: #FFFFFF;
    border: 1px dashed #E0E0E0;
    border-width: 2px;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2px;
        div,p{
            margin: 0;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 160%;
            color: #333333; 
            &:last-child{
                font-weight: 400;
                color: #b5b5b5;
            }
            span.actions-cons {
                display: flex;
                align-items: center;
                gap: 4px;
                color: ${blue1};
                margin: 4px 0px;
                cursor: pointer;
                &:hover{
                    text-decoration: underline;
                }
                svg{
                    width: 24px;
                    height: 24px;
                }
            }
        }
    }
   .text-limit{
        display:flex;
        align-items:center;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        color: #BDBDBD;
        justify-content: flex-end;
   } 
`;

export const HealthPlanNutritionSelect = style.div`
    background: #FFFFFF;
    border: 1px solid #E6EDEF;
    border-radius: 6px;
    padding: 16px;
    margin-top:16px;
    

    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content:flex-start;
   .item-checkbox{
        cursor:pointer;
   }
   .gw_link{
    color: #355F92 !important;
    cursor: pointer;
   }
    .content{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content:flex-start;
        flex: 1;
        margin-top: 4px;

        .headerInfo{
            display: flex;
            width: 100%;

            .extraInfo{
                display: flex;
                align-items: center;
                justify-content: space-between;
                flex: 1;
                padding: 0 16px;
                span{
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 21px;
                    display: flex;
                    align-items: center;
                    color: #717171;
                }
            }
        }
        .footerInfo{
                display: flex;
                gap: 8px;
            span{
                font-weight: 400;
                font-size: 12px;
                line-height: 18px;
                display: flex;
                align-items: center;
                color: #484848;
            }
            button{
                span{
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 18px;
                    color: ${blue1};
                }
            }
        }
    }
    .name{
        padding:0px;
        margin:0px;
        font-weight: 500;
        font-size: 16px;
        line-height: 160%;
        color: #252A31;
    }
    .description{
        padding:0px;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #717171;
        margin: 4px 0;
    }
`;

export const GridEditDetails = style.div`        
    .content{
        display:flex;
        flex-direction: column;
        .headerInfo{
            .extraInfo{
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                flex-wrap: wrap;
                gap: 8px;
                span{
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 21px;
                    display: flex;
                    align-items: center;
                    color: #717171;
                }
                .type{
                    color: #494F50;
                }
                .price{
                    font-style: normal;
                    font-weight: 600;
                    font-size: 16px;
                    color: #494F50;
                }
            }
            .price{
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                color: #494F50;
            }
            
        }
        .footerInfo{
                display: flex;
                gap: 16px;
            span{
                font-weight: 400;
                font-size: 12px;
                line-height: 18px;
                display: flex;
                align-items: center;
                color: #484848;
            }
            button{
                span{
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 18px;
                    color: ${blue1};
                }
            }
        }
    }
`;

export const CustomGWDataTable = style.div`
`;
export const GWDataTableRow = style.div`
    display: flex;
    gap: 12px;
    margin: 16px 0;
    .statusSelectbox{
        width: 150px;
        .react-select__single-value > .custom-option{
            color:${blue2} !important;
        }
    }
`;
export const GWDataTableCell = style.div`
    background: #F8FAFC;
    border: 1px solid #F8FAFC;
    border-radius: 3px;
    min-height: 48px;
    padding: 0 16px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #333333;
    &.disabled{
        background: #E8E8E8;
      }
    &.BGTrans{
        background: #FFFFFF;
        border: none;
        padding: 0;
    }
    span{
        display: flex;
        margin-right: 24px;
        &:last-child{
            margin-right: 0;
        }
        &.pointer{
            cursor: pointer;
        }
    }
    &.activeStatus{
        min-width: 110px;
        weight: 400;
        size: 14px;
        line height: 20px;
    }
`;
export const ModalTitle = style.div`
    font-size: 16px;
    font-weight: 500;    
    position: relative;
    flex: 1;
    display: flex;
    justify-content: space-between;
    .closeBlock{
        cursor: pointer;
    }
}
`;
export const ModalBodyContainer = style.div`
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
        display: flex;
        align-items: center;
        color: #1B2324;
`;
export const ModalFooterContainer = style.div`
`;
export const NotesWidgetButton = style.div`
    position: fixed;
    bottom:100px;
    right:50px;
    cursor:pointer;
    z-index:100;
`;
export const GWChipInput = style.div`
    display: flex;
    flex-wrap: wrap;
    gap: 6px;
    padding: 16px 0;
    padding-top: 0;
    border-bottom: 1px solid #E0E0E0;
`;
export const FilterChipConatiner = style.div`
display: flex;
flex-wrap: wrap;
gap: 6px;
padding: 4px;
background: #FFFFFF;
border: 0.5px solid #BBC7CA;
border-radius: 30px;
`;
export const FilterChip = style.div`
border: 1px solid #E6EDEF;
border-radius: 20px;
background: #FFFFFF;
padding: 4px 16px;
color: #1E3653;
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 21px;
cursor: pointer;
&.active{
    background: #E9DCCE;
}
&.in_active{
    background: #FFFFFF;
}
`;
export const GWChipFormField = style.div`
.main_filters{
    width: 348px;
    height: 31px;
    background: #EDEDED;
}
`;
export const GWChip = style.div`
    color: ${blue2};
    font-size: 85%;
    overflow: hidden;
    padding: 4px 12px;
    text-overflow: ellipsis;
    white-space: nowrap;
    box-sizing: border-box;
    background-color:  rgba(122, 150, 182, 0.2);
    border-radius: 18px;
    margin: 2px;
    min-width: 0;
    box-sizing: border-box;
    box-shadow: none;
    max-width: 100%;
    display: inline-flex;
    font-weight: 500;
`;

export const TodoChip = style.div`
&.inactiveChip{
    color: ${blue1};
    font-size: 15px;
    overflow: hidden;
    padding: 8px 16px;
    text-overflow: ellipsis;
    white-space: nowrap;
    box-sizing: border-box;
    background-color: #EEF0F4;
    border-radius: 32px;
    margin: 2px;
    min-width: 0;
    box-sizing: border-box;
    box-shadow: none;
    max-width: 100%;
    display: inline-flex;
    font-weight: 500;
    cursor: pointer;
}

&.activeChip{
    color: #FFFFFF;
    font-size: 15px;
    overflow: hidden;
    padding: 8px 16px;
    text-overflow: ellipsis;
    white-space: nowrap;
    box-sizing: border-box;
    background-color: ${blue1};
    border-radius: 32px;
    margin: 2px;
    min-width: 0;
    box-sizing: border-box;
    box-shadow: none;
    max-width: 100%;
    display: inline-flex;
    font-weight: 500;
    cursor: pointer;
}
    
`;

export const GoToBackBlock = style.div`
    display: flex;
    align-items: center;
    // cursor: pointer;
    gap: 10px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #0D0D0D;
    margin: 16px 0;
    .back-section{
        // flex:1;
        align-items: center;
        display: flex;
        gap: 12px;

    }
    .actions{
        display:flex;
        align-items:center;
        gap:20px;
        .actions--icons{
            cursor:pointer;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            color: ${blue2};
            display:flex;
            algine-items: center;
            gap: 8px;
            &:hover{
                text-decoration: underline;
            }
        }
    }
`;
export const HealthPlanConatiner = style.div`
    background: #FFFFFF;
    border: 1px solid #EEF0F4;
    border-radius: 20px;
    padding : 48px;

    .HealthPlanHeader{
        display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 24px;
        h4{
            font-weight: 600;
            font-size: 24px;
            line-height: 36px;
            text-align: center;
            color: #1B2324;
            margin: 0;
        }
    }
    .HealthPlanHeaderGrid{
        display: flex;
        justify-content: space-evenly;
        gap: 16px;
        margin-bottom: 32px;
        .HealthPlanHeaderGridColumn{
                display:  flex;
                flex-direction: column;
                gap: 4px;
            .HealthPlanHeaderGridColumnHeading{
                font-weight: 400;
                font-size: 14px;
                line-height: 21px;
                color: #717171;
            }
            .HealthPlanHeaderGridColumnAnswer{
                .HealthPlanHeaderHolder{
                    display: flex;
                    align-items: center;
                    //background: #FAFAFA;
                    border-radius: 4px;
                    padding-top: 2px;
                    gap: 8px;
                }              
            }
        }
    }
`;

export const AppointmnetConfirmation = style.div`
    background: #FFFFFF;
    box-shadow: 0px 0px 2px rgba(37, 42, 49, 0.16), 0px 1px 4px rgba(37, 42, 49, 0.12);
    border-radius: 20px;
    min-height:194px;
    padding:32px;
    margin-bottom: 25px;
    .title{
        display:flex;
        flex-direction:row;
        align-items:center;
        margin:0px 0px 25px 0px;
        h3{
            font-weight: 400;
            font-size: 20px;
            line-height: 160%;
            padding:0px;
            margin: 0px;
            display:flex;
            flex:1;
        }
        span{
            display:flex;
        }
    }
    .containter{
        display:flex;
        gap:24px;
    }
    .conformation-box{
        display:flex;
        flex-direaction:row;
        background: #FFFFFF;
        box-shadow: 0px 0px 2px rgba(37, 42, 49, 0.16), 0px 1px 4px rgba(37, 42, 49, 0.12);
        border-radius: 6px;
        color: ${blue1};
        width: 270px;
        height: 70px;
        padding:24px;
        cursor:pointer;
        align-items:center;
        transition: background 0.15s ease-out 0s ;
        &:hover{
            background: ${blue1};
            color: #ffffff;
        }
        &:active{
            transform: scale(0.99);
            color: #ffffff;
        }
    }
    .messageHeader{
        color: #000000;
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 160%;
    }
    &.message{
        animation: scale 0.3s;
    }
    @keyframes scale{
        0%{
            transform: scale(0);
        }
        100%{
            transform: scale(1);
        }
    }
`;

export const RolesMain = style.div`
width: 100%;
display:flex;

.unselectedPills{
  font-weight: 500;
  font-size: 20px;
  color: #717171;
  cursor: pointer;
}
.selectedPills{
    font-weight: 500;
    font-size: 20px;
    color: #1B2324;
    cursor: pointer;
  }
`;

export const MatchCareTeamWrap = style.div`
  height: 100%;
  width: 100%;
  position: relative;
  background-color: #FAFAFC;
  display: flex;
    justify-content: center;
    padding-top: 40px;
    overflow: auto;
`;

export const MatchCareTeamBlock = style.div`
    background: #FFFFFF;
    border: 1px solid #EEF0F4;
    border-radius: 20px;
    min-width: 640px;
    min-height: 400px;
    padding: 32px;
`;
export const MatchCareTeamInnerContainer = style.div`
  padding: 32px 40px;
  padding-top: 18px;
  .MatchCareTeamHeading{
    font-weight: 500;
    font-size: 24px;
    line-height: 140%;   
    color: #111A1C;
    margin-bottom: 16px;
  }
  .MatchCareTeamSubHading{
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;    
    color: #111A1C;
    max-width: 400px;
  }
  .program{
    color: ${blue2};;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    background: rgba(122, 150, 182, 0.2);
    border-radius: 20px;
    padding: 2px 12px;
  }

`;

export const GoToTop = style.div`
    position: fixed;
    bottom:50px;
    right:50px;
    cursor:pointer;
    z-index:100;
`;

export const TestresultsDiv = style.div`
font-weight: 500;
font-size: 16px;
font-family: 'Poppins';
font-style: normal;
color: #1B2324;
`;

export const HealthProfileOrdersContainer = style.div``;

export const HealthProfileOrdersTab = style.div`
    display: flex;
    gap: 24px;
    margin-bottom: 24px;
    .HealthProfileOrdersMenu{
        cursor: pointer;
        font-weight: 600;
        font-size: 20px;
        line-height: 160%;
        letter-spacing: 0.01em;
        color: #B0B0B0;
        &.active{
            color:  #1B2324;
        }
    }
`;

export const SecondaryHeaderClientContainer = style.div`

`;
export const SecondaryHeaderClientTitle = style.div`
    font-weight: 500;
    font-size: 32px;
    line-height: 160%;
    color: #1B2324;
`;
export const SecondaryHeaderClientSubText = style.div`
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;
    color: #484848;
`;
export const SecondaryHeaderClientCountBlock = style.div`
    display: flex;
    gap: 32px;
`;
export const SecondaryHeaderClientCount = style.div`
    min-width : 110px;
`;
export const SecondaryHeaderClientCountTitle = style.div`
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 160%;
    color: #717171;
`;
export const SecondaryHeaderClientCountDigi = style.div`
    font-weight: 600;
    font-size: 32px;
    line-height: 160%;
    color: #1B2324;
`;
export const GwTabContainer = style.div`
display: flex;
margin: 0px;
padding: 0px;
position: relative;
font-weight: 500;

        ::before {
            content: "";
            border-radius: 2px;
            bottom: 0px;
            margin: 0px;
            position: absolute;
            width: inherit;
            left: 0px;
            right: 8px;
            background-color: rgb(235, 235, 235);
            height: 1px;
        }
    &.tabCustomStyle{
        overflow: hidden; 
        position: relative;  
        display: flex; 
        flex-direction: column;
        height: 100%;
    }
`;
export const GWTabsHeader = style.div` 
display: flex;
margin: 0px;
padding: 0px;
position: relative;
font-weight: 500;
position: relative;
margin-bottom: 40px;

        &::before {
            content: "";
            border-radius: 2px;
            bottom: 0px;
            margin: 0px;
            position: absolute;
            width: inherit;
            left: 0px;
            right: 8px;
            background-color: rgb(235, 235, 235);
            height: 1px;
        }
    .GWTab {
        color: rgb(27, 35, 36);
        font-weight: 400;
        font-size: 16px;
        margin-left: 32px;
        padding-bottom: 14px;
        cursor: pointer;
        &:first-child{
            margin-left: 0px;
        }
        &::after {
            content: "";
            border-radius: 2px;
            bottom: 0px;
            margin: 0px;
            position: absolute;
            width: inherit;
            left: 0px;
            right: 0px;
            border-bottom: 2px solid transparent;
            height: 0px;
        }

        &.active{
            color: ${blue1};
            font-weight: 500;
            position: relative;
            &::after {
                border-bottom: 4px solid ${blue1};
            }
        }
    }
`;

export const GWPillContainer = style.div`
    display: flex;
    flex-wrap: wrap;
    gap: 6px;
`;
export const GWPill = style.div`
  overflow: hidden;
    padding: 8px 16px;
    min-width: 80px;
    text-overflow: ellipsis;
    white-space: nowrap;
    background-color: #EEF0F4;
    border-radius: 32px;
    box-sizing: border-box;
    box-shadow: none;
    max-width: 100%;
    font-weight: 500;
    font-size: 15px;
    line-height: 19px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: ${blue2};
    cursor: pointer;
    border: 2px solid transparent;
    -webkit-transition: all .15s ease-in-out;
    transition: all .15s ease-in-out;
    &:hover{
        border-color: ${blue1};
    }
    &.active{
        background-color: ${blue1} !important;
        color: ${whiteColor} !important;
    }
    &.disabled{
        opacity: 0.3;        
    }
`;

export const ToDoTableHeader = style.div`
    font-weight: 600;
    font-size: 28px;
    line-height: 140%;
    color: #111A1C;
`;

export const MyClientsTitle = style.div`
    font-weight: 500;
    font-size: 32px;
    line-height: 160%;
    color: #1B2324;
    margin: 0;
`;
export const GWChatContainer = style.div`
    flex: 1;
    overflow: hidden;
    display: flex;
    -webkit-flex-direction: row;
    flex-direction: row;
    width: 100%;    
    background: #FFFFFF;
    // box-shadow: 0px 0px 2px rgb(37 41 35 / 8%), 0px 1px 1px rgb(37 42 49 / 5%);
    // border-radius: 8px;
    position: relative;
    // border: 1px solid #e2e2e2;
`;
export const GWChatListContainer = style.div`
    overflow-x: hidden;
    overflow-y: scroll;
    padding-right: 4px;
    padding-top: 0px;
    background-color: transparent;
    min-width: 320px;
    background-color: #ffffff;
    background: #FFFFFF;
    // box-shadow: 0px 0px 2px rgb(37 41 35 / 8%), 0px 1px 1px rgb(37 42 49 / 5%);
    // border-radius: 8px;
    position: relative;
    // border: 1px solid #e2e2e2;
    height: 100%;
    
`;
export const GWChatListContainerWrap = styled.div`
margin: 10px 0;
width: 417px;
.searchContainer{
    padding: 8px;
        /* background-color: #f6f6f6; */
        display: flex;
        align-items: center;
        gap:16px; 
        span{
            font-weight: 500;
            font-size: 24px;
            line-height: 140%;
            color: #1E0702;
            flex: 1;
        }
}
    .searchBlocker{
        max-width: 260px;
      input[type='text']{
        background: #F2F4F5;
        border-radius: 8px;
        border: 0;
      }
    }
`;
export const GWChatSingleListContainer = style.div`
    flex: 1;
    background-color: #ffffff;
    border: 1px solid #EEF0F4;
    box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.05);
    border-radius: 20px;
    margin: 10px;
    overflow: hidden;
    .noChatText{
        color: #767B7C;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 36px;
    }
`;
export const GWChatItem = style.div`
    padding: 16px 6px;
    min-height: 50px;
    display: flex;
    align-items: center;
    gap: 16px;
    cursor: pointer;
    // border-bottom: 1px solid #f3f3f3;
    &.active{
        background-color: #FAFAFC;
    }
    &:hover{
        background-color: #FAFAFC;
    }
    .pictureProfile{
        display: flex;
    }
`;
export const GWChatClientInfo = style.div`
display: flex;
align-items: center;
`;
export const GWChatName = style.div`
flex-grow: 1;
font-size: 16px;
line-height: 16px;
color: #262933;
`;

export const GWChatTimeStamp = style.div`
font-size: 14px;
color: #808080;
`;
export const GWChatClientCurrentStatusBlock = style.div`
display: flex;
align-items: center;
`;
export const GWChatClientCurrentStatus = style.div`
flex-grow: 1;
font-weight: 400;
font-size: 14px;
line-height: 22px;
display: flex;
align-items: center;
color: #6F7381;
`;
export const GWChatClientCurrentStatusTime = styled.div`
 font-weight: 500;
font-size: 10px;
line-height: 15px;
text-align: right;
color: #B3B3B3;   
`;
export const GWChatClientCurrentUnread = style.div`
background-color: #355F92;
color: #fff;
border-radius: 50%;
width: 13px;
height: 13px;
display: flex;
align-items: center;
justify-content: center;
font-size: 12px;
font-weight: 500;
`;
export const SingleChatContent = styled.div`
    height: 100%;
    /* overflow: auto; */
    /* padding: 16px; */
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

export const ChatMessageWrapper = styled.div`
    display: flex;
    align-items: flex-start;
    gap: 12px;
    &.sent{
        flex-direction: row-reverse;
    }
`;

export const ChatUserImage = styled.div`
    width: 60px;
    height: 60px;
    background-color: #dcdcdc;
    border: 1px solid #dcdcdc;
    border-radius: 50%;
    overflow: hidden;
    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
`;

export const ChatMessageBox = styled.div`
    max-width: 80%;
    background-color: #F1F1F1;
    min-width: 25%;
    /* padding: 16px; */
    border-radius: 15px;
    p{
        margin: 0;
    }
    &.sent{
        background-color: #355F92;
        color: #ffffff;
    }
`;

export const ChatDetails = styled.div`
padding:  14px 16px;
position: relative;
`;

export const ChatUserName = styled.h4`
    font-size: 14px;
    font-weight: 500;
    margin: 0;
    text-transform: capitalize;
`;

export const ChatMessageTime = styled.span`
    font-size: 12px;
        display: flex;
    align-items: end;
    font-weight: 400;
font-size: 10px;
line-height: 1;
text-align: right;
text-transform: uppercase;
position: absolute;
bottom: 4px;
right: 14px;
/* color: #F9F9F9; */
`;

export const ChatMessage = styled.p`
   font-weight: 400;
font-size: 14px;
line-height: 21px;
padding: 0px;
padding-bottom: 2px;
word-break: break-all;
/* color: #FFFFFF; */
`;
export const SingleChatHeader = styled.div`
background: white;
padding: 24px 16px;
border-bottom: 1px solid #E7E7E7;
`;
export const SingleChatFooter = styled.div`
background: white;
padding: 8px;
padding-top: 16px;
min-height: 48px;
form{
    display: flex;
    gap: 8px;
    input[type='text']{
           width: 100%;
    padding: 16px;
    border-radius: 8px;
    font-size: 16px;
    background: #FAFAFC;
border-radius: 18.5px;
    border: 0.5px solid transparent; 
    &:focus{
        border-color: #355F92 ;
    }

    }
    button{
        cursor: pointer;
        border: 0;
        background: transparent;
        align-items: center;
    }
}`;
export const SingleChatContentContainer = styled.div`
flex: 1;
overflow: hidden;
overflow: auto;
padding: 16px;
`;
export const SingleChatContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    `;

export const GWChatCaution = styled.div`
    text-align: center;
    background: #355F92;
    font-size: 12px;
    opacity: 1;
    color: #fff;
    font-weight: 500;
    border-radius: 12px;
    margin: 0 auto;
    padding: 0 18px;
`;
export const UserAvatarBlock = styled.div`
    height: 24px !important;
    width: 24px !important  ;
    background-color: #ccc;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    &.w-48{
        height: 48px !important;
    width: 48px !important  ;
    }
    .AvatarProfile{
        display: flex;
        align-items: center;
        height: 100%;
    width: 100%;
    object-fit: cover;
    }
    .alphaProfile{
        display: flex;
        align-items: center;
        line-height: 1.5;
        text-transform: capitalize;
        width: 100%;
    height: 100%;
    }
`;
export const LinkInput = styled.div`
.hideDetails {
    transition: all 0.3s ease;
height: 0;
opacity: 0;
width: 100%;
display: flex;
}

.showDetails {
transition: all 0.3s ease;
overflow-y: hidden;
opacity: 1;
}
`;

export const CurrentDate = styled.div`
    font-weight: 500;
font-size: 14px;
line-height: 21px;
text-align: center;
color: #A9A9A9;
`;

export const ChatHeadAvatarBlock = styled.div`
    height: 40px;
    width: 40px;
    background-color: #ccc;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    .alphaProfile{
        display: flex;
        align-items: center;
        line-height: 1.5;
        text-transform: capitalize;
        font-size: 24px;
        width: 100%;
    height: 100%;
    }
    .AvatarProfile{
        display: flex;
        align-items: center;
        height: 100%;
    width: 100%;
    object-fit: cover;
    }
`;
export const PrevMsgs = styled.div`
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 16px;
align-items: center;
text-align: center;
color: #355F92;
:hover{
    text-decoration-line: underline;
}
`;
export const SelectedOption = style.div`
// width: 250px;
display: flex;
justify-content: space-between;
// align-items: center;
color: #FFFFFF;
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 21px;
background: #355F92;
border-radius: 8px 8px 0px 0px;
padding-left: 16px;
padding-right: 16px;
padding-top: 13px;
padding-bottom: 11px;
`;
export const FilterDiv = style.div`
min-width: 210px;
display: flex;
position: relative;
background-color: #F8F8F8;
.svg_styles{
    position: absolute;
    right: 12px;
    top: 12px;
}
&.hide-border{
> div:first-child {
    z-index: 99
  }
}

`;

export const CustomFiltertext = style.div`
    position: absolute;
    z-index: 999999;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 16px;
    height: 100%;
    width: calc(100% - 32px);
    color: #1B2324;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    cursor: pointer;
    bottom: 0px;
    &.active{
        border-right: 1px solid #355f92;
    }
`;
export const FilterCustomDiv = style.div`
    background: #F5F7F7;
    // border-radius: 8px 0px 0px 0px;
    color: #1B2324;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    display: flex;
    align-items: center;
    padding: 13px 16px;
    cursor: pointer;
    border-radius: 4px;
    &.active{
        background: #355f92;
        color: #FFFFFF
    }
`;
export const MsgTodoContent = style.div`
font-weight: 400;
font-size: 16px;
line-height: 22px;
color: #666666;
`;
export const OverviewMessageContent = style.span`
    h3{
        margin-bottom: 8px;
        font-size: 20px;
    }
`;
export const ClientOnboardContainer = styled.div`
    min-width: 125px;
    display: flex;
    align-items: center;
    justify-content: center;
`;
