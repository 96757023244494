/* eslint-disable require-jsdoc */
/* eslint-disable max-len */
import React from 'react';

export default function GritwellLogo() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='135' height='40' viewBox='0 0 135 40' fill='none'>
      <path d='M17.7939 26.7553C20.3987 26.7553 21.7027 27.4718 21.7027 28.8579C21.7027 30.2439 19.6352 32.9285 16.7129 35.3911C15.0907 36.7297 13.2738 37.8152 11.3243 38.6106C9.35008 39.5125 7.20585 39.9862 5.03378 40C1.65541 40 0 38.7923 0 36.8242C0 34.8562 1.30406 33.1539 3.95271 31.5896C2.06757 31.3204 1.12162 30.513 1.12162 29.2178C1.12162 27.5626 3.28041 25.5475 6.38177 24.1144C3.18919 23.0412 0.942571 20.7166 0.942571 17.1808C0.928414 16.0781 1.17774 14.9877 1.66998 13.9998C2.16222 13.0119 2.88327 12.1546 3.77365 11.4987C5.54157 10.0705 7.74381 9.28178 10.0203 9.26158H21.6554V9.35241L17.4324 11.5896C18.1884 12.1455 18.7982 12.8751 19.2099 13.7162C19.6217 14.5574 19.8232 15.4851 19.7973 16.4205C19.8363 17.6086 19.5723 18.7871 19.0299 19.8461C18.4876 20.905 17.6846 21.8098 16.696 22.476C14.7077 23.9353 12.2941 24.7058 9.82433 24.6695C8.69933 24.6695 7.89189 25.2077 7.89189 25.9209C7.89189 26.503 8.56757 26.7721 9.91893 26.7721L17.7939 26.7553ZM13.5135 34.7183C15.2669 33.7796 16.1656 32.9756 16.1656 32.2591C16.1656 31.9025 15.7162 31.7208 14.7737 31.7208H6.1115C5.07773 32.3936 4.53718 33.2447 4.53718 34.1834C4.53718 35.4819 5.70609 36.1077 8.04394 36.1077C9.95593 36.1136 11.838 35.6355 13.5135 34.7183ZM7.2804 15.8116C7.24221 17.5011 7.66112 19.1697 8.49325 20.6426C9.30069 21.9882 10.1993 22.6611 11.2331 22.6611C12.6689 22.6611 13.5676 20.6022 13.5676 17.6922C13.5676 15.8576 13.1633 14.337 12.3548 13.1304C11.5473 11.9226 10.6487 11.2935 9.65203 11.2935C8.40202 11.2734 7.2804 12.9319 7.2804 15.8116Z' fill='black'/>
      <path d='M33.1588 17.8974C33.294 13.8268 32.2602 11.2296 30.1487 11.2296H30.0676V26.1732C30.0676 29.0362 30.3379 30.4693 32.4325 31.5559V31.6232H21.2838V31.5324C23.3953 30.4592 23.6656 29.0261 23.6656 26.1497V14.7115C23.6656 11.8453 23.3953 10.4155 21.2838 9.32885V9.23802H31.723C35.4967 9.23802 37.9663 10.624 38.5507 14.9201L33.1588 17.8974Z' fill='black'/>
      <path d='M37.6521 31.6232V31.5324C39.7635 30.4592 40.0168 29.0261 40.0168 26.1497V14.7115C40.0168 11.8453 39.7469 10.4155 37.6521 9.32885V9.23802H46.4124V26.1732C46.4124 29.0362 46.6823 30.4693 48.7939 31.5559V31.6232H37.6521ZM43.0574 0C45.034 0 46.6522 1.56434 46.6522 3.40118C46.6121 4.32368 46.2155 5.1951 45.5457 5.83372C44.876 6.47229 43.9848 6.82877 43.0574 6.82877C42.1304 6.82877 41.2388 6.47229 40.569 5.83372C39.8993 5.1951 39.5031 4.32368 39.4629 3.40118C39.4494 1.56434 41.0677 0 43.0439 0H43.0574Z' fill='black'/>
      <path d='M62.5441 31.6232C56.9291 31.9361 53.7365 31.4887 52.3446 29.6989C51.6689 28.804 51.2231 28.0168 51.0877 27.2363C50.9341 26.0091 50.873 24.7721 50.9055 23.5357V11.677H48.3445V9.43984C51.7229 8.85787 54.9056 5.99494 57.1959 1.746H57.2872V9.26154H61.24V11.677H57.2872V24.1615C57.2872 25.9512 57.6016 27.3373 58.2773 28.2759C58.953 29.2145 60.3412 30.3347 62.5441 31.5425V31.6232Z' fill='black'/>
      <path d='M87.1215 9.26158H93.5V9.35241C93.0597 9.82976 92.6961 10.3722 92.4226 10.9605C92.23 11.3511 92.0649 11.7546 91.9291 12.1682C91.7941 12.4844 91.7028 12.7065 91.6588 12.841C91.5238 13.1539 91.1657 14.1396 91.0307 14.4962L84.605 31.6232H83.2536L77.7029 17.9411L72.4729 31.6232H71.1216L64.1623 14.7216C62.9022 11.7207 61.9594 9.93102 61.2873 9.33893V9.2481H71.9358V9.33893C69.5542 10.1867 69.3279 11.7544 70.4054 14.0488L73.6488 22.3246L76.3515 14.6308C75.1387 11.6333 74.1959 9.84355 73.5202 9.35241V9.26158H84.1556V9.35241C81.7907 10.2002 81.5946 11.7679 82.6284 14.0623L85.6689 22.2506L88.5138 14.2742C89.5912 11.8116 89.4122 10.1127 87.1215 9.35241V9.26158Z' fill='black'/>
      <path d='M112.372 24.1615L112.463 24.2052C111.818 26.2807 110.678 28.1694 109.139 29.709C108.413 30.4604 107.54 31.0546 106.573 31.4546C105.606 31.8546 104.567 32.0517 103.521 32.0336C100.96 32.0336 98.6689 31.0513 96.6926 29.0362C94.7164 27.021 93.7263 24.2052 93.7263 20.4912C93.7263 16.868 94.8041 14.0488 96.9158 11.9899C99.0274 9.93103 101.453 8.85787 104.105 8.85787C106.531 8.85787 108.554 9.84356 110.216 11.7679C111.878 13.6922 112.777 16.4205 112.821 19.9563H99.9833C100.565 24.7872 103.216 27.021 106.402 27.021C108.419 27.0244 110.396 26.0858 112.372 24.1615ZM99.8718 17.5374H106.791C106.385 13.5576 104.993 10.873 103.24 10.873C101.635 10.873 100.017 13.423 99.8821 17.5374H99.8718Z' fill='black'/>
      <path d='M112.956 31.6232V31.5324C115.068 30.4592 115.321 29.0261 115.321 26.1497V5.77292C115.321 2.90664 115.051 1.47687 112.956 0.390242V0.312866H121.72V26.1732C121.72 29.0362 121.987 30.4693 124.085 31.5559V31.6232H112.956Z' fill='black'/>
      <path d='M123.875 31.6232V31.5324C125.987 30.4592 126.257 29.0261 126.257 26.1497V5.77292C126.257 2.90664 125.987 1.47687 123.875 0.390242V0.312866H132.635V26.1732C132.635 29.0362 132.905 30.4693 135 31.5559V31.6232H123.875Z' fill='black'/>
    </svg>
  );
}
