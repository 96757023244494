/* eslint-disable require-jsdoc */
/* eslint-disable max-len */
import React from 'react';

export default function TestIcon() {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M22.5465 7.82796L16.9215 2.20296C16.817 2.09808 16.6928 2.01486 16.556 1.95808C16.4193 1.9013 16.2727 1.87207 16.1246 1.87207C15.9766 1.87207 15.8299 1.9013 15.6932 1.95808C15.5565 2.01486 15.4323 2.09808 15.3277 2.20296L3.14025 14.3905C2.70155 14.8121 2.35135 15.3169 2.11016 15.8755C1.86897 16.4341 1.74162 17.0352 1.73558 17.6437C1.72953 18.2521 1.84491 18.8556 2.07496 19.4189C2.30501 19.9821 2.6451 20.4939 3.07534 20.9241C3.50558 21.3544 4.01731 21.6945 4.58059 21.9245C5.14387 22.1545 5.74738 22.2699 6.3558 22.2639C6.96422 22.2578 7.56532 22.1305 8.12392 21.8893C8.68252 21.6481 9.18739 21.2979 9.609 20.8592L20.109 10.3592L22.1059 9.69358C22.2869 9.63183 22.4495 9.52568 22.5789 9.38483C22.7083 9.24398 22.8003 9.07293 22.8465 8.88733C22.8879 8.69971 22.8822 8.50475 22.8299 8.31988C22.7775 8.135 22.6802 7.96599 22.5465 7.82796V7.82796ZM8.01525 19.2655C7.58013 19.7006 6.98998 19.945 6.37462 19.945C5.75927 19.945 5.16912 19.7006 4.734 19.2655C4.29888 18.8303 4.05443 18.2402 4.05443 17.6248C4.05443 17.0095 4.29888 16.4193 4.734 15.9842L7.10587 13.6123C7.34962 13.453 8.66212 12.7123 10.7434 13.753C11.3998 14.0859 12.1008 14.3227 12.8246 14.4561L8.01525 19.2655ZM19.1434 8.30608C18.9774 8.36122 18.8265 8.45431 18.7027 8.57796L15.3934 11.8873C15.1496 12.0467 13.8371 12.7873 11.7559 11.7467C11.0994 11.4138 10.3985 11.177 9.67462 11.0436L16.1246 4.59358L19.6684 8.13733L19.1434 8.30608Z' fill='#1B2324'/>
    </svg>

  );
}
