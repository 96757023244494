/* eslint-disable require-jsdoc */
/* eslint-disable max-len */
import React from 'react';

export default function AttachmentIcon() {
  return (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.83333 14C3.46667 14 3.15278 13.8694 2.89167 13.6083C2.63056 13.3472 2.5 13.0333 2.5 12.6667V3.33333C2.5 2.96667 2.63056 2.65278 2.89167 2.39167C3.15278 2.13056 3.46667 2 3.83333 2H8.5V3.33333H3.83333V12.6667H13.1667V8H14.5V12.6667C14.5 13.0333 14.3694 13.3472 14.1083 13.6083C13.8472 13.8694 13.5333 14 13.1667 14H3.83333ZM6.96667 10.4667L6.03333 9.53333L12.2333 3.33333H9.83333V2H14.5V6.66667H13.1667V4.26667L6.96667 10.4667Z" fill="#1E3653"/>
    </svg>

  );
}
