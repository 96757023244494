/* eslint-disable require-jsdoc */
/* eslint-disable max-len */
import React from 'react';

export default function LifestyleTabIcon() {
  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M10 0.542969C10.3788 0.542969 10.7251 0.756971 10.8945 1.09576L13.4734 6.25357L19.144 7.07838C19.5206 7.13317 19.8335 7.39699 19.9511 7.75896C20.0687 8.12093 19.9706 8.51828 19.6981 8.78397L15.571 12.8079L16.4994 18.4815C16.5608 18.8564 16.4043 19.2338 16.0957 19.4553C15.787 19.6769 15.3794 19.7044 15.0438 19.5263L10 16.85L4.95624 19.5263C4.62062 19.7044 4.21306 19.6769 3.9044 19.4553C3.59573 19.2338 3.4393 18.8564 3.50065 18.4815L4.42906 12.8079L0.301929 8.78397C0.0294234 8.51828 -0.068637 8.12093 0.0489696 7.75896C0.166576 7.39699 0.479458 7.13317 0.856089 7.07838L6.5267 6.25357L9.1056 1.09576C9.27499 0.756971 9.62126 0.542969 10 0.542969ZM10 3.77904L8.08196 7.61518C7.93561 7.90788 7.65531 8.11045 7.33147 8.15756L3.14842 8.766L6.19813 11.7395C6.43182 11.9673 6.53961 12.2949 6.4869 12.617L5.80004 16.8145L9.53131 14.8346C9.82444 14.6791 10.1756 14.6791 10.4687 14.8346L14.2 16.8145L13.5132 12.617C13.4604 12.2949 13.5682 11.9673 13.8019 11.7395L16.8516 8.766L12.6686 8.15756C12.3448 8.11045 12.0645 7.90788 11.9181 7.61518L10 3.77904Z' fill='#EFAD12'/>
    </svg>

  );
}
