/* eslint-disable require-jsdoc */
/* eslint-disable max-len */
import React from 'react';

export default function CloseIcon() {
  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M2.29958 0.414566C2.04811 0.171688 1.7113 0.0372957 1.36171 0.0403336C1.01211 0.0433715 0.677696 0.183597 0.430486 0.430808C0.183275 0.678019 0.0430491 1.01244 0.0400112 1.36203C0.0369733 1.71163 0.171366 2.04843 0.414243 2.2999L8.11424 9.9999L0.41291 17.6999C0.285563 17.8229 0.183987 17.97 0.114108 18.1327C0.0442292 18.2954 0.00744814 18.4703 0.00590972 18.6474C0.0043713 18.8244 0.0381066 19 0.105148 19.1638C0.172189 19.3277 0.271193 19.4766 0.396383 19.6018C0.521574 19.7269 0.670443 19.826 0.834305 19.893C0.998167 19.96 1.17374 19.9938 1.35078 19.9922C1.52782 19.9907 1.70278 19.9539 1.86545 19.884C2.02812 19.8142 2.17525 19.7126 2.29824 19.5852L9.99958 11.8852L17.6996 19.5852C17.951 19.8281 18.2878 19.9625 18.6374 19.9595C18.987 19.9564 19.3215 19.8162 19.5687 19.569C19.8159 19.3218 19.9561 18.9874 19.9591 18.6378C19.9622 18.2882 19.8278 17.9514 19.5849 17.6999L11.8849 9.9999L19.5849 2.2999C19.8278 2.04843 19.9622 1.71163 19.9591 1.36203C19.9561 1.01244 19.8159 0.678019 19.5687 0.430808C19.3215 0.183597 18.987 0.0433715 18.6374 0.0403336C18.2878 0.0372957 17.951 0.171688 17.6996 0.414566L9.99958 8.11457L2.29958 0.413232V0.414566Z' fill='#95A1A2' />
    </svg>
  );
}
