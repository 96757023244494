/* eslint-disable no-unused-vars */
import {PiHeaderMenu} from 'pixel-kit';
import React, {useState, useContext, useEffect} from 'react';
import {HeaderMain} from '../header/header-components';
import Logo from '../../assets/Vector.svg';
import MobileLogo from '../../assets/mobileLogo.svg';
// const Logo = require('../../assets/Vector.svg') as string;
// const MobileLogo = require('../../assets/mobileLogo.svg') as string;
import {useHistory, useLocation} from 'react-router-dom';
import {triggerApi} from '../../services';
import {AuthContext} from '../../providers/auth';
import {
  setLocalStorage,
  getLocalStorage,
  clearLocalStorage,
} from '../../core/local-storage-service';
import apiEndpoint from '../../core/apiend_point';
import {
  ApiResponseProps,
  PayloadProps,
  Permissions,
  PiMenuOptions,
  PiMenuProfileOptions,
  ProfileProps,
} from '../../core/schema';
import {useAppSelector} from '../../store/hooks';
import {checkUserPermissions} from '../../core/checkUserPermission';
import ProfileRecommendation from '../icon/icons/profile-recommendation-icon';
import ProfileHealth from '../icon/icons/profile-health-icon';
import ProfileSettings from '../icon/icons/profile-settings-icon';
import ProfilePayment from '../icon/icons/profile-payment-icon';
import ProfileLogout from '../icon/icons/profile-logout-icon';
import ProfileIcon from '../icon/icons/profile-icon';
import {defaultName} from '../../core/defaultname-image';

/**
 *
 * @param {*} props
 * @return {Loginheader}
 */
export default function Loginheader(props: any) {
  const [headerLinkactive, setHeaderLinkactive] = useState('');
  const [hideHeader, setHideheader] = useState(false);
  const {userInfo}: any = useContext(AuthContext);
  const history = useHistory();
  const proImg = userInfo.img_name ? userInfo.display_url : '';
  const location = useLocation();
  const [menuItems, setMenuItems] = useState<PiMenuOptions[]>([]);
  const [profileItems, setProfileItems] = useState<PiMenuProfileOptions[]>([]);
  const [profileCommonItems, setProfileCommonItems] =
  useState<PiMenuProfileOptions[]>([]);
  const userPermissions: Permissions[] =
   useAppSelector((state) => state.globalData.permissions);
  const token = getLocalStorage('token') ? getLocalStorage('token') : '';
  const globalData: any = useAppSelector((state) =>
    state.globalData.globalData);
  const [chatCount, setChatCount] = useState<any>();
  useEffect(()=>{
    // userInfo.livehelper_user_id && getChatCount();
  }, []);

  useEffect(() => {
    getMenuItems();
    getProfileItems();
    getProfileCommonItems();

    if (window.location.href.includes('office/health-plan') ||
        window.location.href.indexOf('office/clients')) {
      setHeaderLinkactive('clients');
    }

    if (window.location.href.includes('office/myschedule') ||
        window.location.href.includes('office/myschedule/upcoming')) {
      setHeaderLinkactive('myschedule');
    }

    if (window.location.href.includes('office/dashboard') ||
        window.location.href.includes('office/matchcare') ||
        window.location.href.includes('office/helathforms')) {
      setHeaderLinkactive('dashboard');
    }

    if (window.location.href.includes('chats')) {
      setHeaderLinkactive('chats');
    } else if (window.location.href.includes('profile')) {
      setHeaderLinkactive('');
    } else if (window.location.href.includes('payment')) {
      setHeaderLinkactive('');
    } else if (window.location.href.includes('user') ||
          window.location.href.includes('roles')) {
      setHeaderLinkactive('');
    } else if (window.location.href.includes('healthplans')) {
      setHeaderLinkactive('');
    } else if (window.location.href.includes('changepassword')) {
      setHeaderLinkactive('');
    } else if (window.location.href.includes('permission-denied')) {
      setHeaderLinkactive('');
    }
  }, [getLocalStorage('leftMenu'), window.location.href.indexOf('office'),
    userPermissions, globalData, location]);

  useEffect(() => {
    const handleInvalidToken = (e: any) => {
      if (e.key === null && e.newValue === e.oldValue) {
        if (!getLocalStorage('token')) {
          window.location.reload();
        }
      }
    };

    window.addEventListener('storage', handleInvalidToken);
    return function cleanup() {
      window.removeEventListener('storage', handleInvalidToken);
    };
  }, []);
  /**
   *
   */
  function getMenuItems() {
    const memu = [];
    if ((userInfo.code === 'sales_manager' ||
        userInfo.code === 'health_coach' ||
         userInfo.code === 'care_manager' ||
          userInfo.code === 'md')) {
      memu.push({
        children: [],
        key: 'dashboard',
        label: 'Dashboard',
        href: '',
      });
    }

    memu.push({
      children: [],
      key: 'clients',
      label: 'My clients',
      href: '',
    });
    memu.push({
      key: 'myschedule',
      label: 'My schedule',
      children: [],
      href: '',
    });
    memu.push({
      key: 'chats',
      label: 'Chats',
      children: [],
      href: '',
    });

    setMenuItems(memu);
  }

  /**
   *
   */
  function getProfileItems() {
    const memu = [];
    if (checkUserPermissions(userPermissions, 'list-recommendation') === true) {
      memu.push({
        elemBefore: <ProfileRecommendation />,
        id: 'healthplan',
        name: 'Health Plan',
      });
    }

    if (checkUserPermissions(userPermissions, 'list-hypothesis') === true) {
      memu.push({
        elemBefore: <ProfileHealth />,
        id: 'healthprofile',
        name: 'Health Profile',
      });
    }

    if (userInfo.code === 'admin') {
      memu.push({
        elemBefore: <ProfileSettings />,
        id: 'usersettings',
        name: 'Settings',
      });
    }

    if (checkUserPermissions(userPermissions, 'admin-payments') === true) {
      memu.push({
        elemBefore: <ProfilePayment />,
        id: 'paymentdetails',
        name: 'Payment Details',
      });
    }

    setProfileItems(memu);
  }

  /**
   *
   */
  function getProfileCommonItems() {
    const memu = [];
    memu.push({
      elemBefore: <ProfileIcon />,
      id: 'myprofile',
      name: 'My Profile',
    });
    memu.push({
      elemBefore: <ProfileLogout />,
      id: 'logout',
      name: 'Logout',
    });
    setProfileCommonItems(memu);
  }

  const MenuClick = (menu: PiMenuOptions) => {
    setLocalStorage('leftMenu', menu.key);
    if (menu.key === 'clients') {
      setHeaderLinkactive(menu.key);
      history.push('/office/clients');
    } else if (menu.key === 'dashboard') {
      setHeaderLinkactive(menu.key);
      history.push('/office/dashboard');
    } else if (menu.key === 'chats') {
      history.push('/office/chats');
    } else if (menu.key === 'myschedule') {
      setHeaderLinkactive(menu.key);
      history.push('/office/myschedule/upcoming');
    }
  };

  const profileClick = (menu: ProfileProps) => {
    if (menu.id == 'logout') {
      logout();
    } else if (menu.id == 'myprofile') {
      setHeaderLinkactive('');
      history.push('/office/profile');
    } else if (menu.id == 'paymentdetails') {
      setHeaderLinkactive('');
      history.push('/office/payment/subscriptions');
    } else if (menu.id == 'healthplan') {
      setHeaderLinkactive('');
      history.push('/office/healthplans/phasesofcare');
    } else if (menu.id == 'healthprofile') {
      setHeaderLinkactive('');
      history.push('/office/healthprofiles/rootcause');
    } else if (menu.id == 'usersettings') {
      setHeaderLinkactive('');
      history.push('/office/users/userlist');
    }
  };


  const getChatCount= async () => {
    const apiObject: PayloadProps = {
      payload: {},
      method: 'GET',
      apiUrl: `${apiEndpoint.chatCountApi}/${userInfo.livehelper_user_id}`,
      headers: {
        Authorization: token,
      },
    };
    await triggerApi(apiObject)
        .then((res: ApiResponseProps) => {
        // setChatCount(res.data)
          if (res.data.list_count !== '0') {
            setChatCount(res.data.list_count);
          } else {
            setChatCount('');
          }
          const interval = setInterval(() => {
            userInfo.livehelper_user_id && getChatCount();
          }, window.location.href.includes('chats') ? 3000 : 5000);
          return () => clearInterval(interval);
        })
        .catch((err: Record<string, unknown>) => {
          console.log();
        });
  };

  const logout = () => {
    const apiObject: PayloadProps = {
      payload: {},
      method: 'POST',
      apiUrl: apiEndpoint.logoutApi,
      headers: {
        Authorization: token,
      },
    };
    triggerApi(apiObject)
        .then((res: ApiResponseProps) => {
          setHideheader(true);
          clearLocalStorage();
          // removeCookie('token', {path: '/'});
          // cookies.remove("gwc_token");
          history.push('/sign-in');
          window.location.reload();
        })
        .catch((err: Record<string, unknown>) => {
          console.log();
        });
  };

  return (
    <>

      {token && !hideHeader && <HeaderMain >
        <PiHeaderMenu
          activeKey={headerLinkactive}
          image={Logo}
          xsImage= {MobileLogo}
          onMenuClick={MenuClick}
          onProfileClick={(e: any) => {
            profileClick(e);
          }}
          chatCount= {chatCount}
          profilePicSrc={proImg && proImg}
          emptyprofilePicSrc={!proImg ?
            defaultName(userInfo.first_name.slice(0, 1),
                'defaultHeaderName') : ''}
          options={menuItems}
          profileOptions={[
            {
              menuItems: profileItems,
              title: 'Admin',
            },
            {
              menuItems: profileCommonItems,
              title: 'My account',
            },
          ]}
          onFeedbackClick={() => {
            history.push('/office/clients/feedbackform');
          }}
        />
      </HeaderMain>} </>

  );
}
