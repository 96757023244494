/* eslint-disable require-jsdoc */
/* eslint-disable max-len */
import React from 'react';

export default function HealthHistoryIcon() {
  return (
    <svg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M5 20.1051V19.1051C5 17.2486 5.7375 15.4681 7.05025 14.1554C8.36301 12.8426 10.1435 12.1051 12 12.1051V12.1051C13.8565 12.1051 15.637 12.8426 16.9497 14.1554C18.2625 15.4681 19 17.2486 19 19.1051V20.1051H5ZM12 12.1051C13.0609 12.1051 14.0783 11.6837 14.8284 10.9335C15.5786 10.1834 16 9.16597 16 8.1051C16 7.04424 15.5786 6.02682 14.8284 5.27668C14.0783 4.52653 13.0609 4.1051 12 4.1051C10.9391 4.1051 9.92172 4.52653 9.17157 5.27668C8.42143 6.02682 8 7.04424 8 8.1051C8 9.16597 8.42143 10.1834 9.17157 10.9335C9.92172 11.6837 10.9391 12.1051 12 12.1051V12.1051Z' stroke='#E09B07' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round'/>
    </svg>

  );
}
