/* eslint-disable require-jsdoc */
/* eslint-disable max-len */
import React from 'react';

export default function NoteIcon() {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M20.3144 6.04771L16.9498 2.67764C16.8971 2.61936 16.8329 2.57275 16.7612 2.54082C16.6894 2.50888 16.6118 2.49232 16.5333 2.49219L4.91666 2.49219C4.13424 2.49219 3.5 3.12744 3.5 3.91111L3.5 22.0733C3.5 22.8569 4.13424 23.4922 4.91667 23.4922L19.0833 23.4922C19.8658 23.4922 20.5 22.8569 20.5 22.0733L20.5 6.4653C20.5 6.29929 20.4276 6.15144 20.3144 6.04771ZM17.1 4.4354L18.5599 5.89759L17.1 5.89759L17.1 4.4354ZM19.3667 21.7895C19.3667 21.94 19.307 22.0844 19.2007 22.1908C19.0944 22.2973 18.9503 22.3571 18.8 22.3571L5.2 22.3571C5.04971 22.3571 4.90558 22.2973 4.79931 22.1908C4.69304 22.0844 4.63333 21.94 4.63333 21.7895L4.63333 4.19489C4.63333 4.04436 4.69303 3.9 4.7993 3.79356C4.90557 3.68712 5.04971 3.62732 5.2 3.62732L15.9667 3.62732L15.9667 6.4653C15.9667 6.61581 16.0264 6.76013 16.1327 6.86654C16.239 6.97295 16.3831 7.03273 16.5333 7.03273L19.3667 7.03273L19.3667 21.7895Z" fill="#355F92"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M16.9498 10.0622C17.1939 10.3063 17.1939 10.702 16.9498 10.9461L10.9498 16.9461C10.7246 17.1713 10.3661 17.1911 10.1175 16.9922L7.61746 14.9922C7.34792 14.7766 7.30422 14.3832 7.51985 14.1137C7.73548 13.8442 8.12879 13.8005 8.39832 14.0161L10.4615 15.6666L16.0659 10.0622C16.31 9.81813 16.7058 9.81813 16.9498 10.0622Z" fill="#355F92"/>
    </svg>
  );
}

