import axios from '../axios/http';
import {PayloadProps} from '../schema/schema';
const apiBaseUrl = process.env.REACT_APP_API_URL;

/**
 * @param {PayloadProps} params required params
 * @return {object} params
 */
export async function triggerApi(params: PayloadProps) {
  return getResponse(params);
}


/**
 * @param {any} params required params
 * @return {object} params
 */
async function getResponse(params: any) {
  const methodTypes = ['GET', 'POST', 'PUT', 'DELETE'];
  let paramsMethod;
  if (methodTypes.includes(params.method.toUpperCase())) {
    paramsMethod = params.method;
  } else {
    paramsMethod = 'GET';
  }

  return axios({
    method: paramsMethod,
    url: apiBaseUrl + params.apiUrl,
    data: params.payload,
    headers: params.headers ? params.headers : {},

  })
      .then((res: any) => {
        console.log(res);
        const response = res.data;
        return response;
      })
      .catch((err: any) => {
        console.log(err);
      });
}
