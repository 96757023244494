
export const setLocalStorage = (key: string, item: any) => {
  localStorage.setItem(key, item);
};

export const getLocalStorage = (item: any) => {
  if (item === 'token') {
    return localStorage.getItem('token') ? localStorage.getItem('token') : '';
  }

  return localStorage.getItem(item) ? localStorage.getItem(item) : '';
};

export const clearLocalStorage = () => {
  document.cookie = 'token=;path=/;Max-Age=0;expires' + new Date();
  localStorage.clear();
};

/**
 * @param {string} cname
 * @return {getCookie}
 */
// function getCookie(cname: string) {
//  const name = cname + '=';
//  const ca = document.cookie.split(';');
//  for (let i = 0; i < ca.length; i++) {
//    let c = ca[i];
//    while (c.startsWith(' ')) {
//      c = c.substring(1);
//    }

//    if (c.startsWith(name)) {
//      return c.substring(name.length, c.length);
//    }
//  }

//  return '';
// }

