
import {
  lazy} from 'react';
const Profile = lazy(() =>
  import('../modules/admin-profile/component/admin-profile'));
const AcuityAppointment = lazy(() =>
  import('../modules/care-manager/care-client/acuity-appointment'));
const HealthDashboard = lazy(() =>
  import('../modules/health-coach/component/health-dashboard'));
const HealthClients = lazy(() =>
  import('../modules/health-coach/component'));
const HCCompletedTodos = lazy(() =>
  import('../modules/health-coach/component/hc-completed-todos'));

export const healthRoutes = [
  {
    path: '/office/clients',
    component: HealthClients,
    private: true,
  },
  {
    path: '/office/root_cause',
    component: HealthClients,
    private: true,
  },
  {
    path: '/office/leads',
    component: HealthClients,
    private: true,
  },
  {
    path: '/office/dashboard',
    component: HealthDashboard,
    private: true,
  },
  {
    path: '/office/dashboard/completedtodos',
    component: HCCompletedTodos,
    private: true,
  },
  {
    path: '/office/profile',
    component: Profile,
    private: true,
  },
  {
    path: '/office/myschedule/:id/appointment',
    component: AcuityAppointment,
    private: true,
  },
  {
    path: '/office/dashboard/:id/appointment',
    component: AcuityAppointment,
    private: true,
  },
];
