/* eslint-disable require-jsdoc */
/* eslint-disable max-len */
import React from 'react';

export default function EditIcon() {
  return (
    <svg width='19' height='20' viewBox='0 0 19 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M3.41484 15.625C3.46172 15.625 3.50859 15.6203 3.55547 15.6133L7.49766 14.9219C7.54453 14.9125 7.58906 14.8914 7.62188 14.8563L17.557 4.92109C17.5788 4.89941 17.596 4.87366 17.6078 4.8453C17.6195 4.81695 17.6256 4.78656 17.6256 4.75586C17.6256 4.72516 17.6195 4.69477 17.6078 4.66642C17.596 4.63806 17.5788 4.61231 17.557 4.59063L13.6617 0.692969C13.6172 0.648438 13.5586 0.625 13.4953 0.625C13.432 0.625 13.3734 0.648438 13.3289 0.692969L3.39375 10.6281C3.35859 10.6633 3.3375 10.7055 3.32812 10.7523L2.63672 14.6945C2.61392 14.8201 2.62207 14.9493 2.66045 15.071C2.69884 15.1927 2.76632 15.3032 2.85703 15.393C3.01172 15.543 3.20625 15.625 3.41484 15.625ZM4.99453 11.5375L13.4953 3.03906L15.2133 4.75703L6.7125 13.2555L4.62891 13.6234L4.99453 11.5375ZM18 17.5938H0.75C0.335156 17.5938 0 17.9289 0 18.3438V19.1875C0 19.2906 0.084375 19.375 0.1875 19.375H18.5625C18.6656 19.375 18.75 19.2906 18.75 19.1875V18.3438C18.75 17.9289 18.4148 17.5938 18 17.5938Z' fill='#355F92' />
    </svg>

  );
}
