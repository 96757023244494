/* eslint-disable require-jsdoc */
/* eslint-disable max-len */
import React from 'react';

export default function PlansIcon() {
  return (
    <svg width='10' height='16' viewBox='0 0 10 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path fillRule='evenodd' clipRule='evenodd' d='M4.96236 0C5.40418 0 5.76236 0.358172 5.76236 0.8V2.55594H8.43122C8.87305 2.55594 9.23122 2.91411 9.23122 3.35594C9.23122 3.79777 8.87305 4.15594 8.43122 4.15594H5.76236V7.02878H6.69685C7.53274 7.02878 8.34629 7.33401 8.95577 7.89538C9.56746 8.45878 9.92497 9.23767 9.92497 10.0652C9.92497 10.8927 9.56746 11.6716 8.95577 12.235C8.34629 12.7964 7.53274 13.1016 6.69685 13.1016H5.76236V14.8575C5.76236 15.2993 5.40418 15.6575 4.96236 15.6575C4.52053 15.6575 4.16236 15.2993 4.16236 14.8575V13.1016H0.8C0.358172 13.1016 0 12.7435 0 12.3016C0 11.8598 0.358172 11.5016 0.8 11.5016H4.16236V8.62878H3.22812C2.39223 8.62878 1.57868 8.32355 0.969198 7.76219C0.357508 7.19879 0 6.41989 0 5.59236C0 4.76483 0.357508 3.98593 0.969198 3.42253C1.57868 2.86117 2.39223 2.55594 3.22812 2.55594H4.16236V0.8C4.16236 0.358172 4.52053 0 4.96236 0ZM4.16236 4.15594H3.22812C2.77605 4.15594 2.3544 4.32195 2.05316 4.59941C1.75413 4.87483 1.6 5.23362 1.6 5.59236C1.6 5.9511 1.75413 6.30989 2.05316 6.58531C2.3544 6.86277 2.77605 7.02878 3.22812 7.02878H4.16236V4.15594ZM5.76236 8.62878V11.5016H6.69685C7.14892 11.5016 7.57057 11.3356 7.87181 11.0582C8.17084 10.7827 8.32497 10.4239 8.32497 10.0652C8.32497 9.70646 8.17084 9.34767 7.87181 9.07225C7.57057 8.79479 7.14892 8.62878 6.69685 8.62878H5.76236Z' fill='#1B2324' />
    </svg>

  );
}
