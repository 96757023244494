/* eslint-disable require-jsdoc */
/* eslint-disable max-len */
import React from 'react';

export default function TestingTabIcon() {
  return (
    <svg width='22' height='21' viewBox='0 0 22 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M21.5465 6.19807L15.9215 0.573075C15.817 0.468195 15.6928 0.384978 15.556 0.328197C15.4193 0.271416 15.2727 0.242188 15.1246 0.242188C14.9766 0.242188 14.8299 0.271416 14.6932 0.328197C14.5565 0.384978 14.4323 0.468195 14.3277 0.573075L2.14025 12.7606C1.70155 13.1822 1.35135 13.6871 1.11016 14.2457C0.868967 14.8043 0.741622 15.4054 0.735577 16.0138C0.729533 16.6222 0.844911 17.2257 1.07496 17.789C1.30501 18.3523 1.6451 18.864 2.07534 19.2942C2.50558 19.7245 3.01731 20.0646 3.58059 20.2946C4.14387 20.5247 4.74738 20.64 5.3558 20.634C5.96422 20.628 6.56532 20.5006 7.12392 20.2594C7.68252 20.0182 8.18739 19.668 8.609 19.2293L19.109 8.72932L21.1059 8.0637C21.2869 8.00195 21.4495 7.8958 21.5789 7.75494C21.7083 7.61409 21.8003 7.44305 21.8465 7.25745C21.8879 7.06983 21.8822 6.87486 21.8299 6.68999C21.7775 6.50512 21.6802 6.33611 21.5465 6.19807ZM7.01525 17.6356C6.58013 18.0707 5.98998 18.3151 5.37462 18.3151C4.75927 18.3151 4.16912 18.0707 3.734 17.6356C3.29888 17.2005 3.05443 16.6103 3.05443 15.9949C3.05443 15.3796 3.29888 14.7894 3.734 14.3543L6.10587 11.9824C6.34962 11.8231 7.66212 11.0824 9.74337 12.1231C10.3998 12.456 11.1008 12.6928 11.8246 12.8262L7.01525 17.6356ZM18.1434 6.6762C17.9774 6.73133 17.8265 6.82443 17.7027 6.94807L14.3934 10.2575C14.1496 10.4168 12.8371 11.1574 10.7559 10.1168C10.0994 9.7839 9.39849 9.5471 8.67462 9.4137L15.1246 2.9637L18.6684 6.50745L18.1434 6.6762Z' fill='#4589AF'/>
    </svg>

  );
}
