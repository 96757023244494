import {
  lazy} from 'react';
const Clients = lazy(() =>
  import('../modules/clients/component/clients'));
const Profile = lazy(() =>
  import('../modules/admin-profile/component/admin-profile'));
const AcuityAppointment = lazy(() =>
  import('../modules/care-manager/care-client/acuity-appointment'));
const MatchCareTeam = lazy(() =>
  import('../modules/match-care-team/component/match-care-team'));
const ViewHealthForm = lazy(() =>
  import('../modules/match-care-team/component/view-health-form'));
const AssignHcMatchCare = lazy(() =>
  import('../modules/match-care-team/component/assign-hc-matchcare'));
const CMDashboard = lazy(() =>
  import('../modules/care-manager/caremanager-dashboard'));
const CMCompletedTodos = lazy(() =>
  import('../modules/care-manager/cm-completed-todos'));
export const careRoutes = [

  {
    path: '/office/clients',
    component: Clients,
    private: true,
  },
  {
    path: '/office/root_cause',
    component: Clients,
    private: true,
  },
  {
    path: '/office/leads',
    component: Clients,
    private: true,
  },
  {
    path: '/office/profile',
    component: Profile,
    private: true,
  },
  {
    path: '/office/dashboard',
    component: CMDashboard,
    private: true,
  },
  {
    path: '/office/dashboard/completedtodos',
    component: CMCompletedTodos,
    private: true,
  },

  {
    path: '/office/myschedule/:id/appointment',
    component: AcuityAppointment,
    private: true,
  },

  {
    path: '/office/helathforms/:id',
    component: MatchCareTeam,
    private: true,
  },
  {
    path: '/office/helathforms/:form_type/:id',
    component: ViewHealthForm,
    private: true,
  },
  {
    path: '/office/matchcare/:type/:id',
    component: AssignHcMatchCare,
    private: true,
  },
];
