import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {Notes} from 'pixel-kit/dist/components/notesWidget/notesWidget';
import {setAutoFreeze} from 'immer';

setAutoFreeze(false);

export type ClientNotes = {
  clientAppointment: Record<string, unknown>;
  clientNotesData: Notes[];
};

const initialState: ClientNotes = {
  clientAppointment: {},
  clientNotesData: [],
};

export const clientNotesSlice = createSlice({
  name: 'clientNotes',
  initialState,
  reducers: {
    clientNotesStore(state: any, action: PayloadAction<ClientNotes>) {
      return action.payload;
    },
  },
});

export const {clientNotesStore} = clientNotesSlice.actions;

export default clientNotesSlice.reducer;
