/* eslint-disable require-jsdoc */
/* eslint-disable max-len */
import React from 'react';

export default function DownloadsIcon() {
  return (
    <svg width='21' height='20' viewBox='0 0 21 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M10.5003 13.3335L13.8337 9.16683H11.3337V3.3335H9.66699V9.16683H7.16699L10.5003 13.3335Z' fill='white' />
      <path d='M17.167 14.9998H3.83366V9.1665H2.16699V14.9998C2.16699 15.919 2.91449 16.6665 3.83366 16.6665H17.167C18.0862 16.6665 18.8337 15.919 18.8337 14.9998V9.1665H17.167V14.9998Z' fill='white' />
    </svg>

  );
}
