/* eslint-disable require-jsdoc */
/* eslint-disable max-len */
import React from 'react';

export default function LeftMenuIcon() {
  return (
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M6.4 1.6C4.63269 1.6 3.2 3.03269 3.2 4.8C3.2 6.56731 4.63269 8 6.4 8C8.16731 8 9.6 6.56731 9.6 4.8C9.6 3.03269 8.16731 1.6 6.4 1.6ZM1.6 4.8C1.6 2.14903 3.74903 0 6.4 0C9.05097 0 11.2 2.14903 11.2 4.8C11.2 7.45097 9.05097 9.6 6.4 9.6C3.74903 9.6 1.6 7.45097 1.6 4.8ZM15.7315 7.40207C16.0617 7.69561 16.0915 8.20127 15.7979 8.53149L13.6646 10.9315C13.5128 11.1023 13.2952 11.2 13.0667 11.2C12.8382 11.2 12.6206 11.1023 12.4687 10.9315L11.4021 9.73149C11.1085 9.40127 11.1383 8.89561 11.4685 8.60207C11.7987 8.30854 12.3044 8.33828 12.5979 8.66851L13.0667 9.19584L14.6021 7.46851C14.8956 7.13828 15.4013 7.10854 15.7315 7.40207ZM3.6 12.8C2.59243 12.8 1.6 13.7708 1.6 15.2C1.6 15.6418 1.24183 16 0.8 16C0.358172 16 0 15.6418 0 15.2C0 13.0945 1.51477 11.2 3.6 11.2H9.2C11.2852 11.2 12.8 13.0945 12.8 15.2C12.8 15.6418 12.4418 16 12 16C11.5582 16 11.2 15.6418 11.2 15.2C11.2 13.7708 10.2076 12.8 9.2 12.8H3.6Z' fill='#1B2324' />
    </svg>
  );
}

