/* eslint-disable require-jsdoc */
/* eslint-disable max-len */
import React from 'react';

export default function CrossDeleteIcon() {
  return (
    <svg width='12' height='13' viewBox='0 0 12 13' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M1.125 1.625L10.875 11.375M10.875 1.625L1.125 11.375L10.875 1.625Z' stroke='#717171' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round'/>
    </svg>

  );
}
