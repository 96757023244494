/* eslint-disable require-jsdoc */
/* eslint-disable max-len */
import React from 'react';

export default function ReviewClientHappinessIcon() {
  return (
    <svg width="28" height="25" viewBox="0 0 28 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14 2.16296C10.7018 -0.825609 5.62975 -0.717192 2.4603 2.48821C-0.820101 5.80583 -0.820101 11.1847 2.4603 14.5024L12.0201 24.1706C13.1136 25.2765 14.8864 25.2765 15.9799 24.1706L25.5397 14.5024C28.8201 11.1847 28.8201 5.80583 25.5397 2.48821C22.3702 -0.717192 17.2982 -0.825609 14 2.16296ZM12.3598 4.49057L13.0101 5.1482C13.5568 5.70113 14.4432 5.70113 14.9899 5.1482L15.6402 4.49057C17.8271 2.27883 21.3729 2.27883 23.5598 4.49057C25.7467 6.70231 25.7467 10.2883 23.5598 12.5L14 22.1682L4.4402 12.5C2.25327 10.2883 2.25327 6.70231 4.4402 4.49057C6.62714 2.27883 10.1729 2.27883 12.3598 4.49057Z" fill="#E09B07" />
    </svg>

  );
}
