/* eslint-disable require-jsdoc */
/* eslint-disable max-len */
import React from 'react';

export default function NoUserIcon() {
  return (
    <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect width='32' height='32' fill='#95A1A2' />
      <path d='M16 8C13.7909 8 12 9.79086 12 12C12 14.2091 13.7909 16 16 16C18.2091 16 20 14.2091 20 12C20 9.79086 18.2091 8 16 8ZM10 12C10 8.68629 12.6863 6 16 6C19.3137 6 22 8.68629 22 12C22 15.3137 19.3137 18 16 18C12.6863 18 10 15.3137 10 12ZM12 22C10.3431 22 9 23.3431 9 25C9 25.5523 8.55228 26 8 26C7.44772 26 7 25.5523 7 25C7 22.2386 9.23858 20 12 20H20C22.7614 20 25 22.2386 25 25C25 25.5523 24.5523 26 24 26C23.4477 26 23 25.5523 23 25C23 23.3431 21.6569 22 20 22H12Z' fill='white' />
    </svg>

  );
}
