/* eslint-disable require-jsdoc */
/* eslint-disable max-len */
import React from 'react';

export default function SymptomAnalysisIcon() {
  return (
    <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M14.6131 5.73939C14.6131 6.51488 14.03 7.16108 13.2847 7.25795C13.8031 8.2595 14.8074 8.93812 16.0061 8.93812C17.6909 8.93812 19.0839 7.5811 19.0839 5.86874C19.0839 4.15609 17.7232 2.79913 16.0061 2.79913C14.9046 2.79913 13.9326 3.38059 13.4141 4.25302C14.0946 4.38216 14.6131 4.99609 14.6131 5.73939V5.73939Z' fill='#355F92'/>
      <path d='M19.3432 18.0818H19.1488C18.3065 18.0818 17.6261 17.4032 17.6261 16.5632C17.6261 15.7232 18.3066 15.0447 19.1488 15.0447H19.3109L19.2786 12.7184C20.1857 12.8153 21.0605 12.88 21.9676 12.88C23.2312 12.88 24.4624 12.783 25.6287 12.5892L26.1795 12.4923C26.9246 12.3632 27.443 11.6523 27.3136 10.9091C27.1841 10.166 26.4713 9.64899 25.726 9.77811L25.1752 9.87502C23.2638 10.1982 21.2226 10.2304 19.1167 9.9397C17.2052 9.6812 14.8077 9.6812 12.8961 9.9397C10.7904 10.2304 8.74912 10.1982 6.8376 9.87502L6.28641 9.74588C5.5413 9.61674 4.82852 10.1335 4.69881 10.8768C4.56932 11.6199 5.08753 12.3307 5.83288 12.4601L6.38363 12.557C8.39229 12.9124 10.5307 12.9446 12.7338 12.6861L12.7014 19.4712L12.5394 21.4744C13.1873 21.6682 13.6735 22.2499 13.6735 22.9282C13.6735 23.7037 13.0578 24.3499 12.3127 24.4468L11.8592 29.681C11.7943 30.5209 12.4099 31.2642 13.2522 31.3287H13.3817C14.1593 31.3287 14.8396 30.7147 14.9044 29.9395L15.7144 20.1173H16.2974L17.1074 29.9395C17.1723 30.7795 17.9174 31.3934 18.7597 31.3287C19.602 31.264 20.2176 30.5209 20.1527 29.681L19.3104 19.4387L19.3432 18.0818Z' fill='#355F92'/>
      <path d='M12.0859 23.6391C12.4746 23.6391 12.7664 23.3159 12.7664 22.9605C12.7664 22.6051 12.4423 22.2819 12.0859 22.2819L8.71657 22.2821C8.48967 22.2821 8.263 22.4112 8.13351 22.6053L6.61078 24.9961C6.54591 24.9961 6.44874 24.9639 6.38387 24.9639C5.44443 24.9639 4.66675 25.7394 4.66675 26.6763C4.66675 27.6132 5.44437 28.3887 6.38387 28.3887C7.32332 28.3887 8.101 27.6132 8.101 26.6763C8.101 26.3209 8.00382 25.9977 7.80947 25.7069L9.1053 23.6069H12.086L12.0859 23.6391Z' fill='#355F92'/>
      <path d='M25.3693 18.2432C25.2399 18.2432 25.1424 18.2432 25.0453 18.2754L23.5225 16.1429C23.393 15.9491 23.1985 15.8522 22.9718 15.8522H19.1165C18.7278 15.8522 18.436 16.1754 18.436 16.5308C18.436 16.8862 18.7601 17.2094 19.1165 17.2094H22.5833L23.8791 19.0512C23.7171 19.3097 23.6199 19.6005 23.6199 19.9236C23.6199 20.8605 24.3975 21.6361 25.337 21.6361C26.2765 21.6361 27.0542 20.8606 27.0542 19.9236C27.0542 18.9863 26.3414 18.2432 25.3694 18.2432L25.3693 18.2432Z' fill='#355F92'/>
      <path d='M6.83726 4.09154C6.9993 4.09154 7.16133 4.05931 7.32314 4.02685L9.16998 6.19161C9.29948 6.35321 9.49406 6.45012 9.68841 6.45012H13.0903C13.479 6.45012 13.7708 6.12693 13.7708 5.7715C13.7708 5.41608 13.4467 5.09288 13.0903 5.09288H10.0125L8.36024 3.15417C8.48951 2.89611 8.55437 2.63761 8.55437 2.37908C8.55437 1.44221 7.77675 0.666656 6.83724 0.666656C5.89774 0.666656 5.12012 1.44215 5.12012 2.37908C5.12012 3.31618 5.89774 4.0915 6.83724 4.0915L6.83726 4.09154Z' fill='#355F92'/>
    </svg>

  );
}
