/* eslint-disable require-jsdoc */
/* eslint-disable max-len */
import React from 'react';

export default function ProfileHealth() {
  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M1.66699 4.16667C1.66699 3.24619 2.41318 2.5 3.33366 2.5H16.667C17.5875 2.5 18.3337 3.24619 18.3337 4.16667V5.83333C18.3337 6.4559 17.9923 6.99873 17.4866 7.2849C17.4956 7.35532 17.5003 7.42711 17.5003 7.5V15.8333C17.5003 16.7538 16.7541 17.5 15.8337 17.5H4.16699C3.24652 17.5 2.50033 16.7538 2.50033 15.8333V7.5C2.50033 7.42711 2.505 7.35532 2.51408 7.2849C2.00834 6.99873 1.66699 6.45589 1.66699 5.83333V4.16667ZM16.667 5.83333V4.16667H3.33366V5.83333H16.667ZM4.16699 7.5V15.8333H15.8337V7.5H4.16699ZM6.66699 10C6.66699 9.53976 7.04009 9.16667 7.50033 9.16667H12.5003C12.9606 9.16667 13.3337 9.53976 13.3337 10C13.3337 10.4602 12.9606 10.8333 12.5003 10.8333H7.50033C7.04009 10.8333 6.66699 10.4602 6.66699 10Z' fill='#0D0D0D'/>
    </svg>

  );
}
