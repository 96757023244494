/* eslint-disable require-jsdoc */
/* eslint-disable max-len */
import React from 'react';

export default function SupplimentsTabIcon() {
  return (
    <svg width='16' height='21' viewBox='0 0 16 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M15 0.183594V2.18359H13V5.18359C13.7956 5.18359 14.5587 5.49966 15.1213 6.06227C15.6839 6.62488 16 7.38794 16 8.18359V19.1836C16 19.4488 15.8946 19.7032 15.7071 19.8907C15.5196 20.0782 15.2652 20.1836 15 20.1836H1C0.734784 20.1836 0.48043 20.0782 0.292893 19.8907C0.105357 19.7032 0 19.4488 0 19.1836V8.18359C0 7.38794 0.316071 6.62488 0.87868 6.06227C1.44129 5.49966 2.20435 5.18359 3 5.18359V2.18359H1V0.183594H15ZM13 7.18359H3C2.73478 7.18359 2.48043 7.28895 2.29289 7.47649C2.10536 7.66402 2 7.91838 2 8.18359V18.1836H14V8.18359C14 7.91838 13.8946 7.66402 13.7071 7.47649C13.5196 7.28895 13.2652 7.18359 13 7.18359ZM9 9.18359V11.1836H11V13.1836H8.999L9 15.1836H7L6.999 13.1836H5V11.1836H7V9.18359H9ZM11 2.18359H5V5.18359H11V2.18359Z' fill='#355F92'/>
    </svg>

  );
}
