/* eslint-disable require-jsdoc */
/* eslint-disable max-len */
import React from 'react';

export default function ShowIcon() {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M22.0827 11.3953C21.2588 9.65954 20.2784 8.24391 19.1413 7.14844L17.9488 8.34095C18.9212 9.27024 19.7683 10.4859 20.5007 12C18.5507 16.036 15.7827 17.9531 11.9999 17.9531C10.8644 17.9531 9.81857 17.7783 8.86232 17.4286L7.57021 18.7207C8.89834 19.334 10.3749 19.6406 11.9999 19.6406C16.5046 19.6406 19.8655 17.2945 22.0827 12.6024C22.1719 12.4136 22.2181 12.2075 22.2181 11.9988C22.2181 11.7901 22.1719 11.584 22.0827 11.3953V11.3953ZM20.5928 3.88032L19.5937 2.88C19.5762 2.86257 19.5556 2.84874 19.5328 2.8393C19.51 2.82987 19.4856 2.82501 19.461 2.82501C19.4364 2.82501 19.412 2.82987 19.3892 2.8393C19.3664 2.84874 19.3458 2.86257 19.3283 2.88L16.765 5.44219C15.3517 4.72032 13.7633 4.35938 11.9999 4.35938C7.49521 4.35938 4.13427 6.70548 1.91708 11.3977C1.82796 11.5864 1.78174 11.7925 1.78174 12.0012C1.78174 12.2099 1.82796 12.416 1.91708 12.6047C2.80286 14.4703 3.86927 15.9657 5.1163 17.0909L2.63614 19.5703C2.60101 19.6055 2.58127 19.6532 2.58127 19.7029C2.58127 19.7526 2.60101 19.8002 2.63614 19.8354L3.63669 20.836C3.67185 20.8711 3.71952 20.8908 3.76923 20.8908C3.81894 20.8908 3.86661 20.8711 3.90177 20.836L20.5928 4.14563C20.6102 4.12822 20.6241 4.10754 20.6335 4.08477C20.6429 4.06201 20.6478 4.03761 20.6478 4.01297C20.6478 3.98833 20.6429 3.96393 20.6335 3.94117C20.6241 3.91841 20.6102 3.89773 20.5928 3.88032ZM3.49911 12C5.45146 7.96407 8.21943 6.04688 11.9999 6.04688C13.2782 6.04688 14.4405 6.26626 15.4949 6.71227L13.8472 8.35993C13.0669 7.94359 12.1735 7.78908 11.2987 7.91918C10.4239 8.04928 9.61402 8.4571 8.98863 9.08249C8.36324 9.70788 7.95542 10.5177 7.82532 11.3925C7.69522 12.2673 7.84973 13.1608 8.26607 13.9411L6.31091 15.8963C5.2288 14.9412 4.29599 13.6472 3.49911 12ZM9.28115 12C9.28156 11.5867 9.37945 11.1794 9.56688 10.811C9.7543 10.4427 10.026 10.1237 10.3598 9.88011C10.6937 9.63648 11.0803 9.47504 11.4882 9.40891C11.8962 9.34277 12.314 9.37379 12.7077 9.49946L9.4056 12.8016C9.32284 12.5424 9.28085 12.272 9.28115 12V12Z' fill='#68787A' />
      <path d='M11.9064 14.625C11.8253 14.625 11.7454 14.6212 11.6662 14.614L10.4282 15.8519C11.1728 16.1371 11.9841 16.2005 12.7639 16.0344C13.5437 15.8683 14.2587 15.4799 14.8225 14.9161C15.3863 14.3523 15.7747 13.6373 15.9408 12.8575C16.1069 12.0776 16.0435 11.2664 15.7584 10.5218L14.5204 11.7598C14.5277 11.839 14.5314 11.9189 14.5314 12C14.5316 12.3448 14.4638 12.6862 14.332 13.0048C14.2001 13.3233 14.0068 13.6128 13.763 13.8566C13.5192 14.1003 13.2297 14.2937 12.9112 14.4256C12.5926 14.5574 12.2512 14.6252 11.9064 14.625V14.625Z' fill='#68787A' />
    </svg>
  );
}
