const apiEndpoint = {
  signInApi: 'login',
  getProfileApi: 'profile',
  logoutApi: 'logout',
  getRoleField: 'office/roles/',
  getRoles: 'office/roles/',
  uploadProfilePicApi: 'office/uploadProfilePic',
  deleteProfilePicApi: 'office/profilePicture/',
  getClients: 'office/clients/',
  getRootClients: 'office/rootCauseAnalysis/',
  getLeadClients: 'office/leads/',
  assignmentsApi: 'office/clients/assignment',
  MdAssignApi: 'office/matchMD',
  submitCareTeam: 'office/assignments/submitCareTeam',
  changePasswordApi: 'changePassword',
  addUserApi: 'office/users/',
  addUsersApi: 'office/users/',
  forgotApi: 'forgotPassword',
  verifyTokenApi: 'VerifyForgotToken',
  resetPasswordApi: 'ResetPassword',
  permissionApi: 'office/permission',
  healthCoachAssignmentApi: 'office/users/role/health_coach',
  mdAssignmentsApi: 'office/users/role/physician',
  careManagerAssignmentsApi: 'office/users/role/care_manager',
  adminResetPasswordApi: 'adminResetPassword',
  intakeFormApi: 'office/clients/intake_form/',
  symptomAnalysisApi: 'office/clients/symptom_analysis/',
  editProfileApi: 'office/clients/',
  docviewer: 'office/clients/historical_tests/',
  guestLogin: 'guestLogin',
  rteBox: 'office/clients/notes/',
  nutritionApi: 'office/nutrition/',
  testingApi: 'office/test/',
  supplementsApi: 'office/supplement/',
  lifestyleApi: 'office/lifestyle/',
  uploadNutritionApi: 'office/uploadNutrition',
  uploadLifestyleApi: 'office/uploadLifeSytle',
  uploadTest: 'office/uploadTest',
  uploadSupplement: 'office/uploadSupplement',
  downloadTest: 'office/downloadTest',
  downloadSupplement: 'office/downloadSupplement',
  downloadNutrition: 'office/downloadNutrition',
  downloadLifestyle: 'office/downloadLifeStyle',
  FilterApi: 'office/masters/',

  rootcauseApi: 'office/rootCause/',
  uploadRootcause: 'office/uploadRootCause',
  downloadRootcause: 'office/downloadRootCause',

  diagnosisApi: 'office/diagnosis/',
  uploadDiagnosis: 'office/uploadDiagnosis',
  downloadDiagnosis: 'office/downloadDiagnosis',

  coredysfunctionApi: 'office/coreDysfunction/',
  uploadCoredysfunction: 'office/uploadCoreDysfunction',
  downloadCoredysfunction: 'office/downloadCoreDysfunction',

  goalsApi: 'office/goal/',
  uploadGoals: 'office/uploadGoal',
  downloadGoals: 'office/downloadGoal',

  getGlobalData: 'office/userPermissions',
  getRecentActivity: 'office/clients/recentActivities',
  getAppointments: 'office/clients/appointments',

  createHealthPlan: 'office/clients/healthplan',
  healthPlanPhases: 'office/clients/healthplan/phasesOfCare',
  healthPlanPhasesDelete: 'office/clients/healthplan/phasesOfCare',
  phasesOfCareStatuses: 'office/clients/healthplan/statuses/phasesOfCare',
  nutritionStatuses: 'office/clients/healthplan/statuses/nutrition',
  testingStatuses: 'office/clients/healthplan/statuses/testing',
  supplementStatuses: 'office/clients/healthplan/statuses/supplement',
  lifestyleStatuses: 'office/clients/healthplan/statuses/lifestyle',
  healthPlanNutrition: 'office/clients/healthplan/nutrition',
  healthPlanNutritionDelete: 'office/clients/healthplan/nutrition',
  healthPlanTesting: 'office/clients/healthplan/testing',
  healthPlanTestingDelete: 'office/clients/healthplan/testing',
  healthPlanSuplement: 'office/clients/healthplan/supplement',
  healthPlanUnits: 'office/clients/healthplan/units',
  healthPlanSuplementDelete: 'office/clients/healthplan/supplement',
  healthPlanLifeStyle: 'office/clients/healthplan/lifestyle',
  healthPlanFrequency: 'office/clients/healthplan/frequencies',
  healthPlanLifeStyleDelete: 'office/clients/healthplan/lifestyle',
  healthPlanMessage: 'office/clients/healthplan/message',
  healthPlanPreivew: 'office/clients/healthplan/preview',
  healthPlanSubmit: 'office/clients/healthplan/submit',
  healthPlanStatusUpdate: 'office/clients/edit-healthplan',
  healthPlanMaster: 'office/healthplan/masters',

  healthprofileGoals: 'office/clients/healthprofile/goals',
  healthprofileSymptom: 'office/clients/healthprofile/notes/symptomnotes',
  healthprofileHypothesis: 'office/clients/healthprofile/hypothesis',
  healthprofileMedication: 'office/clients/healthprofile/notes/medications',
  healthprofileSuppliment: 'office/clients/healthprofile/notes/supplements',
  healthprofileTimeLine: 'office/clients/healthprofile/notes/timeline',
  healthprofileInternalnotes:
  'office/clients/healthprofile/notes/internalnotes',

  healthprofileNotes: 'office/clients/healthprofile/notes',

  clientAppointmentNotes: 'office/clients/appointmentNotes',
  getClientCurrentappointment: 'office/clients/currentAppointment',
  myscheduleApi: 'office/mySchedule',
  appointmentStatus: 'office/appointmentStatuses',
  acuityCalenderDetails: 'office/clients/acuityCalenderDetails',
  clientDocuments: 'office/clients/documents',
  clientTypeForm: 'office/clients/document',

  paymentsApi: 'office/myPayments',
  individualPaymentApi: 'office/clients/payments',
  viewRecieptApi: 'office/clients/payment',
  userYourTodo: 'office/clients/todos',

  phaseofcareApi: 'office/phasesOfCare/',
  uploadPhaseofcare: 'office/uploadPhasesOfCare',
  downloadPhaseofcare: 'office/downloadPhasesOfCare',

  dropdownApi: 'office/healthplan/masters',
  statusDropdown: 'office/clients/statuses/',

  salesmanagerDashboardApi: 'office/dashboard/clientPendingMatchRequests',
  salesmanagerMatchesApi: 'office/dashboard/clientMatchedRequests',
  healthFoms: 'office/assignments/healthForms',
  careTeam: 'office/assignments/careTeam',

  ordersApi: 'office/clients/orders',
  orderApi: 'office/clients/order',
  testResultsApi: 'office/clients/testResults/',
  uploadTestResults: 'office/uploadTestResult',
  publishTestResultsApi: 'office/clients/publishTestResult/',
  publishProviderTestResultsApi: 'office/clients/publishTestResulttoProvider/',
  testResultsRenameApi: 'office/clients/updateTestResult/',
  testOrdersDropdownApi: 'office/clients/testOrders',
  adminOrdersApi: 'office/clientOrders',
  adminOrderReceipt: 'office/clientOrdersReceipt',
  clientValueApi: 'office/dashboard/totalClientsInfo',

  dashboardAppointments: 'office/dashboard/myAppointments/',
  healthCoachTodos: 'office/dashboard/healthCoachTodos/',
  physicianTodos: 'office/dashboard/physicianTodos/',
  cmTodos: 'office/dashboard/careManagerTodos/',
  markAsComplete: 'office/clients/todos',
  hcTaskSummary: 'office/dashboard/healthCoachTaskSummary',
  cmTaskSummary: 'office/dashboard/careManagerTaskSummary',
  mdTaskSummary: 'office/dashboard/mdTaskSummary',
  // completedHCTodos: 'office/dashboard/healthCoachCompletedTodos',
  // completedCMTodos: 'office/dashboard/careManagerCompletedTodos',
  completedTodos: 'office/dashboard/myCompletedTodos',
  autoLogin: 'client/liveHelperchatAutoLogin/',
  userChats: 'client/liveHelperUserChats/',
  userChatInfo: 'client/liveHelperFetchchatInfo/',
  fetchChatMsgs: 'client/liveHelperFetchChatMessages/',
  addMsgs: 'client/liveHelperAddMsgToChatAsAdmin',
  clientGoals: 'office/clients/goals',
  activeHealthPlan: 'office/clients/active_health_plan',
  postprogram_plandetails: 'office/clients/clientSubscriptionHistories',
  urmStatusUpdate: 'client/liveHelperURMStatusUpdate',
  clientProPic: 'client/getVisitorProfilePic',
  previous_msgs: 'office/clients/healthplan/messages',
  chatCountApi: 'client/liveHelperChatsCount',
  shppingCharge: 'api/settings/shippingCharge',
  clientFilters: 'office/clients/clientFilters',
  rootcauseMessage: 'office/clients/rootCauseMessage/',
  rootCauseTest: 'office/clients/rootCauseTests',
  uploadApi: 'office/clients/uploadLabTestForm',
  cardsApi: 'mobile/clients/cards',
  appointmentPayment: 'client/appointmentPayment',
  upgradeIplan: 'office/clients/upgradePlan',
  hcPayment: 'office/clients/makeHCPayment',
  getCards: 'office/clients/cards',
  addCardApi: 'office/clients/addClientCard',
  updateDefaultApi: 'mobile/clients/updateDefaultCard',
  clientInfo: 'office/clientInfo',
  createMessage: 'office/createMessage',
  sendMessage: 'office/publishMessage',
  getMessages: 'office/messages',
  overviewMessages: 'office/overviewMessages',
  commentsApi: 'office/messages/comments',
  getMessage: 'office/message',
  sendComment: 'office/messages/comment',
  tags: 'office/messageTags',
  healthProfile: 'office/messages/healthProfile',
  healthPlan: 'office/messages/activeHealthPlan',
  healthTimeline: 'office/messages/healthTimeline',
  markAsResolved: 'office/resolveMessage',
  getAssignments: 'office/assignmentUserInfo',
  getDashboardMessages: 'office/dashboard/myMessages',
  getMDUsers: 'office/getMDUsers/',
  getPhysician: 'office/getPhysicians',
  getClientDetails: 'api/checkCustomer/',
  getClientPlanData: 'api/getCPPlans/',
  customerOnboard: 'api/onboardCustomer',
};

export default apiEndpoint;
