import {PiSpinner} from 'pixel-kit';
import React from 'react';
import {SpinnerOverlay} from '../../styles/common-styles';

/**
 * @param {any} props
 * @return {Spinner}
 */
export default function Spinner(props: any) {
  return (
    <SpinnerOverlay className={props.data}>
      <PiSpinner
        color='secondary'
        libraryType='atalskit'
        size={50}
      />
    </SpinnerOverlay>
  );
}
